import React, { useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Editor } from "@tinymce/tinymce-react";
import {
  API_BASE_URL,
  Assets_URL,
  Cloudinary_URL,
  NODE_API,
} from "../../../Apicongfig";
import { useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import { useTotalTime } from "../../../../context/TotalTimeContext";
import cheerio from "cheerio";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { MdOutlineModeEdit } from "react-icons/md";
import { LuFileEdit } from "react-icons/lu";
import {
  convertCount2ToSeconds,
  parseTimeTaken,
} from "../../../Utils/MeetingFunctions";
import Spreadsheet from "react-spreadsheet";
import { read, utils } from "xlsx";

// Function to extract base64 image sources from HTML string
function extractBase64SrcFromHTML(htmlString) {
  const base64SrcArray = [];

  // Load the HTML string into cheerio
  const $ = cheerio.load(htmlString);

  // Find all elements with 'src' attribute
  $("[src]").each((index, element) => {
    const srcValue = $(element).attr("src");

    // Check if the src starts with 'data:image'
    if (srcValue.startsWith("data:image")) {
      // If src is a base64 image, push it into base64SrcArray
      base64SrcArray.push(srcValue);
    }
  });

  return base64SrcArray;
}

// Function to replace base64 image sources with cloud URLs in HTML string
function replaceBase64SrcWithLinks(htmlString, imageLinks) {
  // Load the HTML string into cheerio
  const $ = cheerio.load(htmlString);

  // Find all elements with 'src' attribute
  $("[src]").each((index, element) => {
    const srcValue = $(element).attr("src");

    // Check if the src starts with 'data:image'
    if (srcValue.startsWith("data:image")) {
      // Replace the src with the corresponding link from imageLinks
      $(element).attr("src", imageLinks[index]);
    }
  });

  // Return the modified HTML string
  return $.html();
}

// Function to convert base64 strings to File objects
const base64toFile = (base64Strings) => {
  return base64Strings.map((dataurl, index) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // Convert binary string to Uint8Array
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    // Generate a unique filename for each file
    const filename = `file_${index}.${mime.split("/")[1]}`;

    // Create a File object
    return new File([u8arr], filename, { type: mime });
  });
};

// Main function to optimize editor content by replacing base64 images with cloud URLs
//Using NodeAPI - Cloudinary

// export const optimizeEditorContent = async (editorContent) => {
//   // Check if editor content exists
//   if (!editorContent) {
//     return "";
//   }

//   // Initialize variable to store optimized editor content
//   let optimizedEditorContent = "";

//   // Extract base64 image sources from editor content
//   const base64Array = extractBase64SrcFromHTML(editorContent);

//   // Check if there are base64 images in the editor content
//   if (!base64Array.length > 0) {
//     // If no base64 images found, return the original content
//     optimizedEditorContent = editorContent;
//     return optimizedEditorContent;
//   } else {
//     // If base64 images exist, proceed with cloud optimization
//     const API_URL = NODE_API;

//     // Convert base64 strings to File objects
//     const files = base64toFile(base64Array);
//     const formData = new FormData();

//     // Append files to FormData object
//     files.forEach((file, index) => {
//       formData.append(`images`, files[index]);
//     });

//     // Send FormData to cloud service and receive image URLs
//     const imagesResponse = await axios.post(API_URL, formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     });

//     // Extract image URLs from the response
//     const imageSRCArray = imagesResponse?.data?.images?.map(
//       (image) => image.url
//     );

//     // Replace base64 image sources with cloud URLs in the editor content
//     const editorContentWithCloudLinks = replaceBase64SrcWithLinks(
//       editorContent,
//       imageSRCArray
//     );

//     // Update optimized editor content
//     optimizedEditorContent = editorContentWithCloudLinks;
//     return optimizedEditorContent;
//   }
// };

//FrontEnd - Cloudinary
export const optimizeEditorContent = async (editorContent) => {
  if (!editorContent) {
    return "";
  }
  //-------- CLOUD LOGIC ------------------------------
  let optimizedEditorContent = "";
  const base64Array = extractBase64SrcFromHTML(editorContent);
  if (!base64Array.length > 0) {
    optimizedEditorContent = editorContent;
    return optimizedEditorContent;
  } else {
    const cloudinaryUploads = base64Array.map(async (base64Image) => {
      try {
        const response = await fetch(`${Cloudinary_URL}`, {
          method: "POST",
          body: JSON.stringify({
            file: base64Image,
            upload_preset: "chat-application",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        return data.secure_url;
      } catch (error) {
        console.error("Error uploading image to Cloudinary:", error);
        return null;
      }
    });

    const uploadedImageUrls = await Promise.all(cloudinaryUploads);
    const editorContentWithCloudLinks = replaceBase64SrcWithLinks(
      editorContent,
      uploadedImageUrls
    );

    optimizedEditorContent = editorContentWithCloudLinks;
    return optimizedEditorContent;
  }
  //   // ------- CLOUD LOGIC END -------------------------
};

export const image_upload_handler_callback = (blobInfo, progress) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", blobInfo.blob(), blobInfo.filename());

    formData.append("upload_preset", "chat-application");
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open("POST", `${Cloudinary_URL}`);

    xhr.upload.onprogress = (e) => {
      progress((e.loaded / e.total) * 100);
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);
        resolve(response.secure_url); // Assuming Cloudinary returns the secure URL of the uploaded image
      } else {
        reject("Failed to upload image to Cloudinary");
      }
    };

    xhr.onerror = () => {
      reject(
        "Image upload failed due to a XHR Transport error. Code: " + xhr.status
      );
    };

    xhr.send(formData);
  });

//----------------------------------------------

const ReportStepChart = ({
  meetingId,
  puller,
  participants,
  data,
  disabled,
}) => {
  const TINYMCEAPI = process.env.REACT_APP_TINYMCE_API;
  console.log("TINYMCEAPI", TINYMCEAPI);
  const location = window.location.href;
  const fromReport = location.includes("step-details")
    ? true
    : location.includes("meetingDetail")
    ? true
    : false;
  const [t] = useTranslation("global");

  const [isDisabled, setIsDisabled] = useState(false);
  const id = useParams().id || meetingId;
  const [inputData, setInputData] = useState([]);
  console.log("inputData", inputData);
  const [loading, setLoading] = useState(false);
  const [selectedBar, setSelectedBar] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedCount, setSelectedCount] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  console.log('selectedindex', selectedIndex);
  const stepRef = useRef();
  const [accumulatedSelectedCounts, setAccumulatedSelectedCounts] = useState(
    []
  );
  const [storedStartTime, setStoredStartTime] = useState(null);
  const [totalSelectedCount, setTotalSelectedCount] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [countSum, setCountSum] = useState(0);
  const [modifiedFileText, setModifiedFileText] = useState([]);
  const [modalType, setModalType] = useState("Editeur");
  const [editorContent, setEditorContent] = useState("");
  const [addBtnText, setAddBtnText] = useState("Ajouter une étape");
  const [copyBtnText, setCopyBtnText] = useState("Copier l’étape");
  const [nextBtnText, setNextBtnText] = useState("Suivant");
  const [prevBtnText, setPrevBtnText] = useState("Précédent");
  const [validateBtnText, setValidateBtnText] = useState("Valider");
  const [link, setLink] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [timeUnit, setTimeUnit] = useState("");
  const inputDataRef = useRef(inputData);
  const [fileUpload, setFileUpload] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isUpload, setIsUpload] = useState(false);

    const [excelData, setExcelData] = useState(null);
    console.log('excelData',excelData)

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    // console.log("file: ", file);
    setIsUpload(true);
    const allowedFileTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    // Check file size (6 MB = 6 * 1024 * 1024 bytes)
    if (file.size > 6 * 1024 * 1024) {
      toast.error(t("meeting.chart.error.file"));
      setIsUpload(false);
      return;
    }
    if (file && allowedFileTypes.includes(file.type)) {
      try {
        setIsDisabled(false);

        const updatedSteps = [...(inputData?.steps || [])];
        const selectedStep = updatedSteps[selectedIndex];

        const filePayload = {
          title: selectedStep.title,
          count1: selectedStep.count1 || 0,
          count2: selectedStep.count2,
          time: selectedStep.count2, // Ensure this is correct
          editor_type: "File",
          file: file,
          editor_content: null,
          status: "active",
          _method: "put",
        };

        const formData = new FormData();
        formData.append("title", filePayload.title);
        formData.append("count1", filePayload.count1);
        formData.append("count2", filePayload.count2);
        formData.append("time", filePayload.time);
        formData.append("editor_type", filePayload.editor_type);
        formData.append("file", filePayload.file);
        formData.append("editor_content", filePayload.editor_content);
        formData.append("status", filePayload.status);
        formData.append("_method", filePayload._method);

        const response = await axios.post(
          `${API_BASE_URL}/steps/${selectedStep?.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          setFileName(response.data.data.file);
          setIsUpload(false);
        }
      } catch (error) {
        console.log("error while uploading file", error);
        setIsUpload(false);
      }
    } else {
      alert(
        "Please select a valid file type: PDF, Excel, PowerPoint, or Word."
      );
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".pdf,.xlsx,.xls,.ppt,.pptx,.doc,.docx",
    onDrop,
  });

  const [user, setUser] = useState(null);

  const getMeetingbyId = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get-meeting/${id}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      console.log("steps when modal closed-->", response.data?.data?.steps);
      const updatedSteps = response.data?.data?.steps;
      if (puller !== undefined) {
        puller(updatedSteps);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getMeetingbyId();
  }, [id, isModalOpen]);

  useEffect(() => {
    if (fromReport) {
      setIsModalOpen(true);
    }
  }, [fromReport]);


  useEffect(() => {
    const fetchExcel = async () => {
      try {
        const excelFile = inputData?.steps[selectedIndex]?.file
        // Fetch Excel data if applicable
        if (excelFile) {
          const fileResponse = await axios.get(
            `${Assets_URL}/${excelFile}`,
            { responseType: "arraybuffer" }
          );
          console.log("file response", fileResponse);

          const fileData = fileResponse?.data;
          const workbook = read(fileData, { type: "buffer" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          if (worksheet) {
            const jsonSheetData = utils.sheet_to_json(worksheet, { header: 1 });
            const formattedData = jsonSheetData?.map((row, rowIndex) =>
              row.map((cell) => ({
                value: cell,
                readOnly: rowIndex === 0,
              }))
            );

            setExcelData(formattedData); // Set Excel data after fetching
          } else {
            console.warn("Worksheet is null or undefined");
          }
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    if(selectedIndex && inputData && inputData?.steps[selectedIndex]?.editor_type === "Excel"){
      fetchExcel()
    }
  }, [selectedIndex])
  
  useEffect(() => {
    const getMeeting = async () => {
      try {
        setLoading(true);
        setIsDisabled(true);
        const REQUEST_URL = fromReport
          ? `${API_BASE_URL}/showPublicMeeting/${meetingId}`
          : `${API_BASE_URL}/get-meeting/${meetingId}`;
        const response = await axios.get(REQUEST_URL, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        if (response.status) {
          console.log("response data chart: ", response.data?.data);
          const updatedSteps = response.data?.data.steps;
          const time = updatedSteps.reduce((acc, step) => acc + step.count2, 0);
          setStepNotes(updatedSteps?.map((step) => step?.note));
          setInputData({ ...response.data?.data, steps: updatedSteps });
          setLoading(false);
          updateTotalTime(time);

          // const formattedData = updatedSteps?.map(step => ({
          //   x: step.title,
          //   y: step.time,
          //   time_unit: step.time_unit
          // }));
          // setChartData(formattedData);
        }
      } catch (error) {
        // console.log("error", error);
        setLoading(false);
      } finally {
        setIsDisabled(false);
      }
    };

    getMeeting();
  }, [id, meetingId, isModalOpen]);

  const [allData, setAllData] = useState([]);
  useEffect(() => {
    if (inputData && inputData?.steps) {
      setModifiedFileText(inputData?.steps?.map((step) => step.editor_content));
    }

    if (inputData) {
      const { steps } = inputData;
      let accumulatedCount2 = steps?.length > 0 ? steps[0]?.count2 : 0;
      let storedTime = moment(inputData.start_time, "HH:mm"); // Initialize stored time
      console.log("accumulatedCount2->", accumulatedCount2);
      const formattedData = steps
        ?.map((step, index) => {
          let count1 = 0;
          let count2 = step.count2;

          if (index > 0) {
            accumulatedCount2 += step.count2;
            count1 = accumulatedCount2 - count2;
          }

          // Calculate new stored time if selectedIndex > 0
          if (index === selectedIndex) {
            storedTime.add(count1, "minutes");
          }

          return {
            x: step.title,
            y: [count1, count1 + count2, count2],
            // unit: step.time_unit,
          };
        })
        .reverse();

      // Set stored time only if selectedIndex > 0
      if (selectedIndex > 0) {
        setStoredStartTime(storedTime.format("hh:mm a"));
      } else {
        setStoredStartTime(
          moment(inputData.start_time, "HH:mm").format("hh:mm a")
        );
      }

      console.log("formattedChartData-->", formattedData);
      setChartData(formattedData);
      setAllData(inputData.steps);
    }
  }, [inputData, selectedIndex]);

  const colors = inputData?.steps
    ?.map((step) => {
      const timeTakenInSeconds = parseTimeTaken(step?.time_taken);
      const count2InSeconds = convertCount2ToSeconds(
        step?.count2,
        step?.time_unit
      );
      console.log("time taken in seconds", timeTakenInSeconds);
      console.log("count2 in seconds", count2InSeconds);
      if (step?.savedTime == "0" || timeTakenInSeconds > count2InSeconds) {
        console.log("red wala");
        return "#FF4560"; // Red
      } else {
        console.log("green wala");
        return "#00E396"; // Green
      }
    })
    .reverse();
  const localizeTimeTaken = (timeTaken) => {
    if (!timeTaken) return;
    const timeUnits = t("time_unit", { returnObjects: true });
    return timeTaken
      .split(" - ")
      .map((part) => {
        const [count, ...unitParts] = part.split(" ");
        const unit = unitParts.join(" "); // Handle cases like "days" and "hours"
        console.log("units", unit);
        return `${count} ${timeUnits[unit] || unit}`; // Fallback to original if unit not found
      })
      .join(" - ");
  };

  const options = {
    xaxis: {
      type: "category",
      labels: {
        show: false,
        // formatter: function (val) {
        //   if (data?.type === "Action") {
        //     return val + " Days";
        //   } else {
        //     return val + " Mins";
        //   }
        // },
        formatter: function (val, index, opts) {
          const step = data?.steps[index];
          const timeTaken = step?.time_taken || "";
          const firstValue = timeTaken.split(" - ")[0]; // Get the first value before the dash

          return firstValue;
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        formatter: function (val, index) {
          return val;
        },
      },
    },
    chart: {
      height: 650,
      zoom: false,
      type: "rangeBar",
      events: {
        click: function (event, chartContext, config) {
          const { dataPointIndex } = config;
          setChartData((prevChartData) => {
            if (
              dataPointIndex !== undefined &&
              dataPointIndex >= 0 &&
              dataPointIndex < prevChartData?.length
            ) {
              const updatedSelectedIndex =
                prevChartData.length - 1 - dataPointIndex;
              setSelectedIndex(updatedSelectedIndex);
              const clickedChartData = prevChartData[dataPointIndex];
              if (
                clickedChartData &&
                clickedChartData.y &&
                clickedChartData.y.length === 3
              ) {
                const bar2 = clickedChartData.y;
                const selectedCounts = prevChartData
                  .slice(dataPointIndex + 1)
                  .map((item) =>
                    item.y && item.y.length === 3 ? item.y[2] : 0
                  );
                const totalSelectedCount = selectedCounts.reduce(
                  (sum, count) => sum + count,
                  0
                );

                const newAccumulatedSelectedCounts = [
                  ...accumulatedSelectedCounts,
                  totalSelectedCount,
                ];

                const startTime = moment(
                  inputDataRef.current["start_time"],
                  "HH:mm"
                )
                  .add(
                    newAccumulatedSelectedCounts.reduce(
                      (sum, count) => sum + count,
                      0
                    ),
                    "minutes"
                  )
                  .format("hh:mm a");

                setSelectedBar(clickedChartData.x);
                setSelectedValue(clickedChartData.x);
                setSelectedCount(bar2[2]);
                setTotalSelectedCount(totalSelectedCount);
                // setStoredStartTime(startTime);
                setAccumulatedSelectedCounts(newAccumulatedSelectedCounts);
                setIsModalOpen(true);

                // if (clickedChartData && clickedChartData.id) {
                //   const clickedStepId = clickedChartData.id;
                // }
              }
            }

            return prevChartData;
          });
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        borderRadius: 15,
        barHeight: 35,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
        // formatter: function (val) {
        //   return val;
        // },
        // formatter: function (val, index, opts) {
        //   const step = data?.steps[index];
        //   const timeTaken = step?.time_taken || '';
        //   return timeTaken;
        // },
      },
    },
    grid: {
      row: {
        colors: ["#fff", "#fff"],
        opacity: 1,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["black"],
      },
      // formatter: function (val, opts) {
      //   var label = opts.w.globals.labels[opts.dataPointIndex];
      //   return label;
      // },
      formatter: function (val, opts) {
        const reversedIndex = data?.steps?.length - 1 - opts.dataPointIndex;
        const step = data?.steps[reversedIndex];

        // Ensure we have a step and it has the properties we need
        if (step) {
          const localizedTimeTaken =
            localizeTimeTaken(step?.time_taken?.replace("-", "")) || "";
          const count2 = step?.count2 || "";
          const timeUnitKey = step?.time_unit
            ? t(`time_unit.${step.time_unit}`)
            : "";
          const timeUnit = t(timeUnitKey) || "";

          return `${localizedTimeTaken} ${
            data?.type === "Special" || data?.type === "Law" ? " " : "/"
          } ${count2} ${timeUnit}`.trim();
        }

        return ""; // Return an empty string if the step is not found
        // const step = data?.steps[reversedIndex];
        // return (localizeTimeTaken(step?.time_taken) || '' + " " + step?.count2 + " " + t(`time_unit.${step.time_unit}`));
      },
    },

    colors: colors,
  };

  const [assignUser, setAssignUser] = useState(null);
  const [stepOrder, setStepOrder] = useState(null);
  useEffect(() => {
    if (inputData && inputData?.steps && selectedIndex >= 0) {
      const updatedStep = [...(inputData?.steps || [])];
      const selectedStep = updatedStep[selectedIndex];
      console.log("selected-----------------", selectedStep);
      const currentStep = updatedStep[selectedIndex]?.count2;
      console.log("selected step:", selectedStep);
      setFileName(selectedStep?.file);
      setShowPreview(true);
      setLink(selectedStep?.url);
      setPreviewUrl(selectedStep?.url);
      // setFileName(
      //   selectedStep?.file !== null
      //     ? selectedStep?.editor_content
      //     : selectedStep?.file
      // );
      // setType(selectedStep?.editor_content);
      setSelectedCount(currentStep);
      setStepOrder(selectedStep?.order);
      setModalType(
        selectedStep?.editor_type !== null
          ? selectedStep?.editor_type
          : "Editeur"
      );
      setTimeUnit(selectedStep?.time_unit);

      setAssignUser(
        selectedStep?.assigned_to_name === null
          ? inputData?.user?.last_name !== null
            ? inputData?.user?.name + " " + inputData?.user?.last_name
            : inputData?.user?.name
          : selectedStep?.assigned_to_name
      );
    }
  }, [inputData, selectedIndex, user, assignUser]);

  // -------------------------OPTIMIZE EDITOR CONTENT-------------------------
  const { total_Time, updateTotalTime } = useTotalTime();
  const [stepNotes, setStepNotes] = useState([]);
  const [stepTranscribedText, setStepTranscribedText] = useState([]);
  console.log("stepTranscribedText", stepTranscribedText);
  const [mySteps, setMySteps] = useState([]);
  const getMeeting = async () => {
    try {
      setLoading(true);
      setIsDisabled(true);
      const REQUEST_URL = fromReport
        ? `${API_BASE_URL}/showPublicMeeting/${meetingId}`
        : `${API_BASE_URL}/get-meeting/${meetingId}`;
      const response = await axios.get(REQUEST_URL, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      if (response.status) {
        const updatedSteps = response.data?.data.steps;
        setStepNotes(updatedSteps?.map((step) => step?.note));
        setStepTranscribedText(
          updatedSteps?.map((step) => step?.transcripted_text)
        );
        setInputData({ ...response.data?.data, steps: updatedSteps });
        const { steps } = response.data?.data;
        setMySteps(steps);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setIsDisabled(false);
    }
  };

  const [isEdited, setIsEdited] = useState(false);
  const [nextId, setNextId] = useState(null);

  const getStep = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/steps/${nextId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      // console.clear("clear");
      if (response.status) {
        setShowPreview(true);
        setFileName(response.data.data.file);
        setLink(response.data?.data?.url);
        setPreviewUrl(response?.data?.data?.url);
      }
    } catch (error) {
      console.log("error while processing get step", error);
    }
  };

  useEffect(() => {
    getStep();
  }, [nextId]);

  useEffect(() => {
    getMeeting();
  }, [id, meetingId]);

  const [open, setOpen] = useState(false);

  const handleSave = async () => {
    try {
      const updatedSteps = [...(data?.steps || [])];
      console.log("updated steps", updatedSteps);
      const selectedStep = updatedSteps[selectedIndex];
      console.log("selectedStep", selectedStep);
      const payload = {
        ...selectedStep,
        note: stepNotes[selectedIndex],
        // actions: tableData ? tableData : [],
      };

      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${selectedStep?.id}/step-note-and-action`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        setStepNotes((prevNotes) =>
          prevNotes.map((note, index) =>
            index === selectedIndex ? response.data.data.note : note
          )
        );
        setStepTranscribedText((prevNotes) =>
          prevNotes.map((note, index) =>
            index === selectedIndex
              ? response.data.data.transcripted_text
              : note
          )
        );
        setOpen(false);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.log("error while save step", error);
    }
  };

  const getYoutubeEmbedUrl = (url) => {
    if (!url) {
      return false;
    }
    if (url.includes("youtube.com/watch")) {
      const videoUrl = new URL(url);
      const videoId = videoUrl.searchParams.get("v");

      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
      // Handle the shortened YouTube URL (youtu.be)
    }
    if (url.includes("youtu.be/")) {
      const videoId = url.split("youtu.be/")[1];
      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }

    return false; // Return false if it's not a valid YouTube URL
  };
  // const transformStepNotes = (note) => {
  //   if (!note) return "";
  //   return note
  //     .replace(/\*\*(.*?)\*\*/g, '<b style="font-size:16px"> $1</b> ')
  //     .replace(/(\d+)\./g, "<br>$1. ")
  //     .replace(/(\.|\))\s*-?\s*/g, "$1");
  // };

  // const transformedValue = transformStepNotes(stepNotes[selectedIndex]);
  const [isToggled, setIsToggled] = useState(false);
  const handleToggle = () => {
    setIsToggled((prev) => !prev);
  };
  return (
    <>
      <div
        id="chart-container"
        className="chart-content"
        style={{
          width: "100%",
          height: "auto",
          overflow: "hidden",
          border: "1px solid #ececec",
          borderRadius: "15px",
          marginTop: "40px",
          padding: "10px",
        }}
      >
        <ReactApexChart
          options={options}
          series={[{ data: chartData }]}
          type="rangeBar"
          height={500}
        />

        {isModalOpen && selectedBar !== null && (
          <>
            <Modal
              show={isModalOpen}
              onHide={() => setIsModalOpen(false)}
              size="xl"
              centered
              // className="report-steps-note-modal"
              className="modal-fade report-steps-note-modal" /* Add this class for transparency */
            >
              <Modal.Body
                style={{
                  padding: "25px 53px",
                }}
              >
                <div>
                  {inputData?.steps[selectedIndex]?.editor_type === "File" ? (
                    <div>
                      <iframe
                        src={
                          Assets_URL +
                          "/" +
                          (inputData?.steps[selectedIndex]?.file +
                            "#toolbar=0&view=fitH")
                        }
                        width="100%"
                        height="500px"
                      />
                    </div>
                  ) : inputData?.steps[selectedIndex]?.file &&
                  inputData?.steps[selectedIndex]?.editor_type !== "Excel" ? (
                                                        <div
                                                          style={{
                                                            borderRadius: "10px",
                                                          }}
                                                        >
                                                          <iframe
                                                            src={`${Assets_URL}/${inputData?.steps[selectedIndex]?.file}#toolbar=0&view=fitH`}
                                                            width="100%"
                                                            height="630px"
                                                          />
                                                        </div>
                                                      ) : inputData?.steps[selectedIndex]?.file && inputData?.steps[selectedIndex]?.editor_type === "Excel" ? (
                                                        <div
                                                          style={{
                                                            borderRadius: "10px",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              width: "100%",
                                                              height: "630px",
                                                              overflowY: "auto",
                                                              textAlign: "center",
                                                            }}
                                                          >
                                                            {excelData ? (
                                                              <Spreadsheet data={excelData || null} />
                                                            ) : (
                                                              <p>No Excel data available for this step.</p>
                                                            )}
                                                          </div>
                                                        </div>
                                                      )  : inputData?.steps[selectedIndex]?.editor_type === "Url" ? (
                    <div>
                      <iframe
                        src={getYoutubeEmbedUrl(
                          inputData?.steps[selectedIndex]?.url
                        )}
                        width="100%"
                        height="500px"
                      />
                    </div>
                  ) : (
                    <div
                      className="rendered-content-report-step-chart"
                      dangerouslySetInnerHTML={{
                        __html:
                          (inputData &&
                            inputData?.steps &&
                            inputData?.steps[selectedIndex] &&
                            inputData?.steps[selectedIndex]?.editor_content !==
                              null) ||
                          inputData?.steps[selectedIndex]?.editor_content !== ""
                            ? inputData?.steps[selectedIndex]?.editor_content
                            : " ",
                      }}
                    />
                  )}
                  <div className="d-flex gap-2 align-items-center">
                    <h3>{inputData?.steps[selectedIndex].title}</h3>
                  {inputData?.type === "Special" &&  <div>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label={isToggled ? t("meeting.formState.Transcript") : t("meeting.formState.Summary")}
                        checked={isToggled}
                        onChange={handleToggle}
                        style={{ cursor: "pointer" }}
                      />
                    </div>}
                  </div>
                </div>
                <div>
                  {/* <div
                 

                  {/* {open ? ( */}
                  <>
                    {inputData?.steps[selectedIndex]?.time_unit === "days" ? (
                      <Editor
                        onBlur={(value) => {
                          console.log("value", value);
                        }}
                        key={selectedIndex}
                        apiKey={TINYMCEAPI}
                        disabled
                        // value={inputData?.steps[selectedIndex].note}
                        value={`
                        <p>Campaign Name: ${inputData?.steps[selectedIndex]?.email_campaigns?.campaign_name}</p>
                        <p>Total Sendings: ${inputData?.steps[selectedIndex]?.email_campaigns?.total_sendings}</p>
                        <p>Total Recipients: ${inputData?.steps[selectedIndex]?.email_campaigns?.total_recipients}</p>
                        <p>Total Opens: ${inputData?.steps[selectedIndex]?.email_campaigns?.total_opens}</p>
                        <p>Total Clicks: ${inputData?.steps[selectedIndex]?.email_campaigns?.total_clicks}</p>
                        <p>Total Unsubscribes: ${inputData?.steps[selectedIndex]?.email_campaigns?.total_unsubscribes}</p>
                      `}
                        init={{
                          statusbar: false,
                          branding: false,
                          height: 400,
                          menubar: true,
                          language: "fr_FR",
                          // language: "en_EN",
                          plugins:
                            "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                          toolbar:
                            "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                          image_advtab: true,
                          file_picker_types: "image",

                          file_picker_callback: function (
                            callback,
                            value,
                            meta
                          ) {
                            if (meta.filetype === "image") {
                              const input = document.createElement("input");
                              input.setAttribute("type", "file");
                              input.setAttribute("accept", "image/*");

                              input.onchange = function () {
                                const file = input.files[0];
                                const reader = new FileReader();

                                reader.onload = function (e) {
                                  const img = new Image();
                                  img.src = e.target.result;

                                  img.onload = function () {
                                    const canvas =
                                      document.createElement("canvas");
                                    const ctx = canvas.getContext("2d");
                                    const maxWidth = 700;
                                    const maxHeight = 394;

                                    let newWidth = img.width;
                                    let newHeight = img.height;

                                    if (img.width > maxWidth) {
                                      newWidth = maxWidth;
                                      newHeight =
                                        (img.height * maxWidth) / img.width;
                                    }

                                    if (newHeight > maxHeight) {
                                      newHeight = maxHeight;
                                      newWidth =
                                        (img.width * maxHeight) / img.height;
                                    }

                                    canvas.width = newWidth;
                                    canvas.height = newHeight;

                                    ctx.drawImage(
                                      img,
                                      0,
                                      0,
                                      newWidth,
                                      newHeight
                                    );

                                    const resizedImageData = canvas.toDataURL(
                                      file.type
                                    );

                                    // Pass the resized image data to the callback function
                                    callback(resizedImageData, {
                                      alt: file.name,
                                    });
                                  };

                                  img.src = e.target.result;
                                };

                                reader.readAsDataURL(file);
                              };

                              input.click();
                            }
                          },
                        }}
                     
                        onEditorChange={(value) => {
                          setStepNotes((prev) => {
                            let newStepNotes = [...prev];
                            newStepNotes[selectedIndex] = value;
                            return newStepNotes;
                          });
                        }}
                      />
                    ) : (
                      <>
                        {!isToggled ? (
                          <Editor
                            onBlur={(value) => {
                              console.log("value", value);
                            }}
                            key={selectedIndex}
                            apiKey={TINYMCEAPI}
                            // value={inputData?.steps[selectedIndex].note}
                            value={!(inputData?.location === "Google Meet" && 
                              !["Task", "Quiz", "Media", "Law"].includes(inputData?.type)) ? stepNotes[selectedIndex] : inputData?.steps[selectedIndex]?.original_note }
                            init={{
                              statusbar: false,
                              branding: false,
                              height: 400,
                              menubar: true,
                              language: "fr_FR",
                              // language: "en_EN",
                              plugins:
                                "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                              toolbar:
                                "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                              image_advtab: true,
                              file_picker_types: "image",

                              file_picker_callback: function (
                                callback,
                                value,
                                meta
                              ) {
                                if (meta.filetype === "image") {
                                  const input = document.createElement("input");
                                  input.setAttribute("type", "file");
                                  input.setAttribute("accept", "image/*");

                                  input.onchange = function () {
                                    const file = input.files[0];
                                    const reader = new FileReader();

                                    reader.onload = function (e) {
                                      const img = new Image();
                                      img.src = e.target.result;

                                      img.onload = function () {
                                        const canvas =
                                          document.createElement("canvas");
                                        const ctx = canvas.getContext("2d");
                                        const maxWidth = 700;
                                        const maxHeight = 394;

                                        let newWidth = img.width;
                                        let newHeight = img.height;

                                        if (img.width > maxWidth) {
                                          newWidth = maxWidth;
                                          newHeight =
                                            (img.height * maxWidth) / img.width;
                                        }

                                        if (newHeight > maxHeight) {
                                          newHeight = maxHeight;
                                          newWidth =
                                            (img.width * maxHeight) /
                                            img.height;
                                        }

                                        canvas.width = newWidth;
                                        canvas.height = newHeight;

                                        ctx.drawImage(
                                          img,
                                          0,
                                          0,
                                          newWidth,
                                          newHeight
                                        );

                                        const resizedImageData =
                                          canvas.toDataURL(file.type);

                                        // Pass the resized image data to the callback function
                                        callback(resizedImageData, {
                                          alt: file.name,
                                        });
                                      };

                                      img.src = e.target.result;
                                    };

                                    reader.readAsDataURL(file);
                                  };

                                  input.click();
                                }
                              },
                            }}
                            onEditorChange={(value) => {
                              setStepNotes((prev) => {
                                let newStepNotes = [...prev];
                                newStepNotes[selectedIndex] = value;
                                return newStepNotes;
                              });
                            }}
                          />
                        ) : (
                          <Editor
                            onBlur={(value) => {
                              console.log("value", value);
                            }}
                            key={selectedIndex}
                            apiKey={TINYMCEAPI}
                            value={stepTranscribedText[selectedIndex]}
                            init={{
                              statusbar: false,
                              branding: false,
                              height: 400,
                              menubar: true,
                              language: "fr_FR",
                              // language: "en_EN",
                              plugins:
                                "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                              toolbar:
                                "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                              image_advtab: true,
                              file_picker_types: "image",

                              file_picker_callback: function (
                                callback,
                                value,
                                meta
                              ) {
                                if (meta.filetype === "image") {
                                  const input = document.createElement("input");
                                  input.setAttribute("type", "file");
                                  input.setAttribute("accept", "image/*");

                                  input.onchange = function () {
                                    const file = input.files[0];
                                    const reader = new FileReader();

                                    reader.onload = function (e) {
                                      const img = new Image();
                                      img.src = e.target.result;

                                      img.onload = function () {
                                        const canvas =
                                          document.createElement("canvas");
                                        const ctx = canvas.getContext("2d");
                                        const maxWidth = 700;
                                        const maxHeight = 394;

                                        let newWidth = img.width;
                                        let newHeight = img.height;

                                        if (img.width > maxWidth) {
                                          newWidth = maxWidth;
                                          newHeight =
                                            (img.height * maxWidth) / img.width;
                                        }

                                        if (newHeight > maxHeight) {
                                          newHeight = maxHeight;
                                          newWidth =
                                            (img.width * maxHeight) /
                                            img.height;
                                        }

                                        canvas.width = newWidth;
                                        canvas.height = newHeight;

                                        ctx.drawImage(
                                          img,
                                          0,
                                          0,
                                          newWidth,
                                          newHeight
                                        );

                                        const resizedImageData =
                                          canvas.toDataURL(file.type);

                                        // Pass the resized image data to the callback function
                                        callback(resizedImageData, {
                                          alt: file.name,
                                        });
                                      };

                                      img.src = e.target.result;
                                    };

                                    reader.readAsDataURL(file);
                                  };

                                  input.click();
                                }
                              },
                            }}
                            onEditorChange={(value) => {
                              setStepTranscribedText((prev) => {
                                let newStepNotes = [...prev];
                                newStepNotes[selectedIndex] = value;
                                return newStepNotes;
                              });
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                  {/* // ) : (
                  //   <>
                  //     <div
                  //       dangerouslySetInnerHTML={{
                  //         __html: inputData?.steps[selectedIndex]?.note,
                  //       }}
                  //     ></div>
                  //   </>
                  // )} */}
                </div>
              </Modal.Body>
              <Modal.Footer className="justify-content-center">
                <Button
                  //   variant={open ? "primary" : "secondary"}
                  // variant=
                  className="save-note-btn"
                  onClick={handleSave}
                  // disabled={disabled}
                >
                  Modifier
                </Button>
                <Button
                  //   variant={open ? "primary" : "secondary"}
                  variant="danger"
                  // className='save-note-btn'
                  onClick={() => setIsModalOpen(false)}
                >
                  {t("buttons.cancel")}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default ReportStepChart;
