import { RxCross2 } from "react-icons/rx";
import MomentDetail from "./components/MomentDetails";
import { useTranslation } from "react-i18next";
import Solution from "./components/Template";
import DateandTime from "./components/DateandTime";
import Location from "./components/Location";
import AddGuests from "./components/AddGuests";
import AddSteps from "./components/AddSteps";
import Options from "./components/Options";
import Privacy from "./components/Privacy";
import { useEffect, useState } from "react"; // Use local state
import Share from "./components/Share";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import { useFormContext } from "../../../../context/CreateMeetingContext";

const tabComponents = {
  tab1: MomentDetail,
  tab2: Solution,
  tab3: DateandTime,
  tab4: Location,
  tab5: AddGuests,
  tab6: AddSteps,
  tab7: Options,
  tab8: Privacy,
};
const NewMeetingModal = ({}) => {
  const [t] = useTranslation("global");
  const [activeTab, setActiveTab] = useState("tab1"); // Local state for tabs
  const [showConfirmation, setShowConfirmation] = useState(false); // State for confirmation modal
  const {
    checkId,
    deleteMeeting,
    saveDraft,
    open,
    handleCloseModal,
    isUpdated,
    isDuplicate,
    validateAndUpdate,
    addParticipant,
    changePrivacy,
    meeting,
    setSelectedTab,
    showProgress,
    progress
  } = useFormContext();



  useEffect(() => {
    if (addParticipant) {
      setActiveTab("tab5");
    }
  }, [addParticipant]);
  useEffect(() => {
    if (changePrivacy) {
      setActiveTab("tab8");
    }
  }, [changePrivacy]);

  const handleCancel = () => setShowConfirmation(true);
  const handleClose = () => setShowConfirmation(false);
  const handleDelete = () => deleteMeeting(checkId);

  const isTabDisabled = (tabName) => {
    if (addParticipant) return tabName !== "tab5";
    if (changePrivacy) return tabName !== "tab8";

    const tabOrder = Object.keys(tabComponents).filter(tab => tab !== "tab2" || !meeting?.solution);
    const currentIndex = tabOrder.indexOf(activeTab);
    const tabIndex = tabOrder.indexOf(tabName);
    return isUpdated || isDuplicate ? false : tabIndex > currentIndex;
  };
  const renderTabContent = () => {
    const SpecificTab = tabComponents[activeTab];
    return (
      <SpecificTab
        setActiveTab={setActiveTab}
        meeting={meeting}
        closeModal={handleCloseModal}
      />
    );
  };

  return (
    <>
      {open && (
        <div id="chart-container" className="chart-content">
          <div className="modal-overlay">
            <div className="new-meeting-modal">
              <div className="modal-nav">
                <div>
                  <h4>
                    {isUpdated
                      ? meeting?.solution
                        ? `${t(
                            "meeting.newMeeting.update"
                          )}: ${meeting?.solution?.title?.toUpperCase()}`
                        : t("meeting.newMeeting.UpdateMoment")
                      : isDuplicate
                      ? meeting?.solution
                        ? `${t(
                            "meeting.newMeeting.duplicate"
                          )}: ${meeting?.solution?.title?.toUpperCase()}`
                        : t("meeting.newMeeting.DuplicateMoment")
                      : addParticipant
                      ? t("meeting.newMeeting.Add new invite")
                      : changePrivacy ?
                       t("meeting.newMeeting.ChangePrivacy")
                      : t("meeting.newMeeting.CreateMoment")}

                    {/* //  {isUpdated
                      // ? `${t("meeting.newMeeting.update")}: ${meeting?.solution?.title?.toUpperCase()} `
                      // : isDuplicate
                      // ? `${t("meeting.newMeeting.duplicate")}: ${meeting?.solution?.title?.toUpperCase()} `
                      // : addParticipant
                      // ? t("meeting.newMeeting.Add new invite")
                      // : t("meeting.newMeeting.CreateMoment")} */}
                  </h4>
                  {!isUpdated && !isDuplicate && !addParticipant && !changePrivacy && (
                    <small style={{ padding: "10px", margin: "0px" }}>
                      {t("meeting.newMeeting.CreateMomentDescription")}
                    </small>
                  )}
                </div>
                <div className="d-flex justify-content-end">
                  <button className="cross-btn" onClick={handleCancel}>
                    <RxCross2 size={18} />
                  </button>
                </div>
              </div>
              <div className="mt-3 modal-body">
                <div
                  className="col-md-11"
                  style={{ borderBottom: "2px solid #F2F2F2" }}
                >
                  <div
                    className="tabs justify-content-start"
                    style={{ padding: "0 33px" }}
                  >
                    {Object.keys(tabComponents).filter(tabName => tabName !== "tab2" || !meeting?.solution).map((tabName) => (
                      <button
                        key={tabName}
                        className={`tab ${
                          activeTab === tabName ? "active" : ""
                        }`}
                        onClick={() =>{ setActiveTab(tabName);setSelectedTab(tabName)}}
                        disabled={isTabDisabled(tabName)}
                      >
                        {t(`meeting.NewMeetingTabs.${tabName}`)}
                      </button>
                    ))}
                  </div>
                </div>
                <div>{renderTabContent()}</div>
              </div>
            </div>
          </div>
          <Modal
            show={showConfirmation}
            onHide={handleClose}
            dialogClassName="custom-modal-size custom-modal-border cancel-moment-modal modal-dialog-centered"
          >
            <Modal.Header closeButton className="border-0"></Modal.Header>
            <Modal.Body className="text-center p-4">
              <h2 className="w-100 text-center fs-5">{t("Are you sure")}</h2>
              <p className="mb-4" style={{ color: "#92929D" }}>
                {isUpdated || isDuplicate || addParticipant || changePrivacy
                  ? ""
                  : t("saveAndDraftText")}
              </p>
              <div className="d-flex justify-content-center gap-3 mb-3">
                {isUpdated || addParticipant || changePrivacy ? (
                  <Button
                    variant="outline-danger"
                    className="px-4 py-2 confirmation-delete"
                    onClick={handleCloseModal}
                  >
                    {t("Cancel")}
                  </Button>
                ) : (
                  <Button
                    variant="outline-danger"
                    className="px-4 py-2 confirmation-delete"
                    onClick={handleDelete}
                  >
                    {t("Delete")}
                  </Button>
                )}
                <Button
                  variant="primary"
                  className="px-4 py-2 confirmation-save"
                  onClick={
                    isDuplicate || isUpdated
                      ? validateAndUpdate
                      : addParticipant || changePrivacy
                      ? validateAndUpdate
                      : saveDraft
                  }
                >
                  {isUpdated || isDuplicate
                    ? t("Save Meeting")
                    : addParticipant || changePrivacy
                    ? t("Save Meeting")
                    : t("Save Draft")}
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}

     
    </>
  );
};

export default NewMeetingModal;
