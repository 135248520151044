import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../Components/Apicongfig";
import { askPermission } from "../Components/Utils/askPermission";

const MeetingsContext = createContext();

export const useMeetings = () => useContext(MeetingsContext);

export const MeetingsProvider = ({ children }) => {
  const [allMeetings, setAllMeetings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allClosedMeetings, setAllClosedMeetings] = useState([]);
  const [allDraftMeetings, setAllDraftMeetings] = useState([]);
  const [status, setStatus] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("TODAY");
  const [offset, setOffset] = useState(0);
  const [progress, setProgress] = useState(0); // State for progress
  console.log('progress: ', progress)
  const [meetingLength, setMeetingLength] = useState(0);

  const [activeMeetingCount, setActiveMeetingCount] = useState(0);
  const [closedMeetingCount, setClosedMeetingCount] = useState(0);
  const [draftMeetingCount, setDraftMeetingCount] = useState(0);
  console.log("offset", offset);
  const [limit] = useState(5);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const [selectedClosedFilter, setSelectedClosedFilter] = useState("TODAY");
  const [closedOffset, setClosedOffset] = useState(0);
  const [closedLimit] = useState(5);
  const [closedHasMore, setClosedHasMore] = useState(true);
  const [closedLoading, setClosedLoading] = useState(false);
  const [closedProgress, setClosedProgress] = useState(0); // State for progress
  const [closedMeetingLength, setClosedMeetingLength] = useState(0);
  //Draft
  const [draftOffset, setDraftOffset] = useState(0);
  const [draftLimit] = useState(5);
  const [draftHasMore, setDraftHasMore] = useState(true);
  console.log("draftHasMore", draftHasMore);
  const [draftLoading, setDraftLoading] = useState(false);
  const [draftProgress, setDraftProgress] = useState(0); // State for progress
  const [draftMeetingLength, setDraftMeetingLength] = useState(0);

  const getMeetingsCalculations = async () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
    // Format date
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
    const day = currentTime.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    try {
      const response = await axios.get(
        `${API_BASE_URL}/calculate-meetings-time?current_time=${formattedTime}&current_date=${formattedDate}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        // setAllMeetings(response?.data?.data);
        // setIsLoading(false);
      }
    } catch (error) {}
  };
  const getMeetings = async () => {
    setProgress(0); // Reset progress to 0 at the start
    setLoading(true);
    setIsLoading(true);

    // Start a progress simulation
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 90) {
          clearInterval(interval); // Stop updating at 90%
          return 90; // Set to 90 before it completes
        }
        return prev + 10; // Increment progress by 10%
      });
    }, 200); // Update every 200ms

    if (offset === 0) {
      setLoading(true);
      // setAllMeetings([])
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/meetings`, {
        params: {
          offset: offset,
          limit: limit,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      if (response?.data && response?.data?.data) {
        setActiveMeetingCount(response?.data?.count)
        const data = response?.data?.data;
        setHasMore(data.length === limit); // Check if more data is available

        // setAllMeetings((prev) => (offset === 0 ? data : [...prev, ...data])); // Reset or append
        setAllMeetings((prev) => {
          const updatedMeetings = offset === 0 ? data : [...prev, ...data];
          return [
            ...new Map(updatedMeetings.map((item) => [item.id, item])).values(),
          ]; // assuming item.id is the unique identifier
        });
        
        setMeetingLength(data?.length);
        setIsLoading(false);
        setLoading(false);
        setProgress(100); // Set progress to 100% upon completion
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      clearInterval(interval); // Clear the interval when done
      setProgress(100); // Set progress to 100% upon completion
      setIsLoading(false);
      setLoading(false);
    }
  };

  const getClosedMeetings = async () => {
    setClosedProgress(0);
    setClosedLoading(true);
    setIsLoading(true);

    // Start a progress simulation
    const interval = setInterval(() => {
      setClosedProgress((prev) => {
        if (prev >= 90) {
          clearInterval(interval); // Stop updating at 90%
          return 90; // Set to 90 before it completes
        }
        return prev + 10; // Increment progress by 10%
      });
    }, 200); // Update every 200ms

    try {
      setIsLoading(true);
      if (closedOffset === 0) {
        setClosedLoading(true);
      }

      const response = await axios.get(`${API_BASE_URL}/closed/meetings`, {
        params: {
          // filter: selectedClosedFilter,
          offset: closedOffset,
          limit: closedLimit,
        },
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      if (response.status) {
        setClosedMeetingCount(response?.data?.count)

        // setAllClosedMeetings(response?.data?.data);
        const data = response?.data?.data;
        setClosedHasMore(data?.length === closedLimit);

        setClosedMeetingLength(data?.length);
        // setAllMeetings(response?.data?.data);
        // setAllClosedMeetings((prev) =>
        //   closedOffset === 0 ? data : [...prev, ...data]
        // );
        setAllClosedMeetings((prev) => {
          const updatedMeetings =
            closedOffset === 0 ? data : [...prev, ...data];
          return [
            ...new Map(updatedMeetings.map((item) => [item.id, item])).values(),
          ]; // assuming item.id is the unique identifier
        });
        setIsLoading(false);
        setClosedLoading(false);
        setClosedProgress(100); // Set progress to 100% upon completion
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      clearInterval(interval); // Clear the interval when done
      setClosedProgress(100); // Set progress to 100% upon completion
      setIsLoading(false);
      setClosedLoading(false);
    }
  };
  const getDraftMeetings = async () => {
    setDraftProgress(0);
    setIsLoading(true);
    setDraftLoading(true);

    // Start a progress simulation
    const interval = setInterval(() => {
      setDraftProgress((prev) => {
        if (prev >= 90) {
          clearInterval(interval); // Stop updating at 90%
          return 90; // Set to 90 before it completes
        }
        return prev + 10; // Increment progress by 10%
      });
    }, 200); // Update every 200ms
    if (draftOffset === 0) {
      setDraftLoading(true);
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/draft/meetings`, {
        params: {
          offset: draftOffset,
          limit: draftLimit,
        },
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      if (response.status) {
        setDraftMeetingCount(response?.data?.count)

        const data = response?.data?.data;
        setDraftHasMore(data?.length === draftLimit);

        setDraftMeetingLength(data?.length);

        // setAllDraftMeetings((prev) =>
        //   draftOffset === 0 ? data : [...prev, ...data]
        // );
        setAllDraftMeetings((prev) => {
          const updatedMeetings = draftOffset === 0 ? data : [...prev, ...data];
          return [
            ...new Map(updatedMeetings.map((item) => [item.id, item])).values(),
          ]; // assuming item.id is the unique identifier
        });

        setIsLoading(false);
        setDraftLoading(false);
        setDraftProgress(100); // Set progress to 100% upon completion
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      clearInterval(interval); // Clear the interval when done
      setDraftProgress(100); // Set progress to 100% upon completion
      setIsLoading(false);
      setDraftLoading(false);
    }
  };
  const handleDelete = async (id) => {
    const permissionGranted = askPermission(
      "Êtes-vous sûr de vouloir supprimer cette réunion ?" ||
        "Are you sure you want to delete this meeting?"
    );

    if (!permissionGranted) return;

    try {
      const response = await axios.delete(`${API_BASE_URL}/meetings/${id}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });

      if (response.status === 200) {
        toast.success("Réunion supprimée avec succès");
        getMeetings();
      } else {
        throw new Error("Échec de la suppression de la réunion");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <MeetingsContext.Provider
      value={{
        allMeetings,
        isLoading,
        getMeetings,
        allClosedMeetings,
        getClosedMeetings,
        getDraftMeetings,
        allDraftMeetings,

        setAllDraftMeetings,

        handleDelete,
        status,
        setStatus,
        getMeetingsCalculations,
        setAllMeetings,
        setMeetingLength,
        selectedFilter,
        setSelectedFilter,
        selectedClosedFilter,
        setSelectedClosedFilter,

        setAllClosedMeetings,
        offset,
        setOffset,
        limit,
        hasMore,
        setHasMore,
        loading,
        setLoading,
        setClosedMeetingLength,

        closedOffset,
        setClosedOffset,
        closedLimit,
        closedHasMore,
        setClosedHasMore,
        closedLoading,
        setClosedLoading,

        draftOffset,
        setDraftOffset,
        draftLimit,
        draftHasMore,
        setDraftHasMore,
        draftLoading,
        setDraftLoading,

        progress,
        setProgress,
        meetingLength,

        closedProgress,
        closedMeetingLength,

        draftProgress,
        draftMeetingLength,
        setDraftMeetingLength,

        activeMeetingCount,
        closedMeetingCount,
        draftMeetingCount,
      }}
    >
      {children}
    </MeetingsContext.Provider>
  );
};
