import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { API_BASE_URL } from "../../Apicongfig";
import axios from "axios";
import { toast } from "react-toastify";
import { useDestinations } from "../../../context/DestinationsContext";
import { useTranslation } from "react-i18next";
import ImageEditorModal from "./ImageEditorModal";
import { IoImages } from "react-icons/io5";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const AddDestination = ({
  show,
  handleClose,
  currentItem,
  refreshedDestination,
}) => {
  const [loading, setLoading] = useState(false);
  const [destinationName, setDestinationName] = useState(null);
  const [destinationDescription, setDestinationDescription] = useState(null);
  const [type, setType] = useState(null);
  const { getDestinations } = useDestinations();
  const [t] = useTranslation("global");
  const [isDisabled, setIsDisabled] = useState(false);
  console.log("disabled", isDisabled);
  const userId = parseInt(sessionStorage.getItem("user_id"));
  const [image, setImage] = useState(null);
  console.log("image", image);
  console.log("currentItem", currentItem);
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false);
  const fileInputRef = React.useRef(null);
  const [croppedimage, setcroppedImage] = useState(null);
  console.log("croppedimage", croppedimage);
  const typeOptions = [
    {
      value: "Project",
      label: t("destination.project"),
    },
    {
      value: "Accompagnement",
      label: t("destination.accompagnement"),
    },
    {
      value: "Business opportunity",
      label: t("destination.businessOppurtunity"),
    },
    {
      value: "Other",
      label: t("destination.other"),
    },
  ];
  // Handle image selection from file input
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      setShowModal(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  useEffect(() => {
    if (currentItem) {
      setDestinationName(currentItem.destination_name);
      setDestinationDescription(currentItem.destination_description);
      setImage(currentItem?.banner);
      setcroppedImage(currentItem?.banner);
      setType(
        typeOptions.find(
          (opt) => opt.value === currentItem?.destination_type
        ) || null
      );
      if (currentItem?.user_id === userId || currentItem?.user_id === null) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      setDestinationName("");
      setDestinationDescription("");
      setType("");
      setImage(null);
      setcroppedImage(null);

      setIsDisabled(false);
    }
  }, [currentItem]);
  const user_id = sessionStorage.getItem("user_id");
  const createDestination = async () => {
    if (!destinationName) {
      toast.error(t("destination.destinationName"));
      return;
    }
    setLoading(true);
    try {
      // const payload = {
      //   destination_name: destinationName,
      //   destination_description: destinationDescription || "",
      //   user_id: user_id,
      // };
      const formData = new FormData();
      formData.append("destination_name", destinationName);
      formData.append("destination_type", type.value || null);
      formData.append("destination_description", destinationDescription || "");
      formData.append("user_id", user_id);
      // Convert Base64 string to Blob
      if (croppedimage) {
        const base64Response = await fetch(croppedimage);
        const blob = await base64Response.blob(); // Convert to Blob
        formData.append("banner", blob, "banner.jpg"); // Append the Blob to FormData
      }

      const response = await axios.post(
        `${API_BASE_URL}/destinations`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status) {
        const data = response?.data?.data
        handleClose();
        navigate(`/invitiesToMeeting/${data?.id}`)
        // getDestinations();
        toast.success(t("destination.destinationCreateMsg"));
      }
    } catch (error) {
      console.log("error creating destination", error);
    } finally {
      setLoading(false);
      setDestinationName("");
      setDestinationDescription("");
      setType("");
    }
  };
  const updateDestination = async (item) => {
    if (!destinationName) {
      toast.error(t("destination.destinationName"));
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("destination_name", destinationName);
      formData.append("destination_type", type.value || null);
      formData.append("destination_description", destinationDescription || "");
      formData.append("_method", "PATCH");
      // Convert Base64 string to Blob
      if (croppedimage) {
        const base64Response = await fetch(croppedimage);
        const blob = await base64Response.blob(); // Convert to Blob
        formData.append("banner", blob, "banner.jpg"); // Append the Blob to FormData
      }

      // if (croppedimage) {
      //   formData.append("banner", croppedimage);
      // }
      const response = await axios.post(
        `${API_BASE_URL}/destinations/${item.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data", // Specify the content type for file uploads
          },
        }
      );
      if (response.status) {
        const data = response?.data?.data
        navigate(`/invitiesToMeeting/${data?.id}`)
        handleClose();
        // getDestinations();
        refreshedDestination();
        toast.success(t("destination.destinationUpdateMsg"));
      }
    } catch (error) {
      console.log("error creating destination", error);
    } finally {
      setLoading(false);
      setDestinationName("");
      setDestinationDescription("");
      setType("");
      setImage(null);
      setcroppedImage(null);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="create-destination-modal"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="destination-modal-title">
            {currentItem
              ? `${t("invities.update")}`
              : `${t("invities.create")}`}{" "}
            Destination
            <br></br>
            {!currentItem && (
              <small
                style={{ margin: "0px", fontSize: "small", fontWeight: "300" }}
              >
                {t("meeting.newMeeting.CreateDestinationDescription")}
              </small>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form">
              {/* New Dropdown Field for Type */}
              <div className="type">
              <label htmlFor="type" className="form-label">
                Type
              </label>
              <Select
                className="my-select destination-select-dropdown"
                classNamePrefix="select"
                value={type}
                onChange={(selectedOption) => setType(selectedOption)}
                options={typeOptions}
                isClearable
              />
            </div>
            <div className="name">
              <label htmlFor="" className="form-label">
                Destination
                <small
                  style={{ color: "red", fontSize: "15px", marginLeft: "2px" }}
                >
                  *
                </small>
              </label>

              <input
                type="text"
                placeholder={t("What end result do you want to achieve")}
                value={destinationName}
                onChange={(e) => setDestinationName(e.target.value)}
                disabled={isDisabled}
              />
            </div>
          
            <div className="description">
              <label htmlFor="">Description</label>
              <textarea
                placeholder={t("Enter Description")}
                value={destinationDescription}
                onChange={(e) => setDestinationDescription(e.target.value)}
                disabled={isDisabled}
              />
            </div>

            {
              //currrentItem &&
              <div className="description">
                <label htmlFor="">Banner</label>
                <div
                  className="upload-container"
                  // onClick={!image ? triggerFileInput : null}
                  onClick={triggerFileInput}
                  style={{
                    cursor: "pointer",
                    padding: "10px",
                    border: "2px dashed #ccc",
                    textAlign: "center",
                    borderRadius: "5px",
                    height: "200px",
                    backgroundColor: "#f9f9f9", // Add some background to make the container stand out
                  }}
                >
                  {!croppedimage ? (
                    <>
                      <IoImages
                        style={{ color: "#0026b1", fontSize: "24px" }}
                      />
                      <span
                        style={{
                          fontSize: "15px",
                          color: "#0026b1",
                          marginLeft: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {t("invities.uploadImg")}
                      </span>
                    </>
                  ) : (
                    <img
                      src={croppedimage}
                      alt="Selected"
                      style={{ width: "100%", height: "auto" }}
                    />
                  )}
                </div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
              </div>
            }
          </form>
        </Modal.Body>
        <Modal.Footer className="destination-modal-footer">
          <button
            onClick={() =>
              currentItem ? updateDestination(currentItem) : createDestination()
            }
            disabled={isDisabled}
          >
            {loading ? (
              <Spinner
                as="div"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
                style={{
                  margin: "2px 12px",
                }}
              />
            ) : (
              <>
                {" "}
                {currentItem
                  ? `${t("invities.update")}`
                  : `${t("invities.create")}`}{" "}
                Destination
              </>
            )}
          </button>
        </Modal.Footer>
      </Modal>
      {image && (
        <ImageEditorModal
          show={showModal}
          handleClose={handleModalClose}
          selectedImage={image}
          setImage={setImage}
          setcroppedImage={setcroppedImage}
        />
      )}
    </>
  );
};

export default AddDestination;
