import React, { useEffect, useRef, useState } from "react";
import {
  NavLink,
  useNavigate,
  useLocation,
  useParams,
  Link,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { API_BASE_URL, Assets_URL } from "../Apicongfig";
import { useDraftMeetings } from "../../context/DraftMeetingContext";
import { CiLogout, CiUser } from "react-icons/ci";
import axios from "axios";
import { useUser } from "../../context/UserContext";
import { useTabs } from "../../context/TabContext";
import { useSidebarContext } from "../../context/SidebarContext";
import { useHeaderTitle } from "../../context/HeaderTitleContext";

function Sidebar({ isSidebarOpen, toggleSidebar, onLogout }) {
  const { activeTab, setActiveTab } = useTabs();
  const { user } = useUser();
  const [t, i18n] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const isActiveContract = [
    `/contract`,
    "/ModifierContract",
    "/ContractLinkEntreprises",
    "/ContractToTeam",
    `/readContract/${params.id}`,
    `/ModifierContract/${params.id}`,
    `/CopyContract/${params.id}`,
    `/CopyClosedContract/${params.id}`,
  ].includes(location.pathname);

  const isActiveEntreprises = [
    `/Enterprises`,
    "/ModifierEnterprises",
    "/EntreprisesToTeam",
    `/ModifierEnterprises/${params.id}`,
  ].includes(location.pathname);

  const isActiveTeam = [
    `/Team`,
    `/ModifierTeam/${params.id}`,
    `/users/${params.id}`,
    `/ModifierUser/${params.id}`,
  ].includes(location.pathname);

  const isActiveSupport = [`/customer-support`].includes(location.pathname);

  const isActiveMeeting =
    location.pathname.startsWith("/meeting") ||
    location.pathname.startsWith("/graph") ||
    location.pathname.startsWith("/view") ||
    location.pathname.startsWith("/copy") ||
    location.pathname.startsWith("/d") ||
    location.pathname.startsWith("/Play") ||
    location.pathname.startsWith("/presentation") ||
    location.pathname.startsWith("/validateMeeting") ||
    location.pathname.startsWith("/meetings/drafts") ||
    location.pathname.startsWith("/present/invite") ||
    location.pathname.startsWith("/invite");

  const isActiveAction = location.pathname.startsWith("/action");

  const isActiveSolution = location.pathname.startsWith("/solution");

  const isActiveGuest = [
    "/Invities",
    `/invitiesToMeeting/${params.id}`,
    `/participantToAction/${params.id}`,
    `/updateParticipant/${params.id}`,
  ].includes(location.pathname);

  const handleLogout = () => {
    if (onLogout) {
      onLogout();
    }
    //toast.success(t("header.logoutSuccess"));
    navigate("/");
  };
  const role = sessionStorage.getItem("type");

  const { profileImage, setProfileImage } = useHeaderTitle();
  // const [profileImage, setProfileImage] = useState(null);
  console.log("profileImage", profileImage);
  const { updateLanguage } = useDraftMeetings();

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    console.log("lang", lang);
    updateLanguage(lang);
  };
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { show } = useSidebarContext();
  console.log("show-->", show);
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const dropdownRef = useRef(null);

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const userID = sessionStorage.getItem("user_id");
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/users/${userID}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        if (response.status === 200) {
          // console.log("response in header", response);
          setProfileImage(response?.data?.data?.image);
        }
      } catch (error) {
        // console.log(error?.message);
      }
    };
    getUser();
  }, [userID, profileImage]);
  const iconStyle = {
    fill: " #5088ee",
    width: "50px",
    height: "50px",
    background: "azure",
    borderRadius: "50%",
    cursor: "pointer",
  };
  return (
    <>
      <div
        className={`sidebar`}
        // className={`sidebar ${show ? "d-xl-block d-lg-block" : "d-none"} d-none`}
        // className={`sidebar d-none d-xl-block d-lg-block`}
        id="sidebar"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="container-fluid ">
          <div className="text-center p-4">
            <div className="profile-logo">
              {profileImage ? (
                <img
                  className="user-img"
                  // src={Assets_URL + "/" + profileImage}
                  // src={
                  //   user.image
                  //     ? Assets_URL + "/" + user.image
                  //     : Assets_URL + "/" + profileImage
                  // }
                  src={
                    profileImage?.startsWith("users/")
                      ? Assets_URL + "/" + profileImage
                      : // : item?.image?.startsWith("users/")
                        // ? Assets_URL + "/" + item.image
                        profileImage
                  }
                  alt="profile"
                  // onErrorCapture={(e) => {
                  //   e.target.onerror = null;
                  //   e.target.src = "/Assets/avatar.jpeg";
                  // }}
                  onClick={toggleDropdown}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <svg
                  className="user-img"
                  onClick={toggleDropdown}
                  style={iconStyle}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                </svg>
              )}

              <img
                className="logout-icon"
                src="/Assets/Avatar_company.svg"
                height="12px"
                width="12px"
                alt="tektime"
              />
            </div>
            {isDropdownOpen && (
              <div className="dr" ref={dropdownRef}>
                <div className="dropdown-arrow-up"></div>

                <div
                  className="dropdown-item"
                  onClick={() => {
                    navigate("/profile");
                    closeDropdown();
                  }}
                >
                  <div
                    style={{
                      borderBottom: " 1px solid #D5D5D5",
                      width: "100%",
                      textAlign: "left",
                      paddingBottom: " 9px",
                    }}
                  >
                    <span role="img" aria-label="Profile">
                      <img src="/Assets/sidebar_profile.svg" alt="profile" />
                    </span>{" "}
                    <span className="dropdown-profile">
                      {t("sidebar.profile")}
                    </span>
                  </div>
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => {
                    handleLogout();
                    closeDropdown();
                  }}
                >
                  <span role="img" aria-label="Logout" className="me-3">
                    <CiLogout />
                  </span>{" "}
                  <span className="dropdown-logout">{t("sidebar.logout")}</span>
                </div>
              </div>
            )}
          </div>
          <div className="side-btn">
            <div
              style={{
                position: "absolute",
                top: role === "MasterAdmin" ? "15%" : "25%",
                // : role === "User" ? "40%"
                width: "100%",
              }}
              // className={isDropdownOpen ? 'dropdown-content' : ''}
            >
              {role !== "SuperAdmin" &&
                role !== "Admin" &&
                role !== "User" &&
                role !== "Invitee" && (
                  <NavLink
                    className={`nav-link ${isActiveContract ? "active" : ""}`}
                    activeClassName={role === "MasterAdmin" ? "active" : ""}
                    to={"/contract"}
                    activeStyle={{ color: "#0026B1" }}
                    onClick={() => setDropdownOpen(false)}
                  >
                    <button>
                      {isActiveContract ? (
                        <img
                          src="/Assets/sidebar_subscription_active.svg"
                          alt="subscription"
                        />
                      ) : (
                        <img
                          src="/Assets/sidebar_subscription.svg"
                          alt="subscription"
                        />
                      )}
                      <span>{t("sidebar.subscriptions")}</span>
                    </button>
                  </NavLink>
                )}
              {role !== "Admin" && role !== "User" && role !== "Invitee" && (
                <NavLink
                  className={`nav-link ${isActiveEntreprises ? "active" : ""}`}
                  activeClassName={role === "SuperAdmin" ? "active" : ""}
                  to={"/Enterprises"}
                  activeStyle={{ color: "#0026B1" }}
                  onClick={() => setDropdownOpen(false)}
                >
                  <button>
                    {isActiveEntreprises ? (
                      <img
                        src="/Assets/sidebar_enterprise_active.svg"
                        alt="enterprise"
                      />
                    ) : (
                      <img
                        src="/Assets/sidebar_enterprise.svg"
                        alt="enterprise"
                      />
                    )}
                    <span>{t("sidebar.enterprises")}</span>
                  </button>
                </NavLink>
              )}
              {role !== "User" && role !== "Invitee" && (
                <NavLink
                  className={`nav-link ${isActiveTeam ? "active" : ""}`}
                  activeClassName="active"
                  to={"/Team"}
                  activeStyle={{ color: "#0026B1" }}
                  onClick={() => setDropdownOpen(false)}
                >
                  <button>
                    {isActiveTeam ? (
                      <img src="/Assets/sidebar_team_active.svg" alt="team" />
                    ) : (
                      <img src="/Assets/sidebar_team.svg" alt="team" />
                    )}

                    <span>{t("sidebar.teams")}</span>
                  </button>
                </NavLink>
              )}

              {
                <NavLink
                  className={`nav-link ${isActiveGuest ? "active" : ""}`}
                  activeClassName={role === "User" ? "active" : ""}
                  to="/Invities"
                  activeStyle={{ color: "#0026B1" }}
                  onClick={() => setDropdownOpen(false)}
                >
                  <button>
                    {isActiveGuest ? (
                      <img
                        src="/Assets/sidebar_active_destination.svg"
                        alt="destination"
                      />
                    ) : (
                      <img
                        src="/Assets/sidebar_destination.svg"
                        alt="destination"
                      />
                    )}

                    <span>{t("sidebar.guests")}</span>
                  </button>
                </NavLink>
              }

              {
                <NavLink
                  className={`nav-link ${isActiveSolution ? "active" : ""}`}
                  activeClassName="active"
                  to={"/solution"}
                  activeStyle={{ color: "#0026B1" }}
                  onClick={() => {
                    setDropdownOpen(false);
                    // setActiveTab("tab2");
                  }}
                >
                  <button>
                    {isActiveSolution ? (
                      <img
                        src="/Assets/Tek.png"
                        // src="/Assets/tektime-sidebar-active.svg"
                        alt="solution"
                        width="32px"
                      />
                    ) : (
                      <img
                        src="/Assets/tektime-sidebar.svg"
                        alt="solution"
                        width="32px"
                      />
                    )}

                    <span>{t("sidebar.solution")}</span>
                  </button>
                </NavLink>
              }
              {
                <NavLink
                  className={`nav-link ${isActiveMeeting ? "active" : ""}`}
                  activeClassName="active"
                  to={"/meeting"}
                  activeStyle={{ color: "#0026B1" }}
                  onClick={() => {
                    setDropdownOpen(false);
                    setActiveTab("tab2");
                  }}
                >
                  <button>
                    {isActiveMeeting ? (
                      <img
                        src="/Assets/sidebar_meeting_active.svg"
                        alt="meeting"
                      />
                    ) : (
                      <img src="/Assets/sidebar_meeting.svg" alt="meeting" />
                    )}

                    <span>{t("sidebar.meetings")}</span>
                  </button>
                </NavLink>
              }
              {
                <NavLink
                  className={`nav-link ${isActiveAction ? "active" : ""}`}
                  activeClassName="active"
                  to={"/action"}
                  activeStyle={{ color: "#0026B1" }}
                  onClick={() => {
                    setDropdownOpen(false);
                    // setActiveTab("tab2");
                  }}
                >
                  <button>
                    {isActiveAction ? (
                      <img
                        src="/Assets/sidebar-action-active.svg"
                        alt="action"
                      />
                    ) : (
                      <img src="/Assets/sidebar-action.svg" alt="action" />
                    )}

                    <span>{t("sidebar.action")}</span>
                  </button>
                </NavLink>
              }

              {/* role !== "User" && */}

              {/* {
              <NavLink
                className={`nav-link ${isActiveSupport ? "active" : ""}`}
                activeClassName={"active"}
                to="/customer-support"
                activeStyle={{ color: "#0026B1" }}
                onClick={() => setDropdownOpen(false)}
              >
                <button
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 3.53846C7.32682 3.53846 3.53846 7.32682 3.53846 12C3.53846 16.6732 7.32682 20.4615 12 20.4615C16.6732 20.4615 20.4615 16.6732 20.4615 12C20.4615 7.32682 16.6732 3.53846 12 3.53846ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                      stroke={isActiveSupport ? "#0026B1" : "white"}
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 8.15385C11.2919 8.15385 10.7179 8.72784 10.7179 9.4359C10.7179 9.86073 10.3736 10.2051 9.94872 10.2051C9.52388 10.2051 9.17949 9.86073 9.17949 9.4359C9.17949 7.87817 10.4423 6.61539 12 6.61539C13.5577 6.61539 14.8205 7.87817 14.8205 9.4359C14.8205 9.99624 14.6563 10.5206 14.3731 10.9606C14.1958 11.2363 13.9884 11.4994 13.7972 11.7371C13.7622 11.7805 13.7279 11.8231 13.6941 11.8649C13.5355 12.0612 13.3898 12.2415 13.2553 12.4264C12.9234 12.8826 12.7692 13.2253 12.7692 13.5385C12.7692 13.9633 12.4248 14.3077 12 14.3077C11.5752 14.3077 11.2308 13.9633 11.2308 13.5385C11.2308 12.7187 11.6332 12.0408 12.0112 11.5213C12.171 11.3018 12.346 11.0853 12.5051 10.8884C12.537 10.849 12.5683 10.8103 12.5986 10.7726C12.7885 10.5365 12.9502 10.3289 13.0794 10.1282C13.2076 9.92891 13.2821 9.69227 13.2821 9.4359C13.2821 8.72784 12.7081 8.15385 12 8.15385Z"
                      stroke={isActiveSupport ? "#0026B1" : "white"}
                    />
                    <path
                      d="M13.0256 16.6154C13.0256 17.1818 12.5664 17.641 12 17.641C11.4336 17.641 10.9744 17.1818 10.9744 16.6154C10.9744 16.0489 11.4336 15.5897 12 15.5897C12.5664 15.5897 13.0256 16.0489 13.0256 16.6154Z"
                      stroke={isActiveSupport ? "#0026B1" : "white"}
                    />
                  </svg>

                  <span>{t("sidebar.customer-support")}</span>
                </button>
              </NavLink>
            } */}
            </div>
            {/* <div
              style={{
                position: "absolute",
                bottom: "20px",
                width: "100%",
                textAlign: "center",
              }}
            >
              <img
                src="/Assets/tektime-sidebar-logo.svg"
                alt="tektime"
                width={"40px"}
                C
              />
            </div> */}
          </div>
        </div>{" "}
      </div>
    </>
  );
}
export default Sidebar;
