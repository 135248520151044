import React, { useEffect, useRef, useState } from "react";
import { Assets_URL } from "../../Apicongfig";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  MdKeyboardArrowDown,
  MdOutlinePhotoSizeSelectActual,
} from "react-icons/md";
import { BsFiletypePdf } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { AiOutlinePlaySquare } from "react-icons/ai";
import { IoCopyOutline, IoVideocamOutline } from "react-icons/io5";
import { PiFilePdfLight } from "react-icons/pi";
import { formatStepDate } from "../../Utils/MeetingFunctions";
import moment from "moment";
import { HiUserCircle } from "react-icons/hi2";
import { RiFileExcel2Line, RiFolderVideoLine } from "react-icons/ri";
import Spreadsheet from "react-spreadsheet";
import axios from "axios";
import { read, utils } from "xlsx";
import { FaRegFileAudio } from "react-icons/fa";
import DOMPurify from "dompurify";

const ReportStepCard = ({ data, startTime, users, meeting }) => {
  const containerRef = useRef(null);

  const [t] = useTranslation("global");
  const [excelData, setExcelData] = useState([]); // Change to array
  console.log("excelData", excelData);
  const [dropdownVisible, setDropdownVisible] = useState(
    Array(data?.length).fill(true)
  );
  const dropdownRefs = useRef([]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
        // Check links and add a "not-openable" class if necessary
        container.querySelectorAll("a").forEach((link) => {
            const href = link.getAttribute("href");
            if (!href || href.trim() === "#") {
                link.classList.add("not-openable");
            } else {
                link.classList.remove("not-openable");
            }
        });

        // Add click handling to open valid links
        container.addEventListener("click", (e) => {
            const target = e.target;
            if (target.tagName === "A") {
                const href = target.getAttribute("href");
                if (!href || href.trim() === "#") {
                    e.preventDefault();
                } else {
                    e.preventDefault();
                    window.open(href, "_blank", "noopener,noreferrer");
                }
            }
        });
    }

    return () => {
        if (container) {
            container.removeEventListener("click", null);
        }
    };
}, []);

  // Fetch a single Excel file
  const fetchExcel = async (file) => {
    try {
      const fileResponse = await axios.get(`${Assets_URL}/${file}`, {
        responseType: "arraybuffer",
      });

      const fileData = fileResponse.data;
      const workbook = read(fileData, { type: "buffer" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonSheetData = utils.sheet_to_json(worksheet, { header: 1 });

      const formattedData = jsonSheetData.map((row, rowIndex) =>
        row.map((cell) => ({
          value: cell,
          readOnly: rowIndex === 0,
        }))
      );

      return formattedData; // Return the formatted data
    } catch (error) {
      console.error("Error fetching Excel file:", error);
      return null; // Return null on error
    }
  };

  const fetchAllExcelData = async () => {
    const dataWithExcel = []; // Array to hold Excel data for each step
    for (const item of data) {
      const stepData = { ...item }; // Copy item to avoid mutating original
      if (item.editor_type === "Excel" && item.file) {
        const fetchedData = await fetchExcel(item.file);
        console.log("fetchedData", fetchedData);
        if (fetchedData) {
          stepData.excelData = fetchedData; // Attach fetched Excel data to the step
        }
      }
      dataWithExcel.push(stepData); // Collect all step data including Excel data
      console.log("data with Excel data", dataWithExcel);
    }
    setExcelData(dataWithExcel); // Set the state with the enriched data
  };

  useEffect(() => {
    fetchAllExcelData();
  }, [data]);

  const toggleDropdown = (index) => {
    setDropdownVisible((prev) => {
      const newDropdownVisible = [...prev];
      newDropdownVisible[index] = !newDropdownVisible[index];
      return newDropdownVisible;
    });
  };
  useEffect(() => {
    data?.forEach((item, index) => {
      const el = dropdownRefs.current[index];
      if (el) {
        if (dropdownVisible[index]) {
          el.style.display = "block";
          requestAnimationFrame(() => {
            el.classList.add("show");
          });
        } else {
          el.classList.remove("show");
          el.addEventListener(
            "transitionend",
            () => {
              el.style.display = "none";
            },
            { once: true }
          );
        }
      }
    });
  }, [dropdownVisible, data]);

  const convertTo24HourFormat = (time) => {
    if (!time) return;
    const momentTime = moment(time, "hh:mm:ss A"); // Parse 12-hour format
    return momentTime.isValid() ? momentTime.format("HH[h]mm[m]ss") : time; // Convert to 24-hour
  };
  const localizeTimeTaken = (timeTaken) => {
    if (!timeTaken) return;

    const timeUnits = t("time_unit", { returnObjects: true });

    return timeTaken
      .split(" - ")
      .map((part) => {
        // Check if the part contains time, e.g., "11:31:01 PM"
        const timeMatch = part.match(/(\d{1,2}:\d{2}:\d{2} [APM]{2})/);
        let localizedPart = part;

        // If a time match is found, convert it to 24-hour format
        if (timeMatch) {
          const convertedTime = convertTo24HourFormat(timeMatch[0]);
          localizedPart = part.replace(timeMatch[0], convertedTime);
        }

        // Split the rest into count and units (if applicable)
        const [count, ...unitParts] = localizedPart.split(" ");
        const unit = unitParts.join(" "); // Handle cases like "days" and "hours"

        return `${count} ${timeUnits[unit] || unit}`; // Localize the unit
      })
      .join(" - ");
  };

  const localizeTimeTakenActive = (timeTaken) => {
    if (!timeTaken) return "";

    // Retrieve localized time units
    const timeUnits = t("time_unit", { returnObjects: true });

    // Split the timeTaken string by " - " to separate time components
    const timeParts = timeTaken.split(" - ");

    // Initialize variables for each time component
    let days = null;
    let hours = null;
    let minutes = null;
    let seconds = null;

    // Iterate over each part and assign it to the corresponding variable
    timeParts.forEach((part) => {
      if (part.includes("day")) {
        days = part;
      } else if (part.includes("hour")) {
        hours = part;
      } else if (part.includes("min")) {
        minutes = part;
      } else if (part.includes("sec")) {
        seconds = part;
      }
    });

    // Check if days are present
    const hasDays = Boolean(days);

    // Determine what to show based on the presence of days
    let result = "";
    if (hasDays) {
      // Show days and hours if days are present
      result = [days, hours].filter(Boolean).join(" - ");
    } else if (hours) {
      // Show only hours and minutes if hours and minutes are present
      result = [hours, minutes].filter(Boolean).join(" - ");
    } else if (minutes) {
      // Show minutes only if no days or hours are present
      // result = minutes;
      result = [minutes, seconds].filter(Boolean).join(" - ");
    } else {
      result = seconds;
    }

    // Return empty string if result is undefined or empty
    if (!result) return "";

    // Localize and return the result
    return result
      .split(" ")
      .map((part) => (isNaN(part) ? timeUnits[part] || part : part))
      .join(" ");
  };

  // Helper function to format date into dd/mm/yyyy
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getYoutubeEmbedUrl = (url) => {
    if (!url) {
      return false;
    }
    if (url.includes("youtube.com/watch")) {
      const videoUrl = new URL(url);
      const videoId = videoUrl.searchParams.get("v");

      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }
    if (url.includes("youtu.be/")) {
      const videoId = url.split("youtu.be/")[1];
      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }

    return false; // Return false if it's not a valid YouTube URL
  };
  // Function to calculate step dates based on meeting date and step times
  const calculateStepDates = (steps, meetingDate) => {
    if (!steps || !meetingDate) {
      console.error("Steps or meetingDate is null or undefined");
      return [];
    }
    const stepsWithDates = [];
    let currentDate = new Date(meetingDate); // Initialize with meeting date

    steps.forEach((step, index) => {
      if (index === 0) {
        // For the first step, use the meeting date
        stepsWithDates.push(formatDate(currentDate));
      } else {
        // For subsequent steps, add the time_taken of the previous step to the current date
        const previousStep = steps[index - 1];

        // Parse time_taken based on its format
        let timeToAdd = 0;
        if (previousStep.time_taken?.includes("day")) {
          timeToAdd = parseInt(previousStep?.time_taken) * 24 * 60 * 60 * 1000; // Convert days to milliseconds
        } else if (previousStep.time_taken?.includes("hour")) {
          timeToAdd = parseInt(previousStep?.time_taken) * 60 * 60 * 1000; // Convert hours to milliseconds
        } else if (previousStep.time_taken?.includes("minute")) {
          timeToAdd = parseInt(previousStep?.time_taken) * 60 * 1000; // Convert minutes to milliseconds
        } else if (previousStep.time_taken?.includes("second")) {
          timeToAdd = parseInt(previousStep?.time_taken) * 1000; // Convert seconds to milliseconds
        }

        // Add timeToAdd to currentDate
        currentDate = new Date(currentDate.getTime() + timeToAdd);
        stepsWithDates.push(formatDate(currentDate));
      }
    });

    return stepsWithDates;
  };
  const dates = calculateStepDates(meeting?.steps, meeting?.date);
  return (
    <div className="row" style={{ marginBottom: "6rem" }}>
      {data?.map((item, index) => {
        let editorContent = item.editor_content;
        // Create a temporary div element to parse the HTML content
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = editorContent;

        // Find the first image tag
        const firstImageTag = tempDiv.querySelector("img");

        // Extract the src attribute from the first image tag
        const firstImageUrl = firstImageTag
          ? firstImageTag.getAttribute("src")
          : "";

        let stepTime = item.count2;
        console.log("stepTime: ", stepTime);
        console.log("startTime: ", startTime);

        // Parse the time correctly
        let [time, modifier] = startTime.split(" ");
        let [hours, minutes] = time.split(":").map(Number);

        // Convert to 24-hour format if necessary
        if (modifier === "PM" && hours < 12) {
          hours += 12;
        }
        if (modifier === "AM" && hours === 12) {
          hours = 0;
        }

        // Create a new Date object and set the hours and minutes
        let startDate = new Date();
        startDate.setHours(hours);
        startDate.setMinutes(minutes);
        startDate.setSeconds(0);

        // Add stepTime minutes to the start date
        startDate.setMinutes(startDate.getMinutes() + stepTime);

        // Format the new time in 12-hour format with AM/PM
        const formattedTimeAfterAddingStepsTime = startDate.toLocaleTimeString(
          "en-US",
          {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        );

        console.log(
          "formattedTime after adding step count2",
          formattedTimeAfterAddingStepsTime
        );

        const sanitizedContent = DOMPurify.sanitize(item?.editor_content || "");

        return (
          <div className="col-md-12" key={index}>
            <Card
              className="mt-4 step-card"
              onClick={() => toggleDropdown(index)}
              style={{ cursor: "pointer" }}
            >
              <Card.Body className="d-flex">
                <div
                  className={` ${
                    dropdownVisible[index]
                      ? "d-none"
                      : "d-block d-flex align-items-start"
                  }`}
                  // style={{ display: dropdownVisible[index] ? "none" : "block" }}
                >
                  {item.editor_content &&
                  item.editor_content.trim() !==
                    "<html><head></head><body></body></html>" ? (
                    <div
                      style={{
                        width: "193.14px",
                        height: "104px",
                        background: "#eaeaef",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="report-step-img"
                    >
                      {firstImageUrl ? (
                        <Card.Img
                          className={dropdownVisible[index] ? "step-img" : ""}
                          src={firstImageUrl}
                          style={{
                            height: "-webkit-fill-available",
                            width: "-webkit-fill-available",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            background: "#eaeaef",
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FiEdit
                            className="file-img img-fluid"
                            style={{ padding: "40px" }}
                          />

                          {/* <img
                            src="/Assets/Tek.png"
                            style={{ width: "50px", height: "50px" }}
                            alt="Fallback Image"
                          /> */}
                        </div>
                      )}
                    </div>
                  ) : item.editor_type === "File" ? (
                    <div
                      style={{
                        background: "#eaeaef",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="report-step-img"
                    >
                      <PiFilePdfLight
                        className="file-img img-fluid"
                        style={{ padding: "40px" }}
                      />
                    </div>
                  ) : item.editor_type === "Excel" ? (
                    <div
                      style={{
                        background: "#eaeaef",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="report-step-img"
                    >
                      <RiFileExcel2Line
                        className="file-img img-fluid"
                        style={{ padding: "42px" }}
                      />
                    </div>
                  ) : item?.editor_type === "Video" ? (
                    <div
                      style={{
                        background: "#eaeaef",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="report-step-img"
                    >
                      <IoVideocamOutline
                        className="file-img img-fluid"
                        style={{ padding: "40px" }}
                      />
                    </div>
                  ) : item?.editor_type === "Video Report" ? (
                    <div
                      style={{
                        background: "#eaeaef",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="report-step-img"
                    >
                      <RiFolderVideoLine
                        className="file-img img-fluid"
                        style={{ padding: "40px" }}
                      />
                    </div>
                  ) : item?.editor_type === "Audio Report" ? (
                    <div
                      style={{
                        background: "#eaeaef",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="report-step-img"
                    >
                      <FaRegFileAudio
                        className="file-img img-fluid"
                        style={{ padding: "40px" }}
                      />
                    </div>
                  ) : item?.editor_type === "Photo" ? (
                    <div
                      style={{
                        background: "#eaeaef",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="report-step-img"
                    >
                      <MdOutlinePhotoSizeSelectActual
                        className="file-img img-fluid"
                        style={{ padding: "40px" }}
                      />
                    </div>
                  ) : item.url ? (
                    <div
                      style={{
                        width: "193.14px",
                        height: "104px",
                        background: "#eaeaef",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="report-step-img"
                    >
                      <IoCopyOutline
                        className="file-img img-fluid"
                        style={{ padding: "40px" }}
                      />
                      {/* <Card.Img
                        className="link-img"
                        style={{
                          height: "35px",
                          width: "35px",
                          filter: "opacity(0.5)",
                        }}
                        // src="/Assets/Thumbnail.png"
                        src={`/Assets/link-removebg.png`}
                      /> */}
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "193.14px",
                        height: "104px",
                        background: "#eaeaef",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="report-step-img"
                    >
                      {/* <Card.Img
                        className="link-img"
                        style={{
                          height: "50px",
                          width: "50px",
                        }}
                        src={`/Assets/Tek.png`}
                      /> */}
                      <FiEdit
                        className="file-img img-fluid"
                        style={{ padding: "40px" }}
                      />
                    </div>
                  )}
                </div>

                <div className="d-flex flex-column justify-content-center w-100">
                  <div className="mx-3 d-flex justify-content-between align-items-center step-data w-100">
                    <Card.Title
                      className="step-card-heading"
                      style={{ fontSize: "larger" }}
                    >
                      {item?.title}
                    </Card.Title>

                    <span style={{ padding: "10px" }}>
                      <span
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "16.41px",
                          textAlign: "left",
                          color: "#92929D",
                        }}
                      >
                        {meeting?.type === "Special" ||
                        meeting?.type === "Law" ? (
                          <>
                            {item.time_taken === "0 sec"
                              ? item.step_time || "0 sec"
                              : localizeTimeTakenActive(
                                  item?.time_taken?.replace("-", "")
                                ) +
                                item.count2 +
                                " " +
                                t(`time_unit.${item.time_unit}`)}
                          </>
                        ) : (
                          <>
                            {item.time_taken === "0 sec"
                              ? item.step_time || "0 sec"
                              : localizeTimeTakenActive(
                                  item?.time_taken?.replace("-", "")
                                ) +
                                " / " +
                                item.count2 +
                                " " +
                                t(`time_unit.${item.time_unit}`)}
                          </>
                        )}
                      </span>
                      <MdKeyboardArrowDown
                        // onClick={() => toggleDropdown(index)}
                        style={{ cursor: "pointer" }}
                        size={26}
                      />
                    </span>
                  </div>

                  <div style={{ padding: "10px" }}>
                    <Card.Text className="step-card-content d-flex align-items-center">
                      <span>
                        {item.assigned_team ? (
                          <>
                            {item?.assigned_team?.logo ? (
                              <img
                                height="24px"
                                width="24px"
                                style={{
                                  marginRight: "9px",
                                  borderRadius: "20px",
                                  objectFit: "cover",
                                  objectPosition: "top",
                                }}
                                src={
                                  Assets_URL + "/" + item?.assigned_team?.logo
                                }
                                alt={item?.assigned_team?.name}
                              />
                            ) : (
                              <HiUserCircle
                                style={{
                                  height: "24px",
                                  width: "24px",
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {item?.image ? (
                              <img
                                height="24px"
                                width="24px"
                                style={{
                                  marginRight: "9px",
                                  borderRadius: "20px",
                                  objectFit: "cover",
                                  objectPosition: "top",
                                }}
                                src={
                                  item?.image?.startsWith("users/")
                                    ? Assets_URL + "/" + item?.image
                                    : item?.image?.startsWith("storage/")
                                    ? Assets_URL + "/" + item.image
                                    : item?.image
                                }
                                // src={
                                //     item?.assigned_to_image
                                // }
                                alt="img"
                              />
                            ) : (
                              <img
                                height="24px"
                                width="24px"
                                style={{
                                  marginRight: "9px",
                                  borderRadius: "20px",
                                  objectFit: "cover",
                                  objectPosition: "top",
                                }}
                                src={
                                  users?.image?.startsWith("users/")
                                    ? Assets_URL + "/" + users?.image
                                    : users?.image?.startsWith("storage/")
                                    ? Assets_URL + "/" + users?.image
                                    : users?.image
                                }
                                alt="img"
                              />
                            )}
                          </>
                        )}
                        {/* <span>
                          {item?.assigned_to_name ||
                            `${users?.firstName} ${users?.lastName}`}
                        </span> */}
                        {item.assigned_team ? (
                          <span>{item?.assigned_team?.name}</span>
                        ) : (
                          <span>
                            {item?.assigned_to_name ||
                              `${users?.firstName} ${users?.lastName}`}
                          </span>
                        )}
                      </span>

                      <img
                        height="16px"
                        width="16px"
                        src="/Assets/ion_time-outline.svg"
                        style={{ marginLeft: "1rem", marginRight: ".2rem" }}
                      />

                      {item.time_unit === "days" ? (
                        <span className="me-2">
                          {formatStepDate(item?.start_date)}
                        </span>
                      ) : (
                        <span className="me-2">
                          {formatStepDate(item?.start_date) +
                            " " +
                            `${t("at")}` +
                            " " +
                            localizeTimeTaken(item?.step_time)}
                        </span>
                      )}

                      {/* <img
                        height="16px"
                        width="16px"
                        src="/Assets/alarm-invite.svg"
                      />
                      <span>{localizeTimeTaken(item?.time_taken)}</span> */}
                    </Card.Text>
                  </div>

                  <div
                    className={` ${
                      dropdownVisible[index]
                        ? "d-block"
                        : "d-none d-flex align-items-start"
                    }`}
                  >
                    {
                      item.editor_content &&
                      item.editor_content.trim() !==
                        "<html><head></head><body></body></html>" ? (
                        <div
                          style={{
                            // width: "193.14px",
                            // height: "104px",
                            width: "auto",
                            height: meeting?.type === "Law" ? "auto" : "500px",
                            // background: "#eaeaef",
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="scrollableContainer rendered-content"
                            style={{
                              height: meeting?.type === "Law" && "auto",
                            }}
                          >
                            {/* <div
                              dangerouslySetInnerHTML={{
                                __html: (() => {
                                  if (item.editor_content === null) {
                                    return null;
                                  }
                                  return item?.editor_content;
                                })(),
                              }}
                            /> */}

                            <div
                              ref={containerRef}
                              dangerouslySetInnerHTML={{
                                __html: sanitizedContent,
                              }}
                              style={{
                                border: "1px solid #ddd",
                                padding: "10px",
                                borderRadius: "5px",
                              }}
                            />
                          </div>
                        </div>
                      ) : item.file && item?.editor_type !== "Excel" ? (
                        <div
                          style={{
                            borderRadius: "10px",
                          }}
                        >
                          <iframe
                            src={`${Assets_URL}/${item.file}#toolbar=0&view=fitH`}
                            width="100%"
                            height="400px"
                          />
                        </div>
                      ) : item.file && item?.editor_type === "Excel" ? (
                        <div
                          style={{
                            borderRadius: "10px",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "630px",
                              overflowY: "auto",
                              textAlign: "center",
                            }}
                          >
                            {excelData.length > 0 ? (
                              <Spreadsheet
                                data={excelData[index]?.excelData || []}
                              />
                            ) : (
                              <p>No Excel data available for this step.</p>
                            )}
                          </div>
                        </div>
                      ) : item.url ? (
                        <div
                          style={{
                            // width: "auto",
                            // height: "500px",
                            // background: "#eaeaef",
                            borderRadius: "10px",
                            // display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                          }}
                        >
                          <iframe
                            src={getYoutubeEmbedUrl(item?.url)}
                            width="100%"
                            height="630px"
                          />
                        </div>
                      ) : null
                      // <div
                      //   style={{
                      //     width: "auto",
                      //     height: "500px",
                      //     background: "#eaeaef",
                      //     borderRadius: "10px",
                      //     display: "flex",
                      //     alignItems: "center",
                      //     justifyContent: "center",
                      //   }}
                      // >
                      //   <Card.Img
                      //     className="link-img"
                      //     style={{
                      //       height: "50px",
                      //       width: "50px",
                      //     }}
                      //     src={`/Assets/Tek.png`}
                      //   />
                      // </div>
                    }
                  </div>
                  {dropdownVisible[index] && (
                    <div
                      className="dropdown-content-1  fade"
                      ref={(el) => (dropdownRefs.current[index] = el)}
                      style={{ display: "none", margin: "6px" }}
                    >
                      <div className="dropdown-section-1">
                        {meeting?.meeting_notes === null && (
                          <>
                            {" "}
                            <h6>{t("summary")}</h6>
                            <h6>
                              Total Characters:{" "}
                              {
                                item?.note
                                  ?.replace(
                                    /\*\*(.*?)\*\*/g,
                                    '<b style="font-size:16px">$1</b>'
                                  )
                                  .replace(/(\d+)\./g, "$1. ")
                                  .replace(/(\.|\))\s*-?\s*/g, "$1")?.length
                              }
                            </h6>
                          </>
                        )}
                        {item?.time_unit === "days" ? (
                          <>
                            <div className="row d-flex flex-column">
                              <div
                                className="d-flex gap-3 fs-4"
                                style={{ color: "black" }}
                              >
                                <h6 style={{ color: "black" }}>
                                  Campaign Name:{" "}
                                </h6>
                                <h6 style={{ color: "black" }}>
                                  {item?.email_campaigns?.campaign_name}
                                </h6>
                              </div>
                              <div className="d-flex ga-3">
                                <h6 style={{ color: "black" }}>
                                  Total Sendings:{" "}
                                </h6>
                                <h6 style={{ color: "black" }}>
                                  {item?.email_campaigns?.total_sendings}
                                </h6>
                              </div>
                              <div className="d-flex ga-3">
                                <h6 style={{ color: "black" }}>
                                  Total Recipients:{" "}
                                </h6>
                                <h6 style={{ color: "black" }}>
                                  {item?.email_campaigns?.total_recipients}
                                </h6>
                              </div>
                              <div className="d-flex ga-3">
                                <h6 style={{ color: "black" }}>
                                  Total Opens:{" "}
                                </h6>
                                <h6 style={{ color: "black" }}>
                                  {item?.email_campaigns?.total_opens}
                                </h6>
                              </div>
                              <div className="d-flex ga-3">
                                <h6 style={{ color: "black" }}>
                                  Total clicks:{" "}
                                </h6>
                                <h6 style={{ color: "black" }}>
                                  {item?.email_campaigns?.total_clicks}
                                </h6>
                              </div>
                              <div className="d-flex ga-3">
                                <h6 style={{ color: "black" }}>
                                  Total Unsubscribes:{" "}
                                </h6>
                                <h6 style={{ color: "black" }}>
                                  {item?.email_campaigns?.total_unsubscribes}
                                </h6>
                              </div>
                            </div>
                          </>
                        ) : meeting?.meeting_notes === null ? (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: (() => {
                                  if (item?.note === null) {
                                    return null;
                                  }
                                  return item?.note;
                                  // ?.replace(
                                  //   /\*\*(.*?)\*\*/g,
                                  //   '<b style="font-size:16px">$1</b>'
                                  // )
                                  // .replace(/(\d+)\./g, "$1. ")
                                  // .replace(/(\.|\))\s*-?\s*/g, "$1");
                                })(),
                              }}
                            />
                          </>
                        ) : //    <div
                        //   dangerouslySetInnerHTML={{
                        //     __html: (() => {
                        //       if (item?.original_note === null) {
                        //         return null;
                        //       }
                        //       return item?.original_note
                        //     })(),
                        //   }}
                        // />
                        null}
                      </div>
                      {/* <div className="dropdown-section-1 my-4">
                        <h6>Step's Decisions</h6>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: (() => {
                              if (item.decision === null) {
                                return null;
                              }
                              return item.decision;
                            })(),
                          }}
                        />
                      </div> */}
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export default ReportStepCard;
