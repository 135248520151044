import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner, Card, ProgressBar } from "react-bootstrap";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { IoEyeOutline } from "react-icons/io5";
import NoContent from "../Meeting/NoContent";
import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "../../../context/HeaderTitleContext";
import { askPermission } from "../../Utils/askPermission";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Avatar, Tooltip } from "antd";
import { AiOutlinePlaySquare } from "react-icons/ai";
import { RiEditBoxLine, RiPresentationFill } from "react-icons/ri";
import { IoCopyOutline } from "react-icons/io5";
import { useMeetings } from "../../../context/MeetingsContext";
import { useDraftMeetings } from "../../../context/DraftMeetingContext";
import { FaLink } from "react-icons/fa6";
import { LuLink } from "react-icons/lu";
import copy from "copy-to-clipboard";
import { openLinkInNewTab } from "../../Utils/openLinkInNewTab";
import { MdAssessment } from "react-icons/md";
import { useDestinations } from "../../../context/DestinationsContext";
import AddDestination from "./AddDestination";
import ConfirmationModal from "../../Utils/ConfirmationModal";
import SkeletonCards from "../../Utils/SkeletonCard";

const CompletedDestinations = ({
  setActiveTab,
  selectedView,
  allDestinations,
}) => {
  //   const { isLoading, getDes, timeUnitsTotal } = useMeetings();
  const { getDestinations, isLoading,progress } = useDestinations();
  const { language } = useDraftMeetings();

  const effectRan = React.useRef(false);
  const { title, pushHeaderTitle, popHeaderTitle, setHeaderTitle } =
    useHeaderTitle();
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  // const [meetings, setMeetings] = useState([]);
  const moment = require("moment");
  require("moment/locale/fr");
  useEffect(() => {
    SpeechRecognition.stopListening();
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  //Delete Destination
  const handleDelete = async (id) => {
    // const permissionGranted = askPermission(
    //   t("destination.destinationDeleteConfirmMsg")
    // );

    // if (!permissionGranted) return;

    try {
      const response = await axios.delete(
        `${API_BASE_URL}/destinations/${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      // Check for successful deletion (assuming HTTP status 200)
      if (response.status === 200) {
        toast.success(t("destination.destinationDeleteMsg"));
        getDestinations();
      } else {
        // Handle other status codes (e.g., 4xx, 5xx) appropriately
        throw new Error("Échec de la suppression de la réunion");
      }
    } catch (error) {
      // Improve error handling, provide informative messages
      toast.error(t(error.message));
    }
  };

  const handleDeleteClick = (e, id) => {
    e.stopPropagation();
    setItemIdToDelete(id);
    setShowConfirmationModal(true);
  };

  const confirmDelete = (e) => {
    e.stopPropagation();
    setShowConfirmationModal(false);
    handleDelete(itemIdToDelete);
  };
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const handleEdit = (item) => {
    setCurrentItem(item);
    setShowEditModal(true);
  };

  const handleClose = () => {
    setCurrentItem(null);
    setShowEditModal(false);
  };

  const viewDestinationMeeting = (item) => {
    navigate(`/invitiesToMeeting/${item.id}`);
  };

  useEffect(() => {
    return () => {
      effectRan.current = true;
    };
  }, []);

  // Function to determine if all meetings for a destination are closed
  const isAllMeetingsClosed = (meetings) => {
    return meetings.every(
      (meeting) => meeting.status === "closed" || meeting.status === "abort"
    );
  };

  // Filter out destinations where all meetings are closed
  const completedDestinations = allDestinations?.filter(
    (destination) =>
      destination.meetings.length > 0 &&
      isAllMeetingsClosed(destination.meetings)
  );
  console.log("completed destinations:", completedDestinations);

  const sortedDestinations = [...completedDestinations];
  const formatDate = (item) => {
    let date;
    if (language === "en") {
      date = moment(item.date).locale("en-gb"); // Set locale to English
    } else {
      date = moment(item.date); // Set into french
    }
    const today = moment().startOf("day"); // Get today date

    const day = date.format("ddd").toUpperCase();
    const dayNumber = date.format("DD");

    // Compare if the date is today
    const isToday = date.isSame(today, "day");

    // Determine the color based on whether it's today or not
    const color =
      isToday ||
      moment().isAfter(
        moment(`${item.date} ${item.start_time}`, "YYYY-MM-DD HH:mm")
      )
        ? "#e7796a"
        : "#4C4C4C";
    return (
      <div style={{ color }}>
        {day}
        <br />
        {dayNumber}
      </div>
    );
  };

  const timeUnitsToSeconds = (time, unit) => {
    switch (unit) {
      case "hours":
        return time * 3600;
      case "minutes":
        return time * 60;
      case "seconds":
        return time;
      case "days":
        return time * 86400; // 24 * 60 * 60
      default:
        console.error("Invalid time unit:", unit);
        return 0;
    }
  };

  const calculateEndTime = (start, steps) => {
    if (!start || !Array.isArray(steps)) {
      console.error("Invalid start time or steps:", { start, steps });
      return "Invalid time";
    }

    // Check if any step has time unit in seconds
    const hasSeconds = steps.some((step) => step.time_unit === "seconds");

    // Calculate total seconds from all steps
    const totalSeconds = steps.reduce((total, step) => {
      return total + timeUnitsToSeconds(step.count2, step.time_unit);
    }, 0);

    // Convert start time to seconds
    const [startHours, startMinutes, startSeconds] = start
      .split(":")
      .map(Number);
    const startInSeconds =
      startHours * 3600 + startMinutes * 60 + (startSeconds || 0);

    // Calculate end time in seconds
    const endInSeconds = startInSeconds + totalSeconds;

    // Convert end time in seconds back to HH:MM:SS
    let endHours = Math.floor(endInSeconds / 3600) % 24;
    const endMinutes = Math.floor((endInSeconds % 3600) / 60);
    const endSeconds = endInSeconds % 60;

    // Determine AM/PM
    const period = endHours >= 12 ? "PM" : "AM";
    endHours = endHours % 12 || 12; // Convert to 12-hour format, ensuring 0 becomes 12

    // Format end time to HH:MM or HH:MM:SS based on hasSeconds
    const formattedEndHours = endHours.toString().padStart(2, "0");
    const formattedEndMinutes = endMinutes.toString().padStart(2, "0");
    const formattedEndSeconds = endSeconds.toString().padStart(2, "0");

    const endTime = hasSeconds
      ? `${formattedEndHours}:${formattedEndMinutes}:${formattedEndSeconds} ${period}`
      : `${formattedEndHours}:${formattedEndMinutes} ${period}`;

    return endTime;
  };

  const [openDropdownId, setOpenDropdownId] = useState(null);
  const convertTo12HourFormat = (time, steps) => {
    if (!time || !steps) {
      return;
    }
    // Check if any step has time unit in seconds
    const hasSeconds = steps.some((step) => step.time_unit === "seconds");
    console.log("time", time);
    // Extract hours and minutes from the time string
    // let [hour, minute] = time?.split(":").map(Number);

    // Extract hours, minutes and seconds from the time string
    let [hour, minute, second] = time.split(":").map(Number);

    // Determine AM or PM suffix
    let suffix = hour >= 12 ? "PM" : "AM";

    // Convert hour from 24-hour format to 12-hour format
    hour = hour % 12 || 12; // The hour '0' should be '12'

    // Format the minute to always be two digits
    minute = minute.toString().padStart(2, "0");

    second = second.toString().padStart(2, "0");

    const endTime = hasSeconds
      ? `${hour}:${minute}:${second} ${suffix}`
      : `${hour}:${minute} ${suffix}`;

    return endTime;
  };

  const toggleDropdown = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  const user = JSON.parse(sessionStorage.getItem("user"));
  console.log("user", user);
  const formatDateInFrench = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const calculateTotalDays = (steps) => {
    return steps.reduce((total, step) => {
      return total + step.count2;
    }, 0);
  };
  // Function to calculate total time in a readable format
  function calculateTotalTime(steps) {
    let totalSeconds = 0;
    steps.forEach((step) => {
      switch (step.time_unit) {
        case "hours":
          totalSeconds += step.count2 * 3600;
          break;
        case "minutes":
          totalSeconds += step.count2 * 60;
          break;
        case "seconds":
          totalSeconds += step.count2;
          break;
      }
    });

    const hrs = Math.floor(totalSeconds / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;

    let result = "";
    if (hrs > 0) {
      result += `${hrs} hrs `;
    }
    if (mins > 0) {
      result += `${mins} mins `;
    }
    if (secs > 0) {
      result += `${secs} secs`;
    }
    return result.trim();
  }
  const addDaysToDate = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const meetingsByMonth = {}; // Object to store meetings organized by month
  // Sort destinations by created_at in descending order
  // const sortedDestinationsByDate = [...sortedDestinations].sort((a, b) =>
  //   moment(b.created_at).isBefore(moment(a.created_at)) ? -1 : 1
  // );
  const sortedDestinationsByDate = [...sortedDestinations].sort((a, b) => {
    const dateA = moment(a.meeting_start_date, "DD/MM/YYYY"); // Adjust format if needed
    const dateB = moment(b.meeting_start_date, "DD/MM/YYYY");
  
    return dateB.diff(dateA); // Use diff to compare dates
  });
  // const sortedDestinationsByDate = [...sortedDestinations].sort((a, b) => {
  //   const dateA = moment(a.meeting_start_date, "DD/MM/YYYY"); // Adjust format if needed
  //   const dateB = moment(b.meeting_start_date, "DD/MM/YYYY");

  //   return dateA - dateB;
  // });
  sortedDestinationsByDate?.forEach((item, index) => {
    let date;
    if (language === "en") {
      date = moment(item.meeting_start_date, "DD/MM/YYYY").locale("en-gb"); // Set locale to English
    } else {
      date = moment(item.meeting_start_date, "DD/MM/YYYY"); // Set locale to French if needed
    }
    const month = moment(date).format("MMMM");
    if (!meetingsByMonth[month]) {
      meetingsByMonth[month] = [];
    }
    const guideCount = item?.participant?.filter(
      (participant) => participant?.isCreator !== 1
    )?.length;
    // Check if the meeting buttons should be disabled
    const shouldDisableButtons =
      item?.user_id !== parseInt(sessionStorage.getItem("user_id")) &&
      item?.steps?.some((step) => step?.participant || step?.userPID);

    // Determine if any meetings are late
    // const isAnyMeetingLate = item?.meetings?.some((meeting) =>
    //   moment().isAfter(
    //     moment(`${meeting.date} ${meeting.start_time}`, "YYYY-MM-DD HH:mm")
    //   )
    // );
    // Determine if all meetings are closed
    const areAllMeetingsClosed = item.meetings?.every(
      (meeting) => meeting.status === "closed" || meeting.status === "abort"
    );
    // Determine the overall badge to show
    const overallBadge = areAllMeetingsClosed && "active";
    // : isAnyMeetingLate
    // ? "late"
    // : "active";
    const badgeText = areAllMeetingsClosed && t("badge.finished");
    // : isAnyMeetingLate
    // ? t("badge.late")
    // : t("badge.active");
    // // Determine the overall badge to show
    // const overallBadge = isAnyMeetingLate ? "late" : "active";
    // const badgeText = isAnyMeetingLate ? t("badge.late") : t("badge.active");
    const userId = parseInt(sessionStorage.getItem("user_id"));

    const totalMeetings = item?.total_meetings;
    const completedMeetings = item?.completed_meetings;
    // const percentageCompleted = totalMeetings > 0 ? (completedMeetings / totalMeetings) * 100 : 0;
    const percentageCompleted =
      totalMeetings > 0
        ? Math.floor((completedMeetings / totalMeetings) * 100)
        : 0;

    meetingsByMonth[month].push(
      <Card
        className="mt-3 mb-2 scheduled"
        key={index}
        onClick={(e) => {
          e.stopPropagation();
          viewDestinationMeeting(item);
        }}
      >
        <div className="row">
          <div className="col-md-12 col-lg-12 first-row">
            <div className="first">
              <span className="destination_name">
                {item?.destination_name}{" "}
                {item?.meetings?.length > 0 && (
                  <>
                    {
                      <span className="destination_status">
                        <span className={`badge ms-2 ${overallBadge}`}>
                          {badgeText}
                        </span>
                      </span>
                    }
                  </>
                )}
              </span>
              <div className="options">
                {item.total_meetings > 0 && (
                  <span>
                    {Math.floor(percentageCompleted)}%{" "}
                    {t("destinationPercentage")}
                  </span>
                )}

                {(item?.user_id === userId || item.user_id === null) && (
                  <div className="dropdown dropstart">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded={openDropdownId === item.id}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        padding: "0px",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(item.id);
                      }}
                    >
                      <BiDotsVerticalRounded color="black" size={"25px"} />
                    </button>
                    <ul
                      className={`dropdown-menu ${
                        openDropdownId === item.id ? "show" : ""
                      }`}
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            // setHeaderTitle([
                            //   {
                            //     titleText: t("header.modification"),
                            //     link: `/meeting`,
                            //   },
                            //   {
                            //     titleText: `${item.title}`,
                            //     link: `/updateMeeting/${item?.id}`,
                            //   },
                            // ]);
                            e.stopPropagation();
                            handleEdit(item);
                          }}
                        >
                          <RiEditBoxLine size={"20px"} /> &nbsp;
                          {t("dropdown.To modify")}
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            const currentURL = `/destination/${item?.uuid}--es/${item?.id}`;
                            copy(currentURL);
                            openLinkInNewTab(currentURL);
                          }}
                        >
                          <LuLink size={"20px"} /> &nbsp;
                          {t("destinationInvitation")}

                        </a>
                      </li>
                      {/* {item?.total_meetings === 0 && (
                        <li>
                          <a
                            className="dropdown-item"
                            style={{ cursor: "pointer", color: "red" }}
                            onClick={(e) => handleDeleteClick(e, item?.id)}
                          >
                            <AiOutlineDelete size={"20px"} color="red" />
                            &nbsp; {t("dropdown.Delete")}
                          </a>
                        </li>
                      )} */}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {(item?.meeting_start_date || item?.meeting_end_date) && (
              <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "16.41px",
                  textAlign: "left",
                  color: "#5c5c5c",
                }}
              >
                {item?.meeting_start_date} - {item?.meeting_end_date}
              </span>
            )}
          </div>
          <div className="col-md-12 col-lg-12 second-row">
            <div className="second">
              <p className="description">{item?.destination_description}</p>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 third-row">
            <div className="row">
              <div className="col-md-6">
                {item?.user_id && (
                  <span className="creator_label">Creator</span>
                )}
              </div>
              <div className="col-md-6">
                {/* {guideCount > 0 && (
                  <span className="creator_label">Participants</span>
                )} */}
              </div>
              <div className="col-md-6 d-flex align-items-center">
                {item?.user_id && (
                  <div className="guide">
                    <Avatar
                      size="large"
                      src={
                        item?.user?.image?.startsWith("users/")
                          ? Assets_URL + "/" + item?.user?.image
                          : item?.user?.image
                      }
                    />
                    <span className="creator-name">
                      {item?.user?.full_name}
                    </span>
                  </div>
                )}
              </div>
              {/* <div className="col-md-6">
                <div className="guide d-flex align-items-center">
                  <Avatar.Group maxCount={5} s>
                    {item?.participant?.map((item) => {
                      if (item?.isCreator === 1) {
                        return;
                      }

                      return (
                        <>
                          <Tooltip
                            title={
                              item?.first_name
                                ? item?.first_name
                                : " " + " " + item.last_name
                                ? item.last_name
                                : " "
                            }
                            placement="top"
                          >
                            <Avatar
                              size="large"
                              src={
                                item.participant_image.startsWith("users/")
                                  ? Assets_URL + "/" + item.participant_image
                                  : item.participant_image
                              }
                            />
                          </Tooltip>
                        </>
                      );
                    })}
                  </Avatar.Group>
                  <span
                    style={{
                      marginLeft: guideCount > 0 ? "8px" : "0px",
                    }}
                  >
                    {guideCount > 0 ? `${guideCount} ${t("presentor")}` : ""}
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Card>
    );
  });

  // Sort the keys (months) in descending order
  const sortedMonths = Object.keys(meetingsByMonth);
  return (
    <div className="current_destinations">
      <div className="my-2 container-fluid">
        {/* {sortedDestinations?.length === 0 && !isLoading ? (
          <NoContent title="Current Destinations" />
        ) : sortedDestinations?.length > 0 ? (
          <>
            {sortedMonths.map((month) => (
              <div key={month}>
                <span className="month">{month}</span>
                {meetingsByMonth[month].map((meeting) => (
                  <React.Fragment key={meeting.key}>{meeting}</React.Fragment>
                ))}
              </div>
            ))}
          </>
        ) : (
          <Spinner
            animation="border"
            role="status"
            className="center-spinner"
          ></Spinner>
        )} */}
         {isLoading ? (
          <div
          className="progress-overlay"
          // style={{ background: "transparent" }}
        >
          <div style={{ width: "50%" }}>
            <ProgressBar now={progress} animated />
          </div>
        </div>
        ) : (
          <>
            {!isLoading && sortedDestinations?.length === 0 ? (
              <>
                <NoContent title="Completed Destinations" />
              </>
            ) : (
              <>
                {sortedMonths.map((month) => (
                  <div key={month}>
                    <span className="month">{month}</span>
                    {meetingsByMonth[month].map((meeting) => (
                      <React.Fragment key={meeting.key}>
                        {meeting}
                      </React.Fragment>
                    ))}
                  </div>
                ))}
              </>
            )}
          </>

        )}

      </div>
      <AddDestination
        show={showEditModal}
        handleClose={handleClose}
        currentItem={currentItem}
      />
      {showConfirmationModal && (
        <ConfirmationModal
          message={t("destination.destinationDeleteConfirmMsg")}
          onConfirm={(e) => confirmDelete(e)}
          onCancel={(e) => {
            e.stopPropagation();
            setShowConfirmationModal(false);
          }}
        />
      )}
    </div>
  );
};

export default CompletedDestinations;
