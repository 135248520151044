import { RxCross2 } from "react-icons/rx";
import MomentDetail from "./components/MomentDetails";
import { useTranslation } from "react-i18next";
import Solution from "./components/Template";
import AddSteps from "./components/AddSteps";
import Options from "./components/Options";
import Privacy from "./components/Privacy";
import { useEffect, useState } from "react"; // Use local state
import Share from "./components/Share";
import { Modal, Button } from "react-bootstrap";
import { useSolutionFormContext } from "../../../../context/CreateSolutionContext";

const NewSolutionModal = ({ meeting }) => {
  const [t] = useTranslation("global");
  const [activeTab, setActiveTab] = useState("tab1"); // Local state for tabs
  const [showConfirmation, setShowConfirmation] = useState(false); // State for confirmation modal
  const {
    checkId,
    deleteSolution,
    handleInputBlur,
    saveDraft,
    open,
    handleCloseModal,
    isUpdated,
    isDuplicate,
    validateAndUpdate,
    addParticipant,
    changePrivacy,
    setAddParticipant,
    setCheckId,
    setMeeting,
    setIsUpdated,
  } = useSolutionFormContext();

  const handleCancel = () => {
    setShowConfirmation(true);
  };

  const handleClose = () => {
    setShowConfirmation(false);
  };

  const handleDelete = () => {
    deleteSolution(checkId);
  };

  const isTabDisabled = (tabName) => {
    const tabOrder = ["tab1", "tab2", "tab3", "tab4", "tab5"];
    const currentIndex = tabOrder.indexOf(activeTab);
    const tabIndex = tabOrder.indexOf(tabName);
    if (isUpdated || isDuplicate) {
      return false;
    }

    return tabIndex > currentIndex;
  };

  return (
    <>
      {open && (
        <div id="chart-container" className="chart-content">
          <div className="modal-overlay">
            <div className="new-meeting-modal">
              <div className="modal-nav">
                <div>
                  {
                    <>
                      <h4>
                        {isUpdated
                          ? t("solution.newMeeting.UpdateMoment")
                          : isDuplicate
                          ? t("solution.newMeeting.DuplicateMoment")
                          : addParticipant || changePrivacy
                          ? t("solution.newMeeting.Add new invite")
                          : t("solution.newMeeting.CreateMoment")}
                      </h4>
                      {!isUpdated && !isDuplicate && !addParticipant && !changePrivacy && (
                        <small style={{ padding: "10px", margin: "0px" }}>
                          {t("solution.newMeeting.CreateMomentDescription")}
                        </small>
                      )}
                    </>
                  }
                </div>
                <div className="d-flex justify-content-end">
                  <button className="cross-btn" onClick={handleCancel}>
                    <RxCross2 size={18} />
                  </button>
                </div>
              </div>
              <div className="mt-3 modal-body">
                <div
                  className="col-md-11"
                  style={{ borderBottom: "2px solid #F2F2F2" }}
                >
                  <div className="tabs" style={{ padding: "0 33px" }}>
                    <div>
                      <button
                        className={`tab ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab1")}
                        disabled={isTabDisabled("tab1")}
                      >
                        {t("solution.NewMeetingTabs.Mission")}
                      </button>
                      <button
                        className={`tab ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab2")}
                        disabled={isTabDisabled("tab2")}
                      >
                        {t("solution.NewMeetingTabs.Solution")}
                      </button>
                      <button
                        className={`tab ${
                          activeTab === "tab3" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab3")}
                        disabled={isTabDisabled("tab3")}
                      >
                        {t("solution.NewMeetingTabs.Add Step")}
                      </button>
                      <button
                        className={`tab ${
                          activeTab === "tab4" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab4")}
                        disabled={isTabDisabled("tab4")}
                      >
                        {t("solution.NewMeetingTabs.Options")}
                      </button>
                      <button
                        className={`tab ${
                          activeTab === "tab5" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab5")}
                        disabled={isTabDisabled("tab5")}
                      >
                        {t("solution.NewMeetingTabs.Privacy")}
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  {activeTab === "tab1" && (
                    <MomentDetail
                      setActiveTab={setActiveTab}
                      meeting={meeting}
                    />
                  )}
                  {activeTab === "tab2" && (
                    <Solution setActiveTab={setActiveTab} meeting={meeting} />
                  )}
                  {activeTab === "tab3" && (
                    <AddSteps setActiveTab={setActiveTab} meeting={meeting} />
                  )}
                  {activeTab === "tab4" && (
                    <Options setActiveTab={setActiveTab} meeting={meeting} />
                  )}
                  {activeTab === "tab5" && (
                    <Privacy
                      setActiveTab={setActiveTab}
                      closeModal={handleCloseModal}
                      meeting={meeting}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={showConfirmation}
            onHide={handleClose}
            dialogClassName="custom-modal-size custom-modal-border cancel-moment-modal modal-dialog-centered"
          >
            <Modal.Header closeButton className="border-0"></Modal.Header>
            <Modal.Body className="text-center p-4">
              <h2 className="w-100 text-center fs-5">{t("Are you sure")}</h2>
              <p className="mb-4" style={{ color: "#92929D" }}>
                {isUpdated || isDuplicate
                  ? ""
                  : addParticipant || changePrivacy
                  ? ""
                  : t("saveAndDraftText")}
              </p>
              <div className="d-flex justify-content-center gap-3 mb-3">
                {isUpdated || addParticipant || changePrivacy? (
                  <Button
                    variant="outline-danger"
                    className="px-4 py-2 confirmation-delete"
                    onClick={handleCloseModal}
                  >
                    {t("Cancel")}
                  </Button>
                ) : (
                  <Button
                    variant="outline-danger"
                    className="px-4 py-2 confirmation-delete"
                    onClick={handleDelete}
                  >
                    {t("Delete")}
                  </Button>
                )}
                <Button
                  variant="primary"
                  className="px-4 py-2 confirmation-save"
                  onClick={
                    isDuplicate || isUpdated
                      ? validateAndUpdate
                      : addParticipant || changePrivacy
                      ? validateAndUpdate
                      : saveDraft
                  }
                >
                  {isUpdated || isDuplicate
                    ? t("Save Solution")
                    : addParticipant || changePrivacy
                    ? t("Save Solution")
                    : t("Save Draft")}
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default NewSolutionModal;
