import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import * as XLSX from "xlsx";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { toast } from "react-toastify";

function ViewFilePreview({
  isModalOpen,
  setIsModalOpen,
  modalContent,
  closeModal,
  isFileUploaded,
  setIsFileUploaded,
  refreshMeetings,
}) {
  const fileUrl = modalContent
    ? `${Assets_URL}/${modalContent?.file_path}`
    : "";
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [t] = useTranslation("global");

  useEffect(() => {
    // If the file is Excel, load its data
    if (
      modalContent?.file_type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      modalContent?.file_type === "application/vnd.ms-excel"
    ) {
      loadExcelData(modalContent?.file_path);
    } else if (
      modalContent?.file_type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      modalContent?.file_type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      loadDocFile(modalContent?.file_path);
    }
  }, [modalContent]);

  const loadExcelData = async (filePath) => {
    setLoading(true);
    try {
      const response = await fetch(`${Assets_URL}/${filePath}`);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(sheet);
        setExcelData(json);
      };
      reader.readAsBinaryString(blob);
    } catch (error) {
      console.error("Error loading Excel file", error);
    } finally {
      setLoading(false);
    }
  };

  const loadDocFile = (filePath) => {
    // Optionally fetch or do other logic if required before displaying the document
    // Currently, the file is directly passed to the FileViewer component
    console.log("Document file loaded:", filePath);
  };

  const deleteFile = async () => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/meeting-files/${modalContent?.id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        setLoading(false);
        setIsModalOpen(false);
        setIsFileUploaded((prev) => !prev);
        refreshMeetings();
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
      setIsModalOpen(false);
    }
  };

  const [isValidFile, setIsValidFile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const validateFile = async () => {
      if (modalContent?.file_path) {
        try {
          const response = await fetch(
            `${Assets_URL}/${modalContent.file_path}`,
            {
              method: "HEAD",
            }
          );
          if (response.ok) {
            setIsValidFile(true);
          } else {
            toast.error("The document could not be found on the server.");
          }
        } catch (error) {
          toast.error("Unable to check document availability.");
        }
      } else {
        toast.error("No file path provided.");
      }
      setIsLoading(false);
    };

    validateFile();
  }, [modalContent?.file_path]);

  if (isLoading) {
    return <p>Loading document...</p>;
  }

  if (!isValidFile) {
    return (
      <div className="fallback">
        <p>Sorry, the document could not be loaded.</p>
      </div>
    );
  }
  return (
    <div className="viewfilepreview">
      <Modal
        open={isModalOpen}
        onCancel={closeModal}
        footer={
          [
            // <Button key="close" onClick={closeModal} className='close-file-modall-button'>
            //     Close
            // </Button>
          ]
        }
        width="80%"
        bodyStyle={{
          overflow: "auto",
          overflowX: "none",
        }}
      >
        {modalContent ? (
          <div>
            {/* Show PDF or Other File Types */}
            {modalContent?.file_type &&
            modalContent?.file_type.includes("pdf") ? (
              <iframe
                title="File Preview"
                src={`${fileUrl}#toolbar=0&view=fitH`}
                className="fileuploadingiframe w-100"
                style={{
                  border: "none",
                  width: "100%",
                  height: "450px",
                }}
              ></iframe>
            ) : modalContent?.file_type?.includes("video") ? (
              <iframe
              title="File Preview"
              src={`${fileUrl}#toolbar=0&view=fitH`}
              className="fileuploadingiframe w-100"
              style={{
                border: "none",
                width: "100%",
                height: "450px",
              }}
            ></iframe>
            ) : null}

            {/* Show Excel Preview if the file is Excel */}
            {modalContent?.file_type === "application/vnd.ms-excel" ? (
              <div className="table-responsive">
                {loading ? (
                  <Spinner
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                  />
                ) : (
                  <div className="table-responsive" style={{ height: "20rem" }}>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          {Object.keys(excelData[0] || {}).map((key, index) => (
                            <th key={index}>{key}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {excelData.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {Object.values(row).map((value, colIndex) => (
                              <td key={colIndex}>{value}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            ) : (modalContent &&
                modalContent?.file_type ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ||
              modalContent?.file_type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              modalContent?.file_type === "text/plain" ||
              modalContent?.file_type === "image/png" ||
              modalContent?.file_type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
              <div className="file-viewer-container">
                <DocViewer
                  documents={[
                    { uri: `${Assets_URL}/${modalContent?.file_path}` },
                  ]}
                  pluginRenderers={DocViewerRenderers}
                  config={{
                    header: {
                      disableFileName: true,
                      retainURLParams: true,
                    },
                  }}
                />
              </div>
            ) : null}

            {/* Delete and Close Buttons */}
            <div className="d-flex gap-2 justify-content-center align-items-center mt-5">
              <Button
                variant="primary"
                className="delete-file-modal-button"
                type="submit"
                onClick={deleteFile}
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                  />
                ) : (
                  <>{t("buttons.Delete")}</>
                )}
              </Button>
              <Button
                key="close"
                onClick={closeModal}
                className="close-file-modall-button"
              >
                Close
              </Button>
            </div>
          </div>
        ) : (
          <p>No content to display.</p>
        )}
      </Modal>
    </div>
  );
}

export default ViewFilePreview;
