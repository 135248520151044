import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { FaRegUser } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import {
  Card,
  Modal,
  Spinner,
  Button,
  OverlayTrigger,
  Tooltip,
  ProgressBar,
} from "react-bootstrap";
import moment from "moment";
import { HiUserCircle } from "react-icons/hi2";
import {
  convertCount2ToSeconds,
  convertTimeTakenToSeconds,
  formatStepDate,
  openGoogleMeet,
  parseTimeTaken,
  typeIcons,
} from "../../Utils/MeetingFunctions";
import { FaArrowRight, FaChevronDown } from "react-icons/fa";
import { useSidebarContext } from "../../../context/SidebarContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useMeetings } from "../../../context/MeetingsContext";
import { useFormContext } from "../../../context/CreateMeetingContext";
import { Avatar } from "antd";

const LazyStepChart = lazy(() =>
  import("../Meeting/CreateNewMeeting/StepChart")
);

function Action({ stepsData }) {
  const { getMeetingsCalculations } = useMeetings();
  const { call } = useFormContext();
  const TINYMCEAPI = process.env.REACT_APP_TINYMCE_API;
  const [t] = useTranslation("global");
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log("loading", loading);
  const userId = parseInt(sessionStorage.getItem("user_id"));
  const { toggle } = useSidebarContext();
  const navigate = useNavigate();
  const titles = [
    "Today",
    "Tomorrow",
    "Rest of Week",
    "Next Week",
    "Rest of Month",
    "Next Month",
    "Rest of Year",
    "Next Year",
  ];
  const defaultIndex = titles.indexOf("Today");

  // const [selectedTitle, setSelectedTitle] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(
    titles[defaultIndex] || null
  );
  console.log("selectedTitle", selectedTitle);
  const [activeIndex, setActiveIndex] = useState(null); // To track which item is active
  const [selectedIndex, setSelectedIndex] = useState(0); // To track which item is active
  console.log("activeIndex", activeIndex);
  const handleTitleClick = (title, index) => {
    setSelectedTitle(title);
    setSelectedIndex(index);
    setActiveIndex(index === activeIndex ? null : index); // Toggle active state
  };
  useEffect(() => {
    if (defaultIndex !== -1) {
      setSelectedTitle(titles[defaultIndex]);
      setActiveIndex(defaultIndex);
    }
  }, []);

  useEffect(() => {
    getMeetingsCalculations();
  }, []);

  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const getActions = async () => {
    // setLoading(true);
    setProgress(0); // Reset progress to 0 at the start
    setShowProgressBar(true); // Show the progress bar
    // Start a progress simulation
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 90) {
          clearInterval(interval); // Stop updating at 90%
          return 90; // Set to 90 before it completes
        }
        return prev + 10; // Increment progress by 10%
      });
    }, 200); // Update every 200ms

    try {
      const response = await axios.get(
        `${API_BASE_URL}/get-user-steps/${userId}?filter=${selectedTitle}`
      );
      if (response.status) {
        const data = response?.data?.data || [];
        setSteps(data);
        setProgress(100); // Set progress to 100% upon completion
      }
    } catch (error) {
      console.error("Error while fetching actions", error);
      clearInterval(interval); // Clear the interval when done
      setProgress(100); // Set progress to 100% upon completion
      setShowProgressBar(false); // Hide the progress bar
    } finally {
      setLoading(false);
      clearInterval(interval); // Clear the interval when done
      setProgress(100); // Set progress to 100% upon completion
      setShowProgressBar(false); // Hide the progress bar
    }
  };
  const refreshedActions = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/get-user-steps/${userId}?filter=${selectedTitle}`
      );
      if (response.status) {
        const data = response?.data?.data || [];
        setSteps(data);
      }
    } catch (error) {
      console.error("Error while fetching actions", error);
    } finally {
    }
  };

  useEffect(() => {
    if (selectedTitle) {
      getActions();
    }
  }, [selectedTitle]);
  useEffect(() => {
    refreshedActions();
  }, [call]);

  const getYoutubeEmbedUrl = (url) => {
    if (!url) {
      return false;
    }
    if (url.includes("youtube.com/watch")) {
      const videoUrl = new URL(url);
      const videoId = videoUrl.searchParams.get("v");

      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
      // Handle the shortened YouTube URL (youtu.be)
    }
    if (url.includes("youtu.be/")) {
      const videoId = url.split("youtu.be/")[1];
      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }

    return false; // Return false if it's not a valid YouTube URL
  };
  // const filteredSteps = useMemo(() => {
  //   if (!selectedTitle) return steps;

  //   const today = moment().startOf("day");
  //   const tomorrow = moment(today).add(1, "days");
  //   const endOfWeek = moment(today).endOf("week");
  //   const nextWeekStart = moment(today).add(1, "weeks").startOf("week");
  //   const endOfMonth = moment(today).endOf("month");
  //   const nextYearStart = moment(today).add(1, "years").startOf("year");

  //   return steps.filter((step) => {
  //     const stepDate = moment(step?.start_date);
  //     switch (selectedTitle) {
  //       case "Today":
  //         return stepDate.isSame(today, "day");
  //       case "Tomorrow":
  //         return stepDate.isSame(tomorrow, "day");
  //       case "Rest of Week":
  //         return stepDate.isAfter(today) && stepDate.isBefore(endOfWeek);
  //       case "Next Week":
  //         return (
  //           stepDate.isAfter(endOfWeek) && stepDate.isBefore(nextWeekStart)
  //         );
  //       case "Rest of Month":
  //         return stepDate.isAfter(endOfWeek) && stepDate.isBefore(endOfMonth);
  //       case "Next Month":
  //         return (
  //           stepDate.isAfter(endOfMonth) &&
  //           stepDate.isBefore(
  //             moment(endOfMonth).add(1, "months").startOf("month")
  //           )
  //         );
  //       case "Rest of Year":
  //         return (
  //           stepDate.isAfter(endOfMonth) && stepDate.isBefore(nextYearStart)
  //         );
  //       case "Next Year":
  //         return stepDate.isAfter(nextYearStart);
  //       default:
  //         return false;
  //     }
  //   });
  // }, [steps, selectedTitle]);

  const upcomingCount = steps?.filter(
    (step) => step.step_status === null
  ).length;

  const formatTimeUnit = (value, singular, plural) => {
    return value === 1 ? `${value} ${singular}` : `${value} ${plural}`;
  };
  // ---------------------------------------TOTAL UPCOMING STEPS TIME-----------------------------------
  const totalUpcomingSteps = steps
    .filter((step) => step.step_status === null)
    .reduce((total, step) => {
      const { count2 = 0, time_unit } = step;

      let countInSeconds = 0;
      switch (time_unit) {
        case "days":
          countInSeconds = count2 * 24 * 60 * 60;
          break;
        case "hours":
          countInSeconds = count2 * 60 * 60;
          break;
        case "minutes":
          countInSeconds = count2 * 60;
          break;
        case "seconds":
          countInSeconds = count2;
          break;
        default:
          countInSeconds = 0; // Handle unknown or missing time_unit
      }

      return total + countInSeconds;
    }, 0);

  const days = Math.floor(totalUpcomingSteps / (24 * 60 * 60));
  const remainingSecondsAfterDays = totalUpcomingSteps % (24 * 60 * 60);
  const hours = Math.floor(remainingSecondsAfterDays / (60 * 60));
  const remainingSecondsAfterHours = remainingSecondsAfterDays % (60 * 60);
  const minutes = Math.floor(remainingSecondsAfterHours / 60);
  const seconds = remainingSecondsAfterHours % 60;

  const formattedTotalTime =
    days > 0
      ? `${formatTimeUnit(days, t("time_unit.day"), t("time_unit.days"))}${
          hours > 0
            ? ` ${formatTimeUnit(
                hours,
                t("time_unit.hour"),
                t("time_unit.hours")
              )}`
            : ""
        }`
      : hours > 0
      ? `${formatTimeUnit(hours, t("time_unit.hour"), t("time_unit.hours"))}${
          minutes > 0
            ? ` ${formatTimeUnit(
                minutes,
                t("time_unit.minute"),
                t("time_unit.minutes")
              )}`
            : ""
        }`
      : minutes > 0
      ? `${formatTimeUnit(
          minutes,
          t("time_unit.minute"),
          t("time_unit.minutes")
        )}${
          seconds > 0
            ? ` ${formatTimeUnit(
                seconds,
                t("time_unit.second"),
                t("time_unit.seconds")
              )}`
            : ""
        }`
      : `${formatTimeUnit(
          seconds,
          t("time_unit.second"),
          t("time_unit.seconds")
        )}`;
  // ------------------------------------------TOTAL COMPLETED STEPS TIME-----------------------------------
  const totalCompletedSteps = steps
    .filter((step) => step.step_status === "completed")
    .reduce((total, step) => {
      const timeTaken = step?.time_taken;
      if (timeTaken) {
        return total + parseTimeTaken(timeTaken);
      }
      return total;
    }, 0);
  const completedDays = Math.floor(totalCompletedSteps / (24 * 3600));
  const remainingSecondsAfterDaysCompleted = totalCompletedSteps % (24 * 3600);
  const completedHours = Math.floor(remainingSecondsAfterDaysCompleted / 3600);
  const remainingSecondsAfterHoursCompleted =
    remainingSecondsAfterDaysCompleted % 3600;
  const completedMinutes = Math.floor(remainingSecondsAfterHoursCompleted / 60);
  const completedSeconds = remainingSecondsAfterHoursCompleted % 60;

  const formattedCompletedTime =
    completedDays > 0
      ? `${formatTimeUnit(
          completedDays,
          t("time_unit.day"),
          t("time_unit.days")
        )}${
          completedHours > 0
            ? ` ${formatTimeUnit(
                completedHours,
                t("time_unit.hour"),
                t("time_unit.hours")
              )}`
            : ""
        }`
      : completedHours > 0
      ? `${formatTimeUnit(
          completedHours,
          t("time_unit.hour"),
          t("time_unit.hours")
        )}${
          completedMinutes > 0
            ? ` ${formatTimeUnit(
                completedMinutes,
                t("time_unit.minute"),
                t("time_unit.minutes")
              )}`
            : ""
        }`
      : completedMinutes > 0
      ? `${formatTimeUnit(
          completedMinutes,
          t("time_unit.minute"),
          t("time_unit.minutes")
        )}${
          completedSeconds > 0
            ? ` ${formatTimeUnit(
                completedSeconds,
                t("time_unit.second"),
                t("time_unit.seconds")
              )}`
            : ""
        }`
      : `${formatTimeUnit(
          completedSeconds,
          t("time_unit.second"),
          t("time_unit.seconds")
        )}`;

  // ------------------------------------------TOTAL InProgress STEPS TIME-----------------------------------
  // const totalInProgressSteps = steps
  //   .filter((step) => step.step_status === "in_progress")
  //   .reduce((total, step) => {
  //     const timeTaken = step?.time_taken;
  //     const count2 = convertCount2ToSeconds(step?.count2,step?.time_unit)
  //     if (timeTaken) {
  //       return total + parseTimeTaken(timeTaken);
  //     }
  //     return total;
  //   }, 0);
  const totalInProgressSteps = steps
    .filter((step) => step.step_status === "in_progress")
    .reduce((total, step) => {
      const timeTaken = step?.time_taken;
      const count2 = convertCount2ToSeconds(step?.count2, step?.time_unit);

      if (timeTaken) {
        const parsedTimeTaken = parseTimeTaken(timeTaken);

        // Check if the parsed time taken is less than count2
        if (parsedTimeTaken < count2) {
          // Calculate the remaining time
          const remainingTime = count2 - parsedTimeTaken;
          return total + remainingTime; // Add remaining time to total
        }
      }

      return total; // If no time taken, return total as is
    }, 0);

  //   const remainingTimes = steps
  //   .filter((step) => step.step_status === "in_progress")
  //   .map((step) => {
  //     const timeTakenInSeconds = parseTimeTaken(step?.time_taken);
  //     if (timeTakenInSeconds < count2InSeconds) {
  //       const remainingSeconds = count2InSeconds - timeTakenInSeconds;
  //       return formatTime(remainingSeconds);
  //     }
  //     return null; // Exclude steps where time_taken exceeds count2
  //   })
  //   .filter(Boolean); // Remove null values

  // return remainingTimes;
  // };
  const inProgressDays = Math.floor(totalCompletedSteps / (24 * 3600));
  const remainingSecondsAfterDaysInProgress =
    totalInProgressSteps % (24 * 3600);
  const inProgressHours = Math.floor(
    remainingSecondsAfterDaysInProgress / 3600
  );
  const remainingSecondsAfterHoursInProgress =
    remainingSecondsAfterDaysInProgress % 3600;
  const inProgressMinutes = Math.floor(
    remainingSecondsAfterHoursInProgress / 60
  );
  const inProgressSeconds = remainingSecondsAfterHoursInProgress % 60;

  const formattedInProgressTime =
    inProgressDays > 0
      ? `${formatTimeUnit(
          inProgressDays,
          t("time_unit.day"),
          t("time_unit.days")
        )}${
          inProgressHours > 0
            ? ` ${formatTimeUnit(
                inProgressHours,
                t("time_unit.hour"),
                t("time_unit.hours")
              )}`
            : ""
        }`
      : inProgressHours > 0
      ? `${formatTimeUnit(
          inProgressHours,
          t("time_unit.hour"),
          t("time_unit.hours")
        )}${
          inProgressMinutes > 0
            ? ` ${formatTimeUnit(
                inProgressMinutes,
                t("time_unit.minute"),
                t("time_unit.minutes")
              )}`
            : ""
        }`
      : inProgressMinutes > 0
      ? `${formatTimeUnit(
          inProgressMinutes,
          t("time_unit.minute"),
          t("time_unit.minutes")
        )}${
          inProgressSeconds > 0
            ? ` ${formatTimeUnit(
                inProgressSeconds,
                t("time_unit.second"),
                t("time_unit.seconds")
              )}`
            : ""
        }`
      : `${formatTimeUnit(
          inProgressSeconds,
          t("time_unit.second"),
          t("time_unit.seconds")
        )}`;
  // Add both durations to get the total workload in seconds
  const totalWorkloadInSeconds = totalUpcomingSteps + totalInProgressSteps;

  // Convert to days and hours
  const totalDays = Math.floor(totalWorkloadInSeconds / (24 * 3600)); // Whole days
  const TotalRemainingSecondsAfterDays = totalWorkloadInSeconds % (24 * 3600); // Remaining seconds after days
  const totalHours = Math.floor(TotalRemainingSecondsAfterDays / 3600); // Remaining hours
  const totalMinutes = Math.floor((TotalRemainingSecondsAfterDays % 3600) / 60); // Remaining minutes

  // Format the result based on the total workload
  // Format the result based on the total workload
  const formattedWorkload =
    totalDays > 0
      ? `${totalDays} ${t("time_unit.day", { count: totalDays })} ${
          totalHours > 0
            ? `${totalHours} ${
                totalHours === 1 ? t("time_unit.hour") : t("time_unit.hours")
              }`
            : totalMinutes > 0
            ? `${totalMinutes} ${
                totalMinutes === 1
                  ? t("time_unit.minute")
                  : t("time_unit.minutes")
              }`
            : ""
        }`
      : totalHours >= 1
      ? `${totalHours} ${
          totalHours === 1 ? t("time_unit.hour") : t("time_unit.hours")
        }`
      : `${totalMinutes} ${
          totalMinutes === 1 ? t("time_unit.minute") : t("time_unit.minutes")
        }`;

  console.log(`Total Workload: ${formattedWorkload}`);

  console.log("totalUpcomingSteps", totalUpcomingSteps);
  console.log("totalInProgressSteps", totalInProgressSteps);
  console.log("totalWorkloadInSeconds", totalWorkloadInSeconds);

  // -----------------------------------------------------------------------------------------------------------------------------
  const inProgressCount = steps.filter(
    (step) => step.step_status === "in_progress"
  ).length;
  const completedCount = steps.filter(
    (step) => step.step_status === "completed"
  ).length;

  console.log("filtered steps", steps);

  const convertTo24HourFormat = (time, type) => {
    if (!time || !type) {
      return false;
    }
    const timeMoment = moment(time, "hh:mm:ss A");

    if (!timeMoment.isValid()) return "";
    const format = type === "seconds" ? "HH[h]mm[m]ss" : "HH[h]mm";
    return timeMoment.format(format);
  };
  const localizeTimeTakenActive = (timeTaken) => {
    if (!timeTaken) return "";

    const timeUnits = t("time_unit", { returnObjects: true });

    const timeParts = timeTaken.split(" - ");

    let days = null;
    let hours = null;
    let minutes = null;
    let seconds = null;

    timeParts.forEach((part) => {
      if (part.includes("day")) {
        days = part;
      } else if (part.includes("hour")) {
        hours = part;
      } else if (part.includes("min")) {
        minutes = part;
      } else if (part.includes("sec")) {
        seconds = part;
      }
    });

    const hasDays = Boolean(days);
    let result = "";
    if (hasDays) {
      result = [days, hours].filter(Boolean).join(" - ");
    } else if (hours) {
      result = [hours, minutes].filter(Boolean).join(" - ");
    } else if (minutes) {
      result = [minutes, seconds].filter(Boolean).join(" - ");
    } else {
      result = seconds;
    }

    if (!result) return "";
    return result
      .split(" ")
      .map((part) => (isNaN(part) ? timeUnits[part] || part : part))
      .join(" ");
  };
  const localizeTimeTaken = (timeTaken) => {
    if (!timeTaken) return;

    const timeUnits = t("time_unit", { returnObjects: true });

    return timeTaken
      .split(" ")
      .map((part) => {
        if (!isNaN(part)) {
          return part;
        }
        return timeUnits[part] || part;
      })
      .join(" ");
  };
  const changeStatusAndPlay = async (item) => {
    setLoading(true);

    const loggedInUserId = sessionStorage.getItem("user_id");
    // // Check if the item type is "Newsletter"
    // if (item.type !== "Newsletter") {
    //   const isGuide =
    //     item?.steps?.some(
    //       (guide) => guide?.userPID === parseInt(loggedInUserId)
    //     ) || item?.user?.id === parseInt(loggedInUserId);

    //   if (!isGuide) {
    //     setLoading(false);
    //     toast.error(
    //       t("meeting.formState.you are not allowed to play the meeting")
    //     );
    //     return;
    //   }
    // }
    let current_time;
    let current_date;
    let end_date;

    const inProgressStep = item?.steps?.find(
      (step) => step?.step_status === "in_progress"
    );
    for (const step of item?.steps || []) {
      if (step?.step_status === "in_progress" && step?.step_time) {
        current_date = step.current_date || step?.start_date;
        current_time = step.step_time;
        end_date = step?.end_date;
        break;
      }
    }

    if (!current_time || !current_date) {
      setLoading(false);

      return;
    }
    const [time, meridiem] = current_time.split(" ");
    let [currentHours, currentMinutes, currentSeconds] = time
      .split(":")
      .map(Number);
    if (meridiem.toLowerCase() === "pm" && currentHours < 12) {
      currentHours += 12;
    } else if (meridiem.toLowerCase() === "am" && currentHours === 12) {
      currentHours = 0;
    }
    const [day, month, year] = current_date?.split("-").map(Number);
    const myDate = `${day}/${month}/${year}`;
    const currentDateTime = new Date(myDate);

    currentDateTime.setHours(currentHours, currentMinutes, currentSeconds);

    const realCurrentTime = new Date();

    const count2InSeconds = convertCount2ToSeconds(
      inProgressStep?.count2,
      inProgressStep?.time_unit
    );

    const differenceInMilliseconds = realCurrentTime - currentDateTime;
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const adjustedDifferenceInSeconds = differenceInSeconds - count2InSeconds;

    localStorage.setItem("difference", differenceInSeconds);
    if (isNaN(differenceInSeconds)) {
      setLoading(false);

      return;
    }

    const convertSecondsToDDHHMMSS = (seconds) => {
      if (isNaN(seconds)) {
        console.error("seconds is NaN");
        setLoading(false);

        return "NaN";
      }

      const days = Math.floor(seconds / (24 * 3600));
      seconds %= 24 * 3600;
      const hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      const minutes = Math.floor(seconds / 60);
      seconds %= 60;
      return `${String(days).padStart(2, "0")}d:${String(hours).padStart(
        2,
        "0"
      )}h:${String(minutes).padStart(2, "0")}m:${String(seconds).padStart(
        2,
        "0"
      )}s`;
    };

    const delay = convertSecondsToDDHHMMSS(adjustedDifferenceInSeconds);

    const updatedSteps = item?.steps?.map((step) => {
      if (step.step_status === "in_progress") {
        let savedTime = Number(step.savedTime) || 0;

        let actualStepTime = 0;
        if (step.time_unit === "days") {
          actualStepTime = Number(step.count2 * 86400);
        } else if (step.time_unit === "hours") {
          actualStepTime = Number(step.count2 * 3600);
        } else if (step.time_unit === "minutes") {
          actualStepTime = Number(step.count2 * 60);
        } else {
          actualStepTime = Number(step.count2);
        }

        const negativeTime = Number(step.negative_time) || 0;

        let newSavedTime = savedTime - differenceInSeconds;
        let newNegativeTime = negativeTime;

        let newTime = actualStepTime - differenceInSeconds;
        localStorage.setItem("newTime", newTime);
        // Adjust savedTime
        if (newTime >= 0) {
          savedTime = newTime;
          if (newTime < 0) savedTime = 0;
        }
        if (differenceInSeconds <= actualStepTime) {
          step.delay = null;
          step.savedTime = savedTime;
          step.negative_time = newNegativeTime;
        } else {
          step.delay = delay;
          step.savedTime = 0;
          step.negative_time = "99";
        }

        return {
          ...step,
          delay: step.delay,
          savedTime: step.savedTime,
          negative_time: step.negative_time,
        };
      } else {
        return {
          ...step,
        };
      }
    });

    const payload = {
      ...item,
      _method: "put",
      end_time: item.end_time,
      delay: delay,
      steps: updatedSteps,
      moment_privacy_teams:
        item?.moment_privacy === "team" &&
        item?.moment_privacy_teams?.length &&
        typeof item?.moment_privacy_teams[0] === "object"
          ? item?.moment_privacy_teams?.map((team) => team?.id)
          : item?.moment_privacy_teams || [], // Send as-is if IDs are already present
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${item.id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status) {
        navigate(`/action/play/${item?.id}`);
        setLoading(false);

        if (item?.meet_link && item?.location === "Google Meet") {
          openGoogleMeet(item?.meet_link);
        }
      }
    } catch (error) {
      console.log("error while updating meeting status", error);
      setLoading(false);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [stepId, setStepId] = useState(null);
  const [stepIndex, setStepIndex] = useState(null);
  const [isDrop, setIsDrop] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(!isModalOpen);
    toggle(true);
  };
  const [doneMomentsModal, setDoneMomentsModal] = useState(null);

  const toggleModal = async (id) => {
    setDoneMomentsModal((prev) => (prev === id ? null : id));
  };
  const handleClick = (item, index) => {
    if (loading) return;
    setLoading(true);
    console.log("item clicked", item);
    if (item?.step_status === "completed") {
      // navigate(`/step/${item?.id}`, { state: { meeting: item?.meeting } });
      toggleModal(index);
      toggle(true);
      // console.log("complete");
    } else if (item?.step_status === "in_progress") {
      if (item?.meeting) {
        changeStatusAndPlay(item?.meeting);
      }
      toggle(true);
    } else {
      toggle(false);
      setIsModalOpen(!isModalOpen);
      // setIsUpdated(true)
      setStepId(item?.id);
      setSelectedMeeting(item?.meeting);
      setStepIndex(index);
      setIsDrop(false);
    }
    setTimeout(() => setLoading(false), 2000);
  };

  return (
    <>
      <div className="container-fluid invite">
        <div className="mb-4 mt-1 action-tabs">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-3 text-center">
              <button
                className={`tab`}
                style={{
                  borderBottom: "2px solid #0026b1",
                  // color: "#0026b1"
                }}
              >
                {t("badge.future")}{" "}
                <span
                  className="status-badge-upcoming"
                  style={{ padding: "1px 5px" }}
                >
                  {/* {upcomingCount} */}
                  {activeIndex === selectedIndex ? upcomingCount : 0}
                </span>
              </button>
            </div>
            <div className="col-md-3 text-center">
              <button
                className={`tab`}
                style={{
                  borderBottom: "2px solid #0026b1",
                  // color: "#0026b1"
                }}
              >
                {t("badge.inprogress")}{" "}
                <span
                  className="status-badge-inprogress"
                  style={{ padding: "1px 5px" }}
                >
                  {/* {inProgressCount} */}
                  {activeIndex === selectedIndex ? inProgressCount : 0}
                </span>
              </button>
            </div>

            <div className="col-md-3 text-center">
              <button
                className={`tab`}
                style={{
                  borderBottom: "2px solid #0026b1",
                  // color: "#0026b1"
                }}
              >
                {t("badge.completed")}{" "}
                <span
                  className="status-badge-completed"
                  style={{ padding: "1px 5px" }}
                >
                  {/* {completedCount} */}
                  {activeIndex === selectedIndex ? completedCount : 0}
                </span>
              </button>
            </div>
          </div>

          {titles.map((title, index) => (
            <div
              key={index}
              className="accordion-item"
              onClick={() => handleTitleClick(title, index)}
              style={{
                cursor: "pointer",
                border: "none",
                padding: "10px 15px",
                backgroundColor:
                  activeIndex === index ? "transparent" : "transparent",
                color: activeIndex === index ? "rgb(54 155 224)" : "inherit",
                fontWeight: activeIndex === index ? "bold" : "normal",
                // borderRadius: "5px",
                width: "100%",
                borderTop: "1px solid #e6e6e6",
              }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="d-flex align-items-center action-accordion-item-margin"
                  style={{
                    justifyContent: activeIndex === index ? "center" : "start",
                    width: "100%",
                    marginLeft: activeIndex === index ? "19rem" : "",
                  }}
                >
                  <FaArrowRight
                    style={{
                      transition: "transform 0.3s",
                      transform:
                        activeIndex === index
                          ? "rotate(90deg)"
                          : "rotate(0deg)",
                      marginRight: "8px",
                      // marginTop:'10px'
                    }}
                  />
                  <div className="d-flex flex-column">
                    <span>
                      {t(`action.action_unit.${title}`)}
                      &nbsp;
                      <br />
                      {activeIndex === index && (
                        <>
                          <span style={{ color: "black" }}>
                            {t("action.RemainingWorkload")}
                            &nbsp;
                            {formattedWorkload}
                          </span>
                          {/* <h6 style={{ color: "black" }}>
                            {formattedWorkload}
                          </h6> */}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              {/* Collapsible content */}
              {activeIndex === index && (
                <div
                  className="accordion-content"
                  // style={{ padding: "10px 0 0 30px" }}
                >
                  {showProgressBar && (
                    // <div className="loader-container">
                    //   <Spinner
                    //     animation="border"
                    //     role="status"
                    //     className="center-spinner"
                    //   ></Spinner>
                    // </div>
                    <div
                      className="progress-overlay"
                      // style={{ background: "transparent" }}
                    >
                      <div style={{ width: "50%" }}>
                        <ProgressBar now={progress} animated />
                      </div>
                    </div>
                  )}
                  {!showProgressBar && (
                    <div className="row">
                      <div className="col-lg-3 col-md-0"></div>
                      <div className="col-lg-3 col-md-4"
                        style={{ borderRight: "1px solid #e6e6e6" }}
                      >
                        <div className="mt-3">
                          {steps
                            ?.filter((step) => step?.step_status === null)
                            .sort((a, b) => {
                              // Create date objects for start_date
                              const dateA = new Date(a?.start_date);
                              const dateB = new Date(b?.start_date);

                              // Compare by start_date first
                              if (dateA < dateB) return -1;
                              if (dateA > dateB) return 1;

                              // If start_date is the same, compare by step_time
                              const timeA = convertTo24HourFormat(
                                a?.step_time,
                                a?.time_unit
                              ); // Ensure step_time is in a comparable format
                              const timeB = convertTo24HourFormat(
                                b?.step_time,
                                b?.time_unit
                              );

                              return timeA.localeCompare(timeB); // Use localeCompare to handle strings
                            })

                            .map((item, index) => {
                              // Check for item and its properties
                              if (!item || !item?.meeting) {
                                return null; // Skip if item or meeting is undefined
                              }

                              // Safeguard tooltip rendering
                              const renderTooltip = (props) => (
                                <Tooltip id="step-card-tooltip" {...props}>
                                  {item?.title || "No Title"}{" "}
                                  {/* Fallback for title */}
                                </Tooltip>
                              );

                              const renderTitleTooltip = (props) => (
                                <Tooltip id="step-card-tooltip" {...props}>
                                  {item?.meeting?.title || "No Meeting Title"}{" "}
                                  {/* Fallback for meeting title */}
                                </Tooltip>
                              );

                              const renderObjectiveTooltip = (props) => (
                                <Tooltip id="step-card-tooltip" {...props}>
                                  {item?.meeting?.objective || "No Objective"}{" "}
                                  {/* Fallback for objective */}
                                </Tooltip>
                              );
                              const data = item?.meeting;

                              return (
                                <Card
                                  className="mt-4 step-card-meeting"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleClick(item, index);
                                  }}
                                  key={index}
                                >
                                  <Card.Body className="step-card-body">
                                    <div className="step-body">
                                      <div className="step-data">
                                        <h6
                                          className="step-card-heading m-0 p-0"
                                          style={{
                                            color: "#92929d",
                                            fontSize: "13px",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(
                                              `/invitiesToMeeting/${item?.meeting?.destination_id}`
                                            );
                                          }}
                                        >
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={renderObjectiveTooltip}
                                          >
                                            <Card.Title
                                              className="m-0 p-0 fs-6 ms-1 truncated-text"
                                              // style={{ width: "60%" }}
                                            >
                                              {item?.meeting?.objective}
                                            </Card.Title>
                                          </OverlayTrigger>
                                          {/* {item?.meeting?.objective} */}
                                        </h6>
                                        <hr
                                          style={{
                                            marginTop: "9px",
                                            marginBottom: "9px",
                                          }}
                                        />
                                        <h6
                                          className="step-card-heading m-0 p-0 fs-6 d-flex align-items-center justify-content-between"
                                          style={{
                                            color: "#92929d",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          
                                            const path =
                                              item?.meeting?.type === "Special"
                                                ? `/present/invite/${item?.meeting?.id}`
                                                : `/invite/${item?.meeting?.id}`;
                                          
                                            navigate(path, {
                                              state: {
                                                data,
                                                from: "meeting",
                                              },
                                            });
                                          }}
                                        >
                                          <span>
                                            {typeIcons[item?.meeting?.type]}
                                          </span>
                                          {/* <span>{item?.meeting?.title}</span> */}
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={renderTitleTooltip}
                                          >
                                            <Card.Title
                                              className="m-0 p-0 fs-6 ms-1 truncated-text"
                                              // style={{ width: "60%" }}
                                            >
                                              {item?.meeting?.title}
                                            </Card.Title>
                                          </OverlayTrigger>
                                        </h6>
                                        <hr
                                          style={{
                                            marginTop: "9px",
                                            marginBottom: "9px",
                                          }}
                                        />
                                        <div
                                          className="step-header d-flex align-items-center justify-content-between"
                                          style={{
                                            margin: "9px 0px",
                                          }}
                                        >
                                          <div className="step-number-container d-flex align-items-center">
                                            <span
                                              className="step-number"
                                              style={{ color: "#92929d" }}
                                            >
                                              {item?.order_no <= 9 ? "0" : " "}
                                              {item?.order_no}/
                                              {item?.meeting?.steps?.length <= 9
                                                ? "0"
                                                : " "}
                                              {item?.meeting?.steps?.length}
                                            </span>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={renderTooltip}
                                            >
                                              <Card.Title
                                                className="step-card-heading m-0 p-0 fs-6 ms-1 truncated-text action-ellipses-text"
                                                // style={{ width: "60%" }}
                                              >
                                                {item?.title}
                                              </Card.Title>
                                            </OverlayTrigger>
                                          </div>
                                          {!window.location.href.includes(
                                            "/present/invite"
                                          ) &&
                                            (item.step_status ===
                                            "completed" ? (
                                              <span className="status-badge-completed h-auto">
                                                {/* Completed */}
                                                {t("badge.completed")}
                                              </span>
                                            ) : item.step_status ===
                                              "in_progress" ? (
                                              <span
                                                className={
                                                  convertTimeTakenToSeconds(
                                                    item?.time_taken
                                                  ) >
                                                  convertCount2ToSeconds(
                                                    item?.count2,
                                                    item?.time_unit
                                                  )
                                                    ? "status-badge-red h-auto"
                                                    : "status-badge-inprogress h-auto"
                                                }
                                              >
                                                {t("badge.inprogress")}
                                              </span>
                                            ) : item.step_status ===
                                              "paused" ? (
                                              <span
                                                className={
                                                  "status-badge-red h-auto"
                                                }
                                              >
                                                {t("badge.paused")}
                                              </span>
                                            ) : (
                                              // null
                                              // <span className="status-badge-upcoming h-auto">
                                              <span
                                                className={
                                                  moment().isAfter(
                                                    moment(
                                                      `${item?.start_date} ${item?.step_time}`,
                                                      "YYYY-MM-DD hh:mm:ss A"
                                                    )
                                                  )
                                                    ? "status-badge-late"
                                                    : "status-badge-upcoming"
                                                }
                                              >
                                                {/* {t("badge.future")} */}
                                                {moment().isAfter(
                                                  moment(
                                                    `${item?.start_date} ${item?.step_time}`,
                                                    "YYYY-MM-DD hh:mm:ss A"
                                                  )
                                                )
                                                  ? t("badge.late")
                                                  : t("badge.future")}
                                              </span>
                                            ))}
                                        </div>
                                        <div className="step-content">
                                          <Card.Subtitle className="step-card-subtext">
                                            {item.assigned_team ? (
                                              <>
                                                {item?.assigned_team?.logo ? (
                                                  <img
                                                    height="24px"
                                                    width="24px"
                                                    style={{
                                                      marginRight: "9px",
                                                      borderRadius: "20px",
                                                      objectFit: "cover",
                                                      objectPosition: "top",
                                                    }}
                                                    src={
                                                      Assets_URL +
                                                      "/" +
                                                      item?.assigned_team?.logo
                                                    }
                                                    alt={
                                                      item?.assigned_team?.name
                                                    }
                                                  />
                                                ) : (
                                                  <HiUserCircle
                                                    style={{
                                                      height: "24px",
                                                      width: "24px",
                                                    }}
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {item?.image ? (
                                                  <img
                                                    height="24px"
                                                    width="24px"
                                                    style={{
                                                      marginRight: "9px",
                                                      borderRadius: "20px",
                                                      objectFit: "cover",
                                                      objectPosition: "top",
                                                    }}
                                                    src={
                                                      item?.image?.startsWith(
                                                        "users/"
                                                      )
                                                        ? Assets_URL +
                                                          "/" +
                                                          item?.image
                                                        : // : item?.image?.startsWith("users/")
                                                          // ? Assets_URL + "/" + item.image
                                                          item?.image
                                                    }
                                                    // src={
                                                    //     item?.assigned_to_image
                                                    // }
                                                    alt="img"
                                                  />
                                                ) : (
                                                  <img
                                                    height="24px"
                                                    width="24px"
                                                    style={{
                                                      marginRight: "9px",
                                                      borderRadius: "20px",
                                                      objectFit: "cover",
                                                      objectPosition: "top",
                                                    }}
                                                    // src={`${users?.participant_image}`}
                                                    // src={
                                                    //   // users?.image
                                                    //   //   ? Assets_URL + "/" + users.image
                                                    //     // :
                                                    //      users?.participant_image
                                                    // }
                                                    src={
                                                      item?.assigned_to_image?.startsWith(
                                                        "users/"
                                                      )
                                                        ? Assets_URL +
                                                          "/" +
                                                          item?.assigned_to_image
                                                        : item?.assigned_to_image
                                                    }
                                                    alt="img"
                                                  />
                                                )}
                                              </>
                                            )}

                                            {item.assigned_team ? (
                                              <span>
                                                {item?.assigned_team?.name}
                                              </span>
                                            ) : (
                                              <span>
                                                {item?.assigned_to_name}
                                              </span>
                                            )}
                                          </Card.Subtitle>
                                          <Card.Text className="step-card-content action-step-card-content d-flex align-items-center">
                                            <img
                                              height="16px"
                                              width="16px"
                                              src="/Assets/ion_time-outline.svg"
                                            />
                                            {window.location.href.includes(
                                              "/present/invite"
                                            ) ? (
                                              <>
                                                <span className="me-2">
                                                  {item?.step_time}
                                                </span>
                                              </>
                                            ) : (
                                              <span
                                                className={`${
                                                  item?.time_unit === "days"
                                                    ? "me-2"
                                                    : "me-2"
                                                }`}
                                              >
                                                {item?.time_unit === "days" ? (
                                                  <>
                                                    {
                                                      item.step_status === null
                                                        ? formatStepDate(
                                                            item?.start_date
                                                          )
                                                        : // +
                                                          // " " +
                                                          // ` ${t("at")}` +
                                                          // " " +
                                                          // formatStepDate(item?.total_step_time)
                                                          formatStepDate(
                                                            item?.start_date
                                                          )
                                                      //  +
                                                      // " " +
                                                      // ` ${t("at")}` +
                                                      // " " +
                                                      // item.start_date?.split(
                                                      //   "T"
                                                      // )[0]
                                                    }
                                                  </>
                                                ) : (
                                                  <>
                                                    {item?.step_status === null
                                                      ? formatStepDate(
                                                          item?.start_date
                                                        ) +
                                                        " " +
                                                        ` ${t("at")}` +
                                                        " " +
                                                        convertTo24HourFormat(
                                                          item?.step_time,
                                                          item?.time_unit
                                                        )
                                                      : formatStepDate(
                                                          item?.start_date
                                                        ) +
                                                        " " +
                                                        ` ${t("at")}` +
                                                        " " +
                                                        convertTo24HourFormat(
                                                          item?.step_time,
                                                          item?.time_unit
                                                        )}
                                                  </>
                                                )}
                                              </span>
                                            )}{" "}
                                          </Card.Text>
                                          <Card.Text className="step-card-content action-step-card-content d-flex align-items-center">
                                            <span className="">
                                              <img
                                                height="16px"
                                                width="16px"
                                                src="/Assets/alarm-invite.svg"
                                              />
                                            </span>
                                            {window.location.href.includes(
                                              "/present/invite"
                                            ) ? (
                                              <span>
                                                {localizeTimeTaken(
                                                  item?.time_taken?.replace(
                                                    "-",
                                                    ""
                                                  )
                                                )}
                                              </span>
                                            ) : (
                                              <>
                                                {item?.step_status === null
                                                  ? item.count2 +
                                                    " " +
                                                    t(
                                                      `time_unit.${item.time_unit}`
                                                    )
                                                  : localizeTimeTakenActive(
                                                      item?.time_taken?.replace(
                                                        "-",
                                                        ""
                                                      )
                                                    )}
                                                {item?.step_status !== null && (
                                                  <span>
                                                    &nbsp; /{" "}
                                                    {item.count2 +
                                                      " " +
                                                      t(
                                                        `time_unit.${item.time_unit}`
                                                      )}
                                                  </span>
                                                )}
                                              </>
                                            )}{" "}
                                          </Card.Text>
                                          <div className="mb-2">
                                            <div className="creator">
                                              {t("Privacy")}
                                            </div>
                                            <div className="">
                                              <div className="d-flex align-items-center flex-wrap">
                                                {item?.meeting
                                                  ?.moment_privacy ===
                                                "public" ? (
                                                  <Avatar
                                                    src="/Assets/Tek.png"
                                                    style={{
                                                      borderRadius: "0",
                                                    }}
                                                  />
                                                ) : item?.meeting
                                                    ?.moment_privacy ===
                                                  "team" ? (
                                                  <Avatar.Group>
                                                    {item?.meeting?.moment_privacy_teams_data?.map(
                                                      (item) => {
                                                        return (
                                                          <>
                                                            {/* <Tooltip
                                                              title={item?.name}
                                                              placement="top"
                                                            > */}
                                                            <Avatar
                                                              size="large"
                                                              // src={
                                                              //   item?.logo?.startsWith("teams/")
                                                              //     ? Assets_URL + "/" + item.logo
                                                              //     : item.logo
                                                              // }
                                                              src={
                                                                item?.logo?.startsWith(
                                                                  "http"
                                                                )
                                                                  ? item.logo
                                                                  : Assets_URL +
                                                                    "/" +
                                                                    item.logo
                                                              }
                                                              style={{
                                                                objectFit:
                                                                  "cover",
                                                                objectPosition:
                                                                  "top",
                                                              }}
                                                            />
                                                            {/* </Tooltip> */}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </Avatar.Group>
                                                ) : item?.meeting
                                                    ?.moment_privacy ===
                                                  "enterprise" ? (
                                                  <img
                                                    src={
                                                      item?.meeting?.user?.enterprise?.logo?.startsWith(
                                                        "enterprises/"
                                                      )
                                                        ? Assets_URL +
                                                          "/" +
                                                          item?.meeting?.user
                                                            ?.enterprise?.logo
                                                        : item?.meeting?.user?.enterprise?.logo?.startsWith(
                                                            "storage/enterprises/"
                                                          )
                                                        ? Assets_URL +
                                                          "/" +
                                                          item?.meeting?.user
                                                            ?.enterprise?.logo
                                                        : item?.meeting?.user
                                                            ?.enterprise?.logo
                                                    }
                                                    alt="Logo"
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                      objectFit: "fill",
                                                      borderRadius: "50%",
                                                    }}
                                                  />
                                                ) : item?.meeting
                                                    ?.moment_privacy ===
                                                  "password" ? (
                                                  <svg
                                                    width="37px"
                                                    height="36px"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <g
                                                      id="SVGRepo_bgCarrier"
                                                      stroke-width="0"
                                                    ></g>
                                                    <g
                                                      id="SVGRepo_tracerCarrier"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    ></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                      {" "}
                                                      <path
                                                        d="M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
                                                        stroke="#000000"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      ></path>{" "}
                                                    </g>
                                                  </svg>
                                                ) : (
                                                  <Avatar
                                                    src={
                                                      item?.meeting?.user?.image.startsWith(
                                                        "users/"
                                                      )
                                                        ? Assets_URL +
                                                          "/" +
                                                          item?.meeting?.user
                                                            ?.image
                                                        : item?.meeting?.user
                                                            ?.image
                                                    }
                                                    style={{
                                                      objectFit: "cover",
                                                      objectPosition: "top",
                                                      height: "24px",
                                                      width: "24px",
                                                    }}
                                                  />
                                                )}

                                                <span
                                                  className={`badge ms-2 ${
                                                    item?.meeting
                                                      ?.moment_privacy ===
                                                    "private"
                                                      ? "solution-badge-red"
                                                      : item?.meeting
                                                          ?.moment_privacy ===
                                                        "public"
                                                      ? "solution-badge-green"
                                                      : item?.meeting
                                                          ?.moment_privacy ===
                                                        "enterprise"
                                                      ? "solution-badge-blue"
                                                      : item?.meeting
                                                          ?.moment_privacy ===
                                                        "password"
                                                      ? "solution-badge-red"
                                                      : "solution-badge-yellow"
                                                  }`}
                                                  style={{
                                                    padding: "3px 8px 3px 8px",
                                                  }}
                                                >
                                                  {item?.meeting
                                                    ?.moment_privacy ===
                                                  "private"
                                                    ? t(
                                                        "solution.badge.private"
                                                      )
                                                    : item?.meeting
                                                        ?.moment_privacy ===
                                                      "public"
                                                    ? t("solution.badge.public")
                                                    : item?.meeting
                                                        ?.moment_privacy ===
                                                      "enterprise"
                                                    ? t(
                                                        "solution.badge.enterprise"
                                                      )
                                                    : item?.meeting
                                                        ?.moment_privacy ===
                                                      "password"
                                                    ? t(
                                                        "solution.badge.password"
                                                      )
                                                    : t("solution.badge.team")}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Card>
                              );
                            })}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4"
                        style={{ borderRight: "1px solid #e6e6e6" }}
                      >
                        <div className="mt-3">
                          {steps
                            ?.filter(
                              (step) => step?.step_status === "in_progress"
                            )
                            .sort((a, b) => {
                              const dateA = new Date(a?.start_date);
                              const dateB = new Date(b?.start_date);
                              if (dateA < dateB) return -1;
                              if (dateA > dateB) return 1;
                              const timeA = convertTo24HourFormat(
                                a?.step_time,
                                a?.time_unit
                              );
                              const timeB = convertTo24HourFormat(
                                b?.step_time,
                                b?.time_unit
                              );

                              return timeA.localeCompare(timeB);
                            })
                            .map((item, index) => {
                              // Check for item and its properties
                              if (!item || !item?.meeting) {
                                return null; // Skip if item or meeting is undefined
                              }

                              // Safeguard tooltip rendering
                              const renderTooltip = (props) => (
                                <Tooltip id="step-card-tooltip" {...props}>
                                  {item?.title || "No Title"}{" "}
                                  {/* Fallback for title */}
                                </Tooltip>
                              );

                              const renderTitleTooltip = (props) => (
                                <Tooltip id="step-card-tooltip" {...props}>
                                  {item?.meeting?.title || "No Meeting Title"}{" "}
                                  {/* Fallback for meeting title */}
                                </Tooltip>
                              );

                              const renderObjectiveTooltip = (props) => (
                                <Tooltip id="step-card-tooltip" {...props}>
                                  {item?.meeting?.objective || "No Objective"}{" "}
                                  {/* Fallback for objective */}
                                </Tooltip>
                              );
                              return (
                                <Card
                                  className="mt-4 step-card-meeting"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleClick(item, index);
                                  }}
                                  key={index}
                                >
                                  <Card.Body className="step-card-body">
                                    <div className="step-body">
                                      <div className="step-data">
                                        <h6
                                          className="step-card-heading m-0 p-0"
                                          style={{
                                            color: "#92929d",
                                            fontSize: "13px",
                                          }}
                                        >
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={renderObjectiveTooltip}
                                          >
                                            <Card.Title
                                              className="m-0 p-0 fs-6 ms-1 truncated-text"
                                              // style={{ width: "60%" }}
                                            >
                                              {item?.meeting?.objective}
                                            </Card.Title>
                                          </OverlayTrigger>
                                        </h6>
                                        <hr
                                          style={{
                                            marginTop: "9px",
                                            marginBottom: "9px",
                                          }}
                                        />
                                        <h6
                                          className="step-card-heading m-0 p-0 fs-6 d-flex align-items-center justify-content-between"
                                          style={{
                                            color: "#92929d",
                                          }}
                                        >
                                          <span>
                                            {typeIcons[item?.meeting?.type]}
                                          </span>
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={renderTitleTooltip}
                                          >
                                            <Card.Title
                                              className="m-0 p-0 fs-6 ms-1 truncated-text"
                                              // style={{ width: "60%" }}
                                            >
                                              {item?.meeting?.title}
                                            </Card.Title>
                                          </OverlayTrigger>
                                        </h6>
                                        <hr
                                          style={{
                                            marginTop: "9px",
                                            marginBottom: "9px",
                                          }}
                                        />
                                        <div
                                          className="step-header d-flex align-items-center justify-content-between"
                                          style={{
                                            margin: "9px 0px",
                                          }}
                                        >
                                          <div className="step-number-container d-flex align-items-center">
                                            <span
                                              className="step-number"
                                              style={{ color: "#92929d" }}
                                            >
                                              {item?.order_no <= 9 ? "0" : " "}
                                              {item?.order_no}/
                                              {item?.meeting?.steps?.length <= 9
                                                ? "0"
                                                : " "}
                                              {item?.meeting?.steps?.length}
                                            </span>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={renderTooltip}
                                            >
                                              <Card.Title
                                                className="step-card-heading m-0 p-0 fs-6 ms-1 truncated-text action-ellipses-text"
                                                // style={{ width: "60%" }}
                                              >
                                                {item?.title}
                                              </Card.Title>
                                            </OverlayTrigger>
                                          </div>
                                          {!window.location.href.includes(
                                            "/present/invite"
                                          ) &&
                                            // meeting.status === "in_progress" &&
                                            (item.step_status ===
                                            "completed" ? (
                                              <span className="status-badge-completed h-auto">
                                                {/* Completed */}
                                                {t("badge.completed")}
                                              </span>
                                            ) : item.step_status ===
                                              "in_progress" ? (
                                              <span
                                                className={
                                                  convertTimeTakenToSeconds(
                                                    item?.time_taken
                                                  ) >
                                                  convertCount2ToSeconds(
                                                    item?.count2,
                                                    item?.time_unit
                                                  )
                                                    ? "status-badge-red h-auto"
                                                    : "status-badge-inprogress h-auto"
                                                }
                                              >
                                                {t("badge.inprogress")}
                                              </span>
                                            ) : item.step_status ===
                                              "paused" ? (
                                              <span
                                                className={
                                                  "status-badge-red h-auto"
                                                }
                                              >
                                                {t("badge.paused")}
                                              </span>
                                            ) : (
                                              // null
                                              <span className="status-badge-upcoming h-auto">
                                                {t("badge.future")}
                                                {/* Upcoming */}
                                              </span>
                                            ))}
                                        </div>
                                        <div className="step-content">
                                          <Card.Subtitle className="step-card-subtext">
                                            {item.assigned_team ? (
                                              <>
                                                {item?.assigned_team?.logo ? (
                                                  <img
                                                    height="24px"
                                                    width="24px"
                                                    style={{
                                                      marginRight: "9px",
                                                      borderRadius: "20px",
                                                      objectFit: "cover",
                                                      objectPosition: "top",
                                                    }}
                                                    src={
                                                      Assets_URL +
                                                      "/" +
                                                      item?.assigned_team?.logo
                                                    }
                                                    alt={
                                                      item?.assigned_team?.name
                                                    }
                                                  />
                                                ) : (
                                                  <HiUserCircle
                                                    style={{
                                                      height: "24px",
                                                      width: "24px",
                                                    }}
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {item?.image ? (
                                                  <img
                                                    height="24px"
                                                    width="24px"
                                                    style={{
                                                      marginRight: "9px",
                                                      borderRadius: "20px",
                                                      objectFit: "cover",
                                                      objectPosition: "top",
                                                    }}
                                                    src={
                                                      item?.image?.startsWith(
                                                        "users/"
                                                      )
                                                        ? Assets_URL +
                                                          "/" +
                                                          item?.image
                                                        : // : item?.image?.startsWith("users/")
                                                          // ? Assets_URL + "/" + item.image
                                                          item?.image
                                                    }
                                                    // src={
                                                    //     item?.assigned_to_image
                                                    // }
                                                    alt="img"
                                                  />
                                                ) : (
                                                  <img
                                                    height="24px"
                                                    width="24px"
                                                    style={{
                                                      marginRight: "9px",
                                                      borderRadius: "20px",
                                                      objectFit: "cover",
                                                      objectPosition: "top",
                                                    }}
                                                    // src={`${users?.participant_image}`}
                                                    // src={
                                                    //   // users?.image
                                                    //   //   ? Assets_URL + "/" + users.image
                                                    //     // :
                                                    //      users?.participant_image
                                                    // }
                                                    src={
                                                      item?.assigned_to_image?.startsWith(
                                                        "users/"
                                                      )
                                                        ? Assets_URL +
                                                          "/" +
                                                          item?.assigned_to_image
                                                        : item?.assigned_to_image
                                                    }
                                                    alt="img"
                                                  />
                                                )}
                                              </>
                                            )}

                                            {item.assigned_team ? (
                                              <span>
                                                {item?.assigned_team?.name}
                                              </span>
                                            ) : (
                                              <span>
                                                {item?.assigned_to_name}
                                              </span>
                                            )}
                                          </Card.Subtitle>
                                          <Card.Text className="step-card-content action-step-card-content d-flex align-items-center">
                                            <img
                                              height="16px"
                                              width="16px"
                                              src="/Assets/ion_time-outline.svg"
                                            />
                                            {window.location.href.includes(
                                              "/present/invite"
                                            ) ? (
                                              <>
                                                <span className="me-2">
                                                  {item?.step_time}
                                                </span>
                                              </>
                                            ) : (
                                              <span
                                                className={`${
                                                  item?.time_unit === "days"
                                                    ? "me-2"
                                                    : "me-2"
                                                }`}
                                              >
                                                {item?.time_unit === "days" ? (
                                                  <>
                                                    {
                                                      item.step_status === null
                                                        ? formatStepDate(
                                                            item?.start_date
                                                          )
                                                        : // +
                                                          // " " +
                                                          // ` ${t("at")}` +
                                                          // " " +
                                                          // formatStepDate(item?.total_step_time)
                                                          formatStepDate(
                                                            item?.start_date
                                                          )
                                                      //  +
                                                      // " " +
                                                      // ` ${t("at")}` +
                                                      // " " +
                                                      // item.start_date?.split(
                                                      //   "T"
                                                      // )[0]
                                                    }
                                                  </>
                                                ) : (
                                                  <>
                                                    {item?.step_status === null
                                                      ? formatStepDate(
                                                          item?.start_date
                                                        ) +
                                                        " " +
                                                        ` ${t("at")}` +
                                                        " " +
                                                        convertTo24HourFormat(
                                                          item?.step_time,
                                                          item?.time_unit
                                                        )
                                                      : formatStepDate(
                                                          item?.start_date
                                                        ) +
                                                        " " +
                                                        ` ${t("at")}` +
                                                        " " +
                                                        convertTo24HourFormat(
                                                          item?.step_time,
                                                          item?.time_unit
                                                        )}
                                                  </>
                                                )}
                                              </span>
                                            )}{" "}
                                          </Card.Text>
                                          <Card.Text className="step-card-content action-step-card-content d-flex align-items-center">
                                            <span className="">
                                              <img
                                                height="16px"
                                                width="16px"
                                                src="/Assets/alarm-invite.svg"
                                              />
                                            </span>
                                            {window.location.href.includes(
                                              "/present/invite"
                                            ) ? (
                                              <span>
                                                {localizeTimeTaken(
                                                  item?.time_taken?.replace(
                                                    "-",
                                                    ""
                                                  )
                                                )}
                                              </span>
                                            ) : (
                                              <>
                                                {item?.step_status === null
                                                  ? item.count2 +
                                                    " " +
                                                    t(
                                                      `time_unit.${item.time_unit}`
                                                    )
                                                  : localizeTimeTakenActive(
                                                      item?.time_taken?.replace(
                                                        "-",
                                                        ""
                                                      )
                                                    )}
                                                {item?.step_status !== null && (
                                                  <span>
                                                    &nbsp; /{" "}
                                                    {item.count2 +
                                                      " " +
                                                      t(
                                                        `time_unit.${item.time_unit}`
                                                      )}
                                                  </span>
                                                )}
                                              </>
                                            )}{" "}
                                          </Card.Text>
                                          <div className="mb-2">
                                            <div className="creator">
                                              {t("Privacy")}
                                            </div>
                                            <div className="">
                                              <div className="d-flex align-items-center flex-wrap">
                                                {item?.meeting
                                                  ?.moment_privacy ===
                                                "public" ? (
                                                  <Avatar
                                                    src="/Assets/Tek.png"
                                                    style={{
                                                      borderRadius: "0",
                                                    }}
                                                  />
                                                ) : item?.meeting
                                                    ?.moment_privacy ===
                                                  "team" ? (
                                                  <Avatar.Group>
                                                    {item?.meeting?.moment_privacy_teams_data?.map(
                                                      (item) => {
                                                        return (
                                                          <>
                                                            {/* <Tooltip
                                                              title={item?.name}
                                                              placement="top"
                                                            > */}
                                                            <Avatar
                                                              size="large"
                                                              // src={
                                                              //   item?.logo?.startsWith("teams/")
                                                              //     ? Assets_URL + "/" + item.logo
                                                              //     : item.logo
                                                              // }
                                                              src={
                                                                item?.logo?.startsWith(
                                                                  "http"
                                                                )
                                                                  ? item.logo
                                                                  : Assets_URL +
                                                                    "/" +
                                                                    item.logo
                                                              }
                                                              style={{
                                                                objectFit:
                                                                  "cover",
                                                                objectPosition:
                                                                  "top",
                                                              }}
                                                            />
                                                            {/* </Tooltip> */}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </Avatar.Group>
                                                ) : item?.meeting
                                                    ?.moment_privacy ===
                                                  "enterprise" ? (
                                                  <img
                                                    src={
                                                      item?.meeting?.user?.enterprise?.logo?.startsWith(
                                                        "enterprises/"
                                                      )
                                                        ? Assets_URL +
                                                          "/" +
                                                          item?.meeting?.user
                                                            ?.enterprise?.logo
                                                        : item?.meeting?.user?.enterprise?.logo?.startsWith(
                                                            "storage/enterprises/"
                                                          )
                                                        ? Assets_URL +
                                                          "/" +
                                                          item?.meeting?.user
                                                            ?.enterprise?.logo
                                                        : item?.meeting?.user
                                                            ?.enterprise?.logo
                                                    }
                                                    alt="Logo"
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                      objectFit: "fill",
                                                      borderRadius: "50%",
                                                    }}
                                                  />
                                                ) : item?.meeting
                                                    ?.moment_privacy ===
                                                  "password" ? (
                                                  <svg
                                                    width="37px"
                                                    height="36px"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <g
                                                      id="SVGRepo_bgCarrier"
                                                      stroke-width="0"
                                                    ></g>
                                                    <g
                                                      id="SVGRepo_tracerCarrier"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    ></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                      {" "}
                                                      <path
                                                        d="M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
                                                        stroke="#000000"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      ></path>{" "}
                                                    </g>
                                                  </svg>
                                                ) : (
                                                  <Avatar
                                                    src={
                                                      item?.meeting?.user?.image.startsWith(
                                                        "users/"
                                                      )
                                                        ? Assets_URL +
                                                          "/" +
                                                          item?.meeting?.user
                                                            ?.image
                                                        : item?.meeting?.user
                                                            ?.image
                                                    }
                                                    style={{
                                                      objectFit: "cover",
                                                      objectPosition:
                                                        "center top",
                                                      height: "24px",
                                                      width: "24px",
                                                    }}
                                                  />
                                                )}

                                                <span
                                                  className={`badge ms-2 ${
                                                    item?.meeting
                                                      ?.moment_privacy ===
                                                    "private"
                                                      ? "solution-badge-red"
                                                      : item?.meeting
                                                          ?.moment_privacy ===
                                                        "public"
                                                      ? "solution-badge-green"
                                                      : item?.meeting
                                                          ?.moment_privacy ===
                                                        "enterprise"
                                                      ? "solution-badge-blue"
                                                      : item?.meeting
                                                          ?.moment_privacy ===
                                                        "password"
                                                      ? "solution-badge-red"
                                                      : "solution-badge-yellow"
                                                  }`}
                                                  style={{
                                                    padding: "3px 8px 3px 8px",
                                                  }}
                                                >
                                                  {item?.meeting
                                                    ?.moment_privacy ===
                                                  "private"
                                                    ? t(
                                                        "solution.badge.private"
                                                      )
                                                    : item?.meeting
                                                        ?.moment_privacy ===
                                                      "public"
                                                    ? t("solution.badge.public")
                                                    : item?.meeting
                                                        ?.moment_privacy ===
                                                      "enterprise"
                                                    ? t(
                                                        "solution.badge.enterprise"
                                                      )
                                                    : item?.meeting
                                                        ?.moment_privacy ===
                                                      "password"
                                                    ? t(
                                                        "solution.badge.password"
                                                      )
                                                    : t("solution.badge.team")}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Card>
                              );
                            })}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4"
                        style={{ borderRight: "1px solid #e6e6e6" }}
                      >
                        <div className="mt-3">
                          {steps
                            ?.filter(
                              (step) => step?.step_status === "completed"
                            )
                            .sort((a, b) => {
                              // Create date objects for start_date
                              const dateA = new Date(a?.start_date);
                              const dateB = new Date(b?.start_date);

                              // Compare by start_date first in descending order
                              if (dateA > dateB) return -1; // Reverse the order
                              if (dateA < dateB) return 1;

                              // If start_date is the same, compare by step_time
                              const timeA = convertTo24HourFormat(
                                a?.step_time,
                                a?.time_unit
                              ); // Ensure step_time is in a comparable format
                              const timeB = convertTo24HourFormat(
                                b?.step_time,
                                b?.time_unit
                              );

                              return timeB.localeCompare(timeA); // Use localeCompare in reverse order
                            })
                            .map((item, index) => {
                              // Check for item and its properties
                              if (!item || !item?.meeting) {
                                return null; // Skip if item or meeting is undefined
                              }

                              // Safeguard tooltip rendering
                              const renderTooltip = (props) => (
                                <Tooltip id="step-card-tooltip" {...props}>
                                  {item?.title || "No Title"}{" "}
                                  {/* Fallback for title */}
                                </Tooltip>
                              );

                              const renderTitleTooltip = (props) => (
                                <Tooltip id="step-card-tooltip" {...props}>
                                  {item?.meeting?.title || "No Meeting Title"}{" "}
                                  {/* Fallback for meeting title */}
                                </Tooltip>
                              );

                              const renderObjectiveTooltip = (props) => (
                                <Tooltip id="step-card-tooltip" {...props}>
                                  {item?.meeting?.objective || "No Objective"}{" "}
                                  {/* Fallback for objective */}
                                </Tooltip>
                              );
                              return (
                                <>
                                  <Card
                                    className="mt-4 step-card-meeting"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClick(item, index);
                                    }}
                                    key={index}
                                  >
                                    <Card.Body className="step-card-body">
                                      <div className="step-body">
                                        <div className="step-data">
                                          <h6
                                            className="step-card-heading m-0 p-0"
                                            style={{
                                              color: "#92929d",
                                              fontSize: "13px",
                                            }}
                                          >
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={renderObjectiveTooltip}
                                            >
                                              <Card.Title
                                                className="m-0 p-0 fs-6 ms-1 truncated-text"
                                                // style={{ width: "60%" }}
                                              >
                                                {item?.meeting?.objective}
                                              </Card.Title>
                                            </OverlayTrigger>
                                          </h6>
                                          <hr
                                            style={{
                                              marginTop: "9px",
                                              marginBottom: "9px",
                                            }}
                                          />
                                          <h6
                                            className="step-card-heading m-0 p-0 fs-6 d-flex align-items-center justify-content-between"
                                            style={{
                                              color: "#92929d",
                                            }}
                                          >
                                            <span>
                                              {typeIcons[item?.meeting?.type]}
                                            </span>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={renderTitleTooltip}
                                            >
                                              <Card.Title
                                                className="m-0 p-0 fs-6 ms-1 truncated-text"
                                                // style={{ width: "60%" }}
                                              >
                                                {item?.meeting?.title}
                                              </Card.Title>
                                            </OverlayTrigger>
                                          </h6>
                                          <hr
                                            style={{
                                              marginTop: "9px",
                                              marginBottom: "9px",
                                            }}
                                          />
                                          <div
                                            className="step-header d-flex align-items-center justify-content-between"
                                            style={{
                                              margin: "9px 0px",
                                            }}
                                          >
                                            <div className="step-number-container d-flex align-items-center">
                                              <span
                                                className="step-number"
                                                style={{ color: "#92929d" }}
                                              >
                                                {item?.order_no <= 9
                                                  ? "0"
                                                  : " "}
                                                {item?.order_no}/
                                                {item?.meeting?.steps?.length <=
                                                9
                                                  ? "0"
                                                  : " "}
                                                {item?.meeting?.steps?.length}
                                              </span>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={renderTooltip}
                                              >
                                                <Card.Title
                                                  className="step-card-heading m-0 p-0 fs-6 ms-1 truncated-text action-ellipses-text"
                                                  // style={{ width: "60%" }}
                                                >
                                                  {item?.title}
                                                </Card.Title>
                                              </OverlayTrigger>
                                            </div>
                                            {!window.location.href.includes(
                                              "/present/invite"
                                            ) &&
                                              // meeting.status === "in_progress" &&
                                              (item.step_status ===
                                              "completed" ? (
                                                <span className="status-badge-completed h-auto">
                                                  {/* Completed */}
                                                  {t("badge.completed")}
                                                </span>
                                              ) : item.step_status ===
                                                "in_progress" ? (
                                                <span
                                                  className={
                                                    convertTimeTakenToSeconds(
                                                      item?.time_taken
                                                    ) >
                                                    convertCount2ToSeconds(
                                                      item?.count2,
                                                      item?.time_unit
                                                    )
                                                      ? "status-badge-red h-auto"
                                                      : "status-badge-inprogress h-auto"
                                                  }
                                                >
                                                  {t("badge.inprogress")}
                                                </span>
                                              ) : item.step_status ===
                                                "paused" ? (
                                                <span
                                                  className={
                                                    "status-badge-red h-auto"
                                                  }
                                                >
                                                  {t("badge.paused")}
                                                </span>
                                              ) : (
                                                // null
                                                <span className="status-badge-upcoming h-auto">
                                                  {t("badge.future")}
                                                  {/* Upcoming */}
                                                </span>
                                              ))}
                                          </div>
                                          <div className="step-content">
                                            <Card.Subtitle className="step-card-subtext">
                                              {item.assigned_team ? (
                                                <>
                                                  {item?.assigned_team?.logo ? (
                                                    <img
                                                      height="24px"
                                                      width="24px"
                                                      style={{
                                                        marginRight: "9px",
                                                        borderRadius: "20px",
                                                        objectFit: "cover",
                                                        objectPosition: "top",
                                                      }}
                                                      src={
                                                        Assets_URL +
                                                        "/" +
                                                        item?.assigned_team
                                                          ?.logo
                                                      }
                                                      alt={
                                                        item?.assigned_team
                                                          ?.name
                                                      }
                                                    />
                                                  ) : (
                                                    <HiUserCircle
                                                      style={{
                                                        height: "24px",
                                                        width: "24px",
                                                      }}
                                                    />
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {item?.image ? (
                                                    <img
                                                      height="24px"
                                                      width="24px"
                                                      style={{
                                                        marginRight: "9px",
                                                        borderRadius: "20px",
                                                        objectFit: "cover",
                                                        objectPosition: "top",
                                                      }}
                                                      src={
                                                        item?.image?.startsWith(
                                                          "users/"
                                                        )
                                                          ? Assets_URL +
                                                            "/" +
                                                            item?.image
                                                          : // : item?.image?.startsWith("users/")
                                                            // ? Assets_URL + "/" + item.image
                                                            item?.image
                                                      }
                                                      // src={
                                                      //     item?.assigned_to_image
                                                      // }
                                                      alt="img"
                                                    />
                                                  ) : (
                                                    <img
                                                      height="24px"
                                                      width="24px"
                                                      style={{
                                                        marginRight: "9px",
                                                        borderRadius: "20px",
                                                        objectFit: "cover",
                                                        objectPosition: "top",
                                                      }}
                                                      // src={`${users?.participant_image}`}
                                                      // src={
                                                      //   // users?.image
                                                      //   //   ? Assets_URL + "/" + users.image
                                                      //     // :
                                                      //      users?.participant_image
                                                      // }
                                                      src={
                                                        item?.assigned_to_image?.startsWith(
                                                          "users/"
                                                        )
                                                          ? Assets_URL +
                                                            "/" +
                                                            item?.assigned_to_image
                                                          : item?.assigned_to_image
                                                      }
                                                      alt="img"
                                                    />
                                                  )}
                                                </>
                                              )}

                                              {item.assigned_team ? (
                                                <span>
                                                  {item?.assigned_team?.name}
                                                </span>
                                              ) : (
                                                <span>
                                                  {item?.assigned_to_name}
                                                </span>
                                              )}
                                            </Card.Subtitle>
                                            <Card.Text className="step-card-content action-step-card-content d-flex align-items-center">
                                              <img
                                                height="16px"
                                                width="16px"
                                                src="/Assets/ion_time-outline.svg"
                                              />
                                              {window.location.href.includes(
                                                "/present/invite"
                                              ) ? (
                                                <>
                                                  <span className="me-2">
                                                    {item?.step_time}
                                                  </span>
                                                </>
                                              ) : (
                                                <span
                                                  className={`${
                                                    item?.time_unit === "days"
                                                      ? "me-2"
                                                      : "me-2"
                                                  }`}
                                                >
                                                  {item?.time_unit ===
                                                  "days" ? (
                                                    <>
                                                      {
                                                        item.step_status ===
                                                        null
                                                          ? formatStepDate(
                                                              item?.start_date
                                                            )
                                                          : // +
                                                            // " " +
                                                            // ` ${t("at")}` +
                                                            // " " +
                                                            // formatStepDate(item?.total_step_time)
                                                            formatStepDate(
                                                              item?.start_date
                                                            )
                                                        //  +
                                                        // " " +
                                                        // ` ${t("at")}` +
                                                        // " " +
                                                        // item.start_date?.split(
                                                        //   "T"
                                                        // )[0]
                                                      }
                                                    </>
                                                  ) : (
                                                    <>
                                                      {item?.step_status ===
                                                      null
                                                        ? formatStepDate(
                                                            item?.start_date
                                                          ) +
                                                          " " +
                                                          ` ${t("at")}` +
                                                          " " +
                                                          convertTo24HourFormat(
                                                            item?.step_time,
                                                            item?.time_unit
                                                          )
                                                        : formatStepDate(
                                                            item?.start_date
                                                          ) +
                                                          " " +
                                                          ` ${t("at")}` +
                                                          " " +
                                                          convertTo24HourFormat(
                                                            item?.step_time,
                                                            item?.time_unit
                                                          )}
                                                    </>
                                                  )}
                                                </span>
                                              )}{" "}
                                            </Card.Text>
                                            <Card.Text className="step-card-content action-step-card-content d-flex align-items-center">
                                              <span className="">
                                                <img
                                                  height="16px"
                                                  width="16px"
                                                  src="/Assets/alarm-invite.svg"
                                                />
                                              </span>
                                              {window.location.href.includes(
                                                "/present/invite"
                                              ) ? (
                                                <span>
                                                  {localizeTimeTaken(
                                                    item?.time_taken?.replace(
                                                      "-",
                                                      ""
                                                    )
                                                  )}
                                                </span>
                                              ) : (
                                                <>
                                                  {item?.step_status === null
                                                    ? item.count2 +
                                                      " " +
                                                      t(
                                                        `time_unit.${item.time_unit}`
                                                      )
                                                    : localizeTimeTakenActive(
                                                        item?.time_taken?.replace(
                                                          "-",
                                                          ""
                                                        )
                                                      )}
                                                  {item?.step_status !==
                                                    null && (
                                                    <span>
                                                      &nbsp; /{" "}
                                                      {item.count2 +
                                                        " " +
                                                        t(
                                                          `time_unit.${item.time_unit}`
                                                        )}
                                                    </span>
                                                  )}
                                                </>
                                              )}{" "}
                                            </Card.Text>
                                            <div className="mb-2">
                                              <div className="creator">
                                                {t("Privacy")}
                                              </div>
                                              <div className="">
                                                <div className="d-flex align-items-center flex-wrap">
                                                  {item?.meeting
                                                    ?.moment_privacy ===
                                                  "public" ? (
                                                    <Avatar
                                                      src="/Assets/Tek.png"
                                                      style={{
                                                        borderRadius: "0",
                                                      }}
                                                    />
                                                  ) : item?.meeting
                                                      ?.moment_privacy ===
                                                    "team" ? (
                                                    <Avatar.Group>
                                                      {item?.meeting?.moment_privacy_teams_data?.map(
                                                        (item) => {
                                                          return (
                                                            <>
                                                              {/* <Tooltip
                                                                title={
                                                                  item?.name
                                                                }
                                                                placement="top"
                                                              > */}
                                                              <Avatar
                                                                size="large"
                                                                // src={
                                                                //   item?.logo?.startsWith("teams/")
                                                                //     ? Assets_URL + "/" + item.logo
                                                                //     : item.logo
                                                                // }
                                                                src={
                                                                  item?.logo?.startsWith(
                                                                    "http"
                                                                  )
                                                                    ? item.logo
                                                                    : Assets_URL +
                                                                      "/" +
                                                                      item.logo
                                                                }
                                                                style={{
                                                                  objectFit:
                                                                    "cover",
                                                                  objectPosition:
                                                                    "top",
                                                                }}
                                                              />
                                                              {/* </Tooltip> */}
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </Avatar.Group>
                                                  ) : item?.meeting
                                                      ?.moment_privacy ===
                                                    "enterprise" ? (
                                                    <img
                                                      src={
                                                        item?.meeting?.user?.enterprise?.logo?.startsWith(
                                                          "enterprises/"
                                                        )
                                                          ? Assets_URL +
                                                            "/" +
                                                            item?.meeting?.user
                                                              ?.enterprise?.logo
                                                          : item?.meeting?.user?.enterprise?.logo?.startsWith(
                                                              "storage/enterprises/"
                                                            )
                                                          ? Assets_URL +
                                                            "/" +
                                                            item?.meeting?.user
                                                              ?.enterprise?.logo
                                                          : item?.meeting?.user
                                                              ?.enterprise?.logo
                                                      }
                                                      alt="Logo"
                                                      style={{
                                                        width: "30px",
                                                        height: "30px",
                                                        objectFit: "fill",
                                                        borderRadius: "50%",
                                                      }}
                                                    />
                                                  ) : item?.meeting
                                                      ?.moment_privacy ===
                                                    "password" ? (
                                                    <svg
                                                      width="37px"
                                                      height="36px"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g
                                                        id="SVGRepo_bgCarrier"
                                                        stroke-width="0"
                                                      ></g>
                                                      <g
                                                        id="SVGRepo_tracerCarrier"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      ></g>
                                                      <g id="SVGRepo_iconCarrier">
                                                        {" "}
                                                        <path
                                                          d="M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
                                                          stroke="#000000"
                                                          stroke-width="2"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                        ></path>{" "}
                                                      </g>
                                                    </svg>
                                                  ) : (
                                                    <Avatar
                                                      src={
                                                        item?.meeting?.user?.image.startsWith(
                                                          "users/"
                                                        )
                                                          ? Assets_URL +
                                                            "/" +
                                                            item?.meeting?.user
                                                              ?.image
                                                          : item?.meeting?.user
                                                              ?.image
                                                      }
                                                      style={{
                                                        objectFit: "cover",
                                                        objectPosition:
                                                          "center top",
                                                        height: "24px",
                                                        width: "24px",
                                                      }}
                                                    />
                                                  )}

                                                  <span
                                                    className={`badge ms-2 ${
                                                      item?.meeting
                                                        ?.moment_privacy ===
                                                      "private"
                                                        ? "solution-badge-red"
                                                        : item?.meeting
                                                            ?.moment_privacy ===
                                                          "public"
                                                        ? "solution-badge-green"
                                                        : item?.meeting
                                                            ?.moment_privacy ===
                                                          "enterprise"
                                                        ? "solution-badge-blue"
                                                        : item?.meeting
                                                            ?.moment_privacy ===
                                                          "password"
                                                        ? "solution-badge-red"
                                                        : "solution-badge-yellow"
                                                    }`}
                                                    style={{
                                                      padding:
                                                        "3px 8px 3px 8px",
                                                    }}
                                                  >
                                                    {item?.meeting
                                                      ?.moment_privacy ===
                                                    "private"
                                                      ? t(
                                                          "solution.badge.private"
                                                        )
                                                      : item?.meeting
                                                          ?.moment_privacy ===
                                                        "public"
                                                      ? t(
                                                          "solution.badge.public"
                                                        )
                                                      : item?.meeting
                                                          ?.moment_privacy ===
                                                        "enterprise"
                                                      ? t(
                                                          "solution.badge.enterprise"
                                                        )
                                                      : item?.meeting
                                                          ?.moment_privacy ===
                                                        "password"
                                                      ? t(
                                                          "solution.badge.password"
                                                        )
                                                      : t(
                                                          "solution.badge.team"
                                                        )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div className="step-images">
                                    {item.editor_content &&
                                    item.editor_content.trim() !==
                                      "<html><head></head><body></body></html>" ? (
                                      <div className="step-img-container">
                                        {firstImageUrl ? (
                                          <Card.Img
                                            className="step-img"
                                            src={firstImageUrl}
                                          />
                                        ) : (
                                          <div className="fallback-img-container">
                                          
                                            <FiEdit
                                              className="file-img img-fluid"
                                              style={{ padding: "12px" }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ) : item.editor_type === "File" ? (
                                      <div className="file-img-container">
                                        <PiFilePdfLight
                                          className="file-img img-fluid"
                                          style={{ padding: "12px" }}
                                        />
                                      </div>
                                    ) : item.editor_type === "Video" ? (
                                      <div className="file-img-container">
                                        <IoVideocamOutline
                                          className="file-img img-fluid"
                                          style={{ padding: "12px" }}
                                        />
                                      </div>
                                    ) : item.editor_type === "Photo" ? (
                                      <div className="file-img-container">
                                        <MdOutlinePhotoSizeSelectActual
                                          className="file-img img-fluid"
                                          style={{ padding: "12px" }}
                                        />
                                      </div>
                                    ) : item.url ? (
                                      <div className="link-img-container">
                                        <IoCopyOutline
                                          className="file-img img-fluid"
                                          style={{ padding: "12px" }}
                                        />
                                      </div>
                                    ) : (
                                      <div className="fallback-img-container">
                                        <FiEdit
                                          className="file-img img-fluid"
                                          style={{ padding: "12px" }}
                                        />
                                      </div>
                                    )}
                                  </div> */}
                                      </div>
                                    </Card.Body>
                                  </Card>
                                  {doneMomentsModal === index && (
                                    <>
                                      <Modal
                                        show={doneMomentsModal === index}
                                        onHide={() =>
                                          setDoneMomentsModal(false)
                                        }
                                        size="xl"
                                        centered
                                        className="modal-fade report-steps-note-modal"
                                      >
                                        <Modal.Body
                                          style={{
                                            padding: "25px 53px",
                                          }}
                                        >
                                          <div>
                                            {item?.editor_type === "File" ||
                                            item?.editor_type === "Video" ||
                                            item?.editor_type === "Photo" ? (
                                              <div>
                                                <iframe
                                                  src={
                                                    Assets_URL +
                                                    "/" +
                                                    (item?.file +
                                                      "#toolbar=0&view=fitH")
                                                  }
                                                  width="100%"
                                                  height="500px"
                                                />
                                              </div>
                                            ) : item?.editor_type === "Url" ? (
                                              <>
                                                <iframe
                                                  src={getYoutubeEmbedUrl(
                                                    item?.url
                                                  )}
                                                  width="100%"
                                                  height="500px"
                                                />
                                              </>
                                            ) : (
                                              <div
                                                className="rendered-content-report-step-chart"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    item?.editor_content !==
                                                      null ||
                                                    item?.editor_content !== ""
                                                      ? item?.editor_content
                                                      : " ",
                                                }}
                                              />
                                            )}
                                            <h3>{item?.title}</h3>
                                          </div>
                                          <div>
                                            {item?.time_unit === "days" ? (
                                              <>
                                                <Editor
                                                  onBlur={(value) => {
                                                    console.log("value", value);
                                                  }}
                                                  disabled
                                                  // key={selectedIndex}
                                                  apiKey={TINYMCEAPI}
                                                  // value={inputData?.steps[selectedIndex].note}
                                                  value={`
                                    <p>Campaign Name: ${item.email_campaigns?.campaign_name}</p>
                                    <p>Total Sendings: ${item.email_campaigns?.total_sendings}</p>
                                    <p>Total Recipients: ${item.email_campaigns?.total_recipients}</p>
                                    <p>Total Opens: ${item.email_campaigns?.total_opens}</p>
                                    <p>Total Clicks: ${item.email_campaigns?.total_clicks}</p>
                                    <p>Total Unsubscribes: ${item.email_campaigns?.total_unsubscribes}</p>
                                  `}
                                                  init={{
                                                    statusbar: false,
                                                    branding: false,
                                                    height: 400,
                                                    menubar: true,
                                                    language: "fr_FR",
                                                    // language: "en_EN",
                                                    plugins:
                                                      "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                                    toolbar:
                                                      "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                                    image_advtab: true,
                                                    file_picker_types: "image",

                                                    file_picker_callback:
                                                      function (
                                                        callback,
                                                        value,
                                                        meta
                                                      ) {
                                                        if (
                                                          meta.filetype ===
                                                          "image"
                                                        ) {
                                                          const input =
                                                            document.createElement(
                                                              "input"
                                                            );
                                                          input.setAttribute(
                                                            "type",
                                                            "file"
                                                          );
                                                          input.setAttribute(
                                                            "accept",
                                                            "image/*"
                                                          );

                                                          input.onchange =
                                                            function () {
                                                              const file =
                                                                input.files[0];
                                                              const reader =
                                                                new FileReader();

                                                              reader.onload =
                                                                function (e) {
                                                                  const img =
                                                                    new Image();
                                                                  img.src =
                                                                    e.target.result;

                                                                  img.onload =
                                                                    function () {
                                                                      const canvas =
                                                                        document.createElement(
                                                                          "canvas"
                                                                        );
                                                                      const ctx =
                                                                        canvas.getContext(
                                                                          "2d"
                                                                        );
                                                                      const maxWidth = 700;
                                                                      const maxHeight = 394;

                                                                      let newWidth =
                                                                        img.width;
                                                                      let newHeight =
                                                                        img.height;

                                                                      if (
                                                                        img.width >
                                                                        maxWidth
                                                                      ) {
                                                                        newWidth =
                                                                          maxWidth;
                                                                        newHeight =
                                                                          (img.height *
                                                                            maxWidth) /
                                                                          img.width;
                                                                      }

                                                                      if (
                                                                        newHeight >
                                                                        maxHeight
                                                                      ) {
                                                                        newHeight =
                                                                          maxHeight;
                                                                        newWidth =
                                                                          (img.width *
                                                                            maxHeight) /
                                                                          img.height;
                                                                      }

                                                                      canvas.width =
                                                                        newWidth;
                                                                      canvas.height =
                                                                        newHeight;

                                                                      ctx.drawImage(
                                                                        img,
                                                                        0,
                                                                        0,
                                                                        newWidth,
                                                                        newHeight
                                                                      );

                                                                      const resizedImageData =
                                                                        canvas.toDataURL(
                                                                          file.type
                                                                        );

                                                                      // Pass the resized image data to the callback function
                                                                      callback(
                                                                        resizedImageData,
                                                                        {
                                                                          alt: file.name,
                                                                        }
                                                                      );
                                                                    };

                                                                  img.src =
                                                                    e.target.result;
                                                                };

                                                              reader.readAsDataURL(
                                                                file
                                                              );
                                                            };

                                                          input.click();
                                                        }
                                                      },
                                                  }}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <Editor
                                                  onBlur={(value) => {
                                                    console.log("value", value);
                                                  }}
                                                  // disabled
                                                  // key={selectedIndex}
                                                  apiKey={TINYMCEAPI}
                                                  // value={inputData?.steps[selectedIndex].note}
                                                  value={item?.note}
                                                  init={{
                                                    statusbar: false,
                                                    branding: false,
                                                    height: 400,
                                                    menubar: true,
                                                    language: "fr_FR",
                                                    // language: "en_EN",
                                                    plugins:
                                                      "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                                    toolbar:
                                                      "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                                    image_advtab: true,
                                                    file_picker_types: "image",

                                                    file_picker_callback:
                                                      function (
                                                        callback,
                                                        value,
                                                        meta
                                                      ) {
                                                        if (
                                                          meta.filetype ===
                                                          "image"
                                                        ) {
                                                          const input =
                                                            document.createElement(
                                                              "input"
                                                            );
                                                          input.setAttribute(
                                                            "type",
                                                            "file"
                                                          );
                                                          input.setAttribute(
                                                            "accept",
                                                            "image/*"
                                                          );

                                                          input.onchange =
                                                            function () {
                                                              const file =
                                                                input.files[0];
                                                              const reader =
                                                                new FileReader();

                                                              reader.onload =
                                                                function (e) {
                                                                  const img =
                                                                    new Image();
                                                                  img.src =
                                                                    e.target.result;

                                                                  img.onload =
                                                                    function () {
                                                                      const canvas =
                                                                        document.createElement(
                                                                          "canvas"
                                                                        );
                                                                      const ctx =
                                                                        canvas.getContext(
                                                                          "2d"
                                                                        );
                                                                      const maxWidth = 700;
                                                                      const maxHeight = 394;

                                                                      let newWidth =
                                                                        img.width;
                                                                      let newHeight =
                                                                        img.height;

                                                                      if (
                                                                        img.width >
                                                                        maxWidth
                                                                      ) {
                                                                        newWidth =
                                                                          maxWidth;
                                                                        newHeight =
                                                                          (img.height *
                                                                            maxWidth) /
                                                                          img.width;
                                                                      }

                                                                      if (
                                                                        newHeight >
                                                                        maxHeight
                                                                      ) {
                                                                        newHeight =
                                                                          maxHeight;
                                                                        newWidth =
                                                                          (img.width *
                                                                            maxHeight) /
                                                                          img.height;
                                                                      }

                                                                      canvas.width =
                                                                        newWidth;
                                                                      canvas.height =
                                                                        newHeight;

                                                                      ctx.drawImage(
                                                                        img,
                                                                        0,
                                                                        0,
                                                                        newWidth,
                                                                        newHeight
                                                                      );

                                                                      const resizedImageData =
                                                                        canvas.toDataURL(
                                                                          file.type
                                                                        );

                                                                      // Pass the resized image data to the callback function
                                                                      callback(
                                                                        resizedImageData,
                                                                        {
                                                                          alt: file.name,
                                                                        }
                                                                      );
                                                                    };

                                                                  img.src =
                                                                    e.target.result;
                                                                };

                                                              reader.readAsDataURL(
                                                                file
                                                              );
                                                            };

                                                          input.click();
                                                        }
                                                      },
                                                  }}
                                                />
                                              </>
                                            )}
                                          </div>
                                        </Modal.Body>
                                        <Modal.Footer className="justify-content-center">
                                          <Button
                                            variant="danger"
                                            onClick={() =>
                                              setDoneMomentsModal(false)
                                            }
                                          >
                                            {t("buttons.cancel")}
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    </>
                                  )}
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {isModalOpen && (
        <Suspense fallback={<div>Loading...</div>}>
          <div className="new-meeting-modal">
            <LazyStepChart
              meetingId={selectedMeeting?.id}
              id={stepId}
              show={isModalOpen}
              meeting1={selectedMeeting}
              setId={setStepId}
              closeModal={handleCloseModal}
              key={`step-chart-${stepId}`}
              isDrop={isDrop}
              stepIndex={stepIndex}
            />
          </div>
        </Suspense>
      )}
    </>
  );
}

export default Action;
