import React, { useEffect, useState, lazy, Suspense, useRef } from "react";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import { Button, Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSteps } from "../../../context/Step";
import { useSidebarContext } from "../../../context/SidebarContext";
import { Editor } from "@tinymce/tinymce-react";
import moment from "moment";
import { AiOutlinePlaySquare } from "react-icons/ai";
import { PiFilePdfLight } from "react-icons/pi";
import { IoCopyOutline, IoVideocamOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import {
  convertCount2ToSeconds,
  convertTimeTakenToSeconds,
  formatStepDate,
  openGoogleMeet,
} from "../../Utils/MeetingFunctions";
import InviteStepChartModal from "./InviteStepChartModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { HiUserCircle } from "react-icons/hi2";
import {
  MdInsertPhoto,
  MdKeyboardArrowDown,
  MdOutlinePhotoSizeSelectActual,
} from "react-icons/md";
import { FaFileExcel, FaVideo } from "react-icons/fa";
import { useFormContext } from "../../../context/CreateMeetingContext";
import { toast } from "react-toastify";
import { RiFileExcel2Line } from "react-icons/ri";
import Spreadsheet from "react-spreadsheet";
import { read, utils } from "xlsx";
import DOMPurify from "dompurify";
const LazyStepChart = lazy(() => import("./CreateNewMeeting/StepChart"));

const StepCard = ({ data, startTime, users, fromMeeting, meeting1 }) => {
  const { setCall } = useFormContext();
  const TINYMCEAPI = process.env.REACT_APP_TINYMCE_API;
  const { steps, updateSteps } = useSteps();
  const [t] = useTranslation("global");
  const [meeting, setMeeting] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  const params = useParams();
  const { meeting_id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toggle, show } = useSidebarContext();
  const [stepId, setStepId] = useState(null);
  const [stepIndex, setStepIndex] = useState(null);
  const { setIsUpdated } = useFormContext();
  const [isDrop, setIsDrop] = useState(false);
  const [excelData, setExcelData] = useState(null);
  console.log("excelData", excelData);
  const [inProgressStep, setInProgressStep] = useState(null);
  console.log("inProgressStep", inProgressStep);
  const handleCloseModal = () => {
    setIsModalOpen(!isModalOpen);
    toggle(true);
  };
  useEffect(() => {
    const getMeeting = async () => {
      // setIsLoading(true);
      try {
        const currentTime = new Date();
        // Get the user's timezone
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log(userTimeZone, "userTimeZone");
        // Convert the current time to the user's timezone
        const options = { timeZone: userTimeZone };
        const timeInUserZone = new Date(
          currentTime.toLocaleString("en-US", options)
        );
  
        // Format time
        const hours = timeInUserZone.getHours();
        const minutes = timeInUserZone.getMinutes();
        const seconds = timeInUserZone.getSeconds();
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
        const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
  
        console.log("formatting time", formattedTime);
        // Format date
        const year = timeInUserZone.getFullYear();
        const month = (timeInUserZone.getMonth() + 1).toString().padStart(2, "0");
        const day = timeInUserZone.getDate().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        console.log("formatting date", formattedDate);
        const response = await axios.get(
          `${API_BASE_URL}/get-meeting/${
            id || meeting_id
          }?current_time=${formattedTime}&current_date=${formattedDate}`
        );
        if (response.status) {
          updateSteps(response?.data?.data?.steps);
          setMeeting(response.data?.data);
        }
      } catch (error) {
        console.log("error while fetching meeting data", error);
      } finally {
      }
    };
    getMeeting();
  }, [id, meeting_id, toggle]);
  const userId = parseInt(sessionStorage.getItem("user_id"));
  const [loading, setLoading] = useState(false);
  const changeStatusAndPlay = async (item) => {
    setLoading(true);
    // if (parseInt(item.user_id) !== userId) {
    //   setLoading(false);
    //   return;
    // }
    const loggedInUserId = sessionStorage.getItem("user_id"); // Get the logged-in user's ID from session storage
    // Check if the item type is "Newsletter"
    if (item.type !== "Newsletter") {
      // Check if the logged-in user is in the guides array
      const isGuide =
        item?.steps?.some(
          (guide) => guide?.userPID === parseInt(loggedInUserId)
        ) ||
        // Check if all participants have user_id equal to meeting.user.id
        item?.user?.id === parseInt(loggedInUserId);

      if (!isGuide) {
        // console.log("User is not a guide. Cannot play the meeting.");
        setLoading(false);

        toast.error(
          t("meeting.formState.you are not allowed to play the meeting")
        );
        return;
      }
    }

    console.log("User is a guide. Proceeding to play the meeting...");
    console.log("change->", item);
    let current_time;
    let current_date;
    let end_date;

    // Find the step with step_status "in_progress"
    const inProgressStep = item?.steps?.find(
      (step) => step?.step_status === "in_progress"
    );
    // Find the step with step_status "in_progress"
    for (const step of item?.steps || []) {
      if (step?.step_status === "in_progress" && step?.step_time) {
        current_date = step.current_date || step?.start_date;
        current_time = step.step_time;
        end_date = step?.end_date;
        break;
      }
    }

    if (!current_time || !current_date) {
      console.log("No in-progress step with current_time found.");
      setLoading(false);

      return;
    }

    console.log("step_time from in-progress step:", current_time);
    console.log("end_date from in-progress step:", end_date);

    // const [currentHours, currentMinutes, currentSeconds] = current_time
    //   ?.split(":")
    //   .map(Number);

    // Parse time with AM/PM
    const [time, meridiem] = current_time.split(" ");
    let [currentHours, currentMinutes, currentSeconds] = time
      .split(":")
      .map(Number);
    if (meridiem.toLowerCase() === "pm" && currentHours < 12) {
      currentHours += 12;
    } else if (meridiem.toLowerCase() === "am" && currentHours === 12) {
      currentHours = 0;
    }
    // Split the date string and create a new Date object
    const [day, month, year] = current_date?.split("-").map(Number);
    console.log("Parsed year:", year, "month:", month, "day:", day);
    const myDate = `${day}/${month}/${year}`;
    const currentDateTime = new Date(myDate);

    // Update the Date object with the extracted hours, minutes, and seconds
    currentDateTime.setHours(currentHours, currentMinutes, currentSeconds);
    console.log("currentDateTime:", currentDateTime);

    const realCurrentTime = new Date();
    console.log("realCurrentTime:", realCurrentTime);

    const count2InSeconds = convertCount2ToSeconds(
      inProgressStep?.count2,
      inProgressStep?.time_unit
    );

    const differenceInMilliseconds = realCurrentTime - currentDateTime;
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    console.log(
      "difference in seconds: -> end_time - real_time",
      differenceInSeconds
    );

    // Subtract count2InSeconds from differenceInSeconds
    const adjustedDifferenceInSeconds = differenceInSeconds - count2InSeconds;

    console.log("Adjusted difference in seconds:", adjustedDifferenceInSeconds);

    localStorage.setItem("difference", differenceInSeconds);
    if (isNaN(differenceInSeconds)) {
      console.error("differenceInSeconds is NaN");
      setLoading(false);

      return;
    }

    const convertSecondsToDDHHMMSS = (seconds) => {
      if (isNaN(seconds)) {
        console.error("seconds is NaN");
        setLoading(false);

        return "NaN";
      }

      const days = Math.floor(seconds / (24 * 3600));
      seconds %= 24 * 3600;
      const hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      const minutes = Math.floor(seconds / 60);
      seconds %= 60;
      return `${String(days).padStart(2, "0")}d:${String(hours).padStart(
        2,
        "0"
      )}h:${String(minutes).padStart(2, "0")}m:${String(seconds).padStart(
        2,
        "0"
      )}s`;
    };

    const delay = convertSecondsToDDHHMMSS(adjustedDifferenceInSeconds);
    console.log("formatted delay:", delay);

    const updatedSteps = item?.steps?.map((step) => {
      console.log("my step--->", step);
      if (step.step_status === "in_progress") {
        let savedTime = Number(step.savedTime) || 0;

        let actualStepTime = 0;
        if (step.time_unit === "days") {
          actualStepTime = Number(step.count2 * 86400);
        } else if (step.time_unit === "hours") {
          actualStepTime = Number(step.count2 * 3600);
        } else if (step.time_unit === "minutes") {
          actualStepTime = Number(step.count2 * 60);
        } else {
          actualStepTime = Number(step.count2);
        }

        console.log("actualStepTime", actualStepTime);
        const negativeTime = Number(step.negative_time) || 0;
        console.log("saved time: ", savedTime);
        console.log("negative time: ", negativeTime);

        let newSavedTime = savedTime - differenceInSeconds;
        let newNegativeTime = negativeTime;

        let newTime = actualStepTime - differenceInSeconds;
        localStorage.setItem("newTime", newTime);
        // Adjust savedTime
        if (newTime >= 0) {
          savedTime = newTime;
          if (newTime < 0) savedTime = 0;
        }
        if (differenceInSeconds <= actualStepTime) {
          console.log("difference smaller than actualStepTime");
          step.delay = null;
          step.savedTime = savedTime;
          step.negative_time = newNegativeTime;
        } else {
          console.log("difference greater than actualStepTime");
          step.delay = delay;
          step.savedTime = 0;
          step.negative_time = "99";
        }

        console.log("new saved time1: ", newSavedTime);
        console.log("new negative time: ", newNegativeTime);

        return {
          ...step,
          delay: step.delay,
          savedTime: step.savedTime,
          negative_time: step.negative_time,
        };
      } else {
        return {
          ...step,
        };
      }
    });

    console.log("updatedSteps->", updatedSteps);

    const payload = {
      ...item,
      _method: "put",
      end_time: item.end_time,
      delay: delay,
      steps: updatedSteps,
      moment_privacy_teams:
      item?.moment_privacy === "team" &&
      item?.moment_privacy_teams?.length &&
        typeof item?.moment_privacy_teams[0] === "object"
        ? item?.moment_privacy_teams.map((team) => team.id)
        : item?.moment_privacy_teams || [], // Send as-is if IDs are already present
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${item.id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status) {
        navigate(`/play/${item.id}`);
        setLoading(false);

        if (item?.meet_link && item?.location === "Google Meet") {
          openGoogleMeet(item?.meet_link);
        }
      }
    } catch (error) {
      console.log("error while updating meeting status", error);
      setLoading(false);
    }
  };

  const [doneMomentsModal, setDoneMomentsModal] = useState(null);

  const toggleModal = async (id) => {
    setDoneMomentsModal((prev) => (prev === id ? null : id));
  };

  const localizeTimeTaken = (timeTaken) => {
    if (!timeTaken) return;

    // Retrieve localized time units
    const timeUnits = t("time_unit", { returnObjects: true });

    // Split timeTaken string by spaces and iterate over each segment
    return timeTaken
      .split(" ")
      .map((part) => {
        // Check if the part is numeric or text
        if (!isNaN(part)) {
          return part; // Return the number as is
        }
        // Otherwise, it's a unit; look up its localized version
        return timeUnits[part] || part; // Fallback to original if no localization
      })
      .join(" ");
  };

  const convertTo24HourFormat = (time, type) => {
    if (!time || !type) {
      return false;
    }
    // Assuming time is in 'hh:mm:ss A' format (12-hour format with AM/PM)
    // const timeMoment = moment(time, "hh:mm:ss A");
    // return timeMoment.isValid() ? timeMoment.format("HH:mm:ss") : "";
    // Assuming time is in 'hh:mm:ss A' format (12-hour format with AM/PM)
    const timeMoment = moment(time, "hh:mm:ss A");

    // Check if the time is valid
    if (!timeMoment.isValid()) return "";

    // If the meeting type is 'Quiz', include seconds in the format
    const format = type === "seconds" ? "HH[h]mm[m]ss" : "HH[h]mm";

    // Return the time in the appropriate format
    return timeMoment.format(format);
  };

  const localizeTimeTakenActive = (timeTaken) => {
    if (!timeTaken) return "";

    // Retrieve localized time units
    const timeUnits = t("time_unit", { returnObjects: true });

    // Split the timeTaken string by " - " to separate time components
    const timeParts = timeTaken.split(" - ");

    // Initialize variables for each time component
    let days = null;
    let hours = null;
    let minutes = null;
    let seconds = null;

    // Iterate over each part and assign it to the corresponding variable
    timeParts.forEach((part) => {
      if (part.includes("day")) {
        days = part;
      } else if (part.includes("hour")) {
        hours = part;
      } else if (part.includes("min")) {
        minutes = part;
      } else if (part.includes("sec")) {
        seconds = part;
      }
    });

    // Check if days are present
    const hasDays = Boolean(days);

    // Determine what to show based on the presence of days
    let result = "";
    if (hasDays) {
      // Show days and hours if days are present
      result = [days, hours].filter(Boolean).join(" - ");
    } else if (hours) {
      // Show only hours and minutes if hours and minutes are present
      result = [hours, minutes].filter(Boolean).join(" - ");
    } else if (minutes) {
      // Show minutes only if no days or hours are present
      // result = minutes;
      result = [minutes, seconds].filter(Boolean).join(" - ");
    } else {
      result = seconds;
    }

    // Return empty string if result is undefined or empty
    if (!result) return "";

    // Localize and return the result
    return result
      .split(" ")
      .map((part) => (isNaN(part) ? timeUnits[part] || part : part))
      .join(" ");
  };

  const RemoveTime = (time_taken, time_unit) => {
    if (!time_taken) return;
    if (time_unit === "days") {
      const newTimeTaken = localizeTimeTaken(time_taken);
      // Split the time_taken string by ' - ' and only keep the first two parts (days and hours)
      const parts = newTimeTaken?.split(" - ");
      if (parts?.length > 2) {
        return parts.slice(0, 2).join(" - ");
      }
    }
    return localizeTimeTaken(time_taken);
  };
  const calculateNewDateForStep = (baseDate, daysToAdd) => {
    if (!baseDate || !daysToAdd) return;
    let date = new Date(baseDate);
    date.setDate(date.getDate() + daysToAdd);
    return date.toISOString().split("T")[0]; // returns the date in 'YYYY-MM-DD' format
  };
  // // Assuming you have an array of steps with dates (stepsWithDates) and meetingData
  const formatDate = (date) => {
    if (!date) return null;
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const calculateStepDates = (steps, meetingDate, meetingTime) => {
    if (!steps || !meetingDate || !meetingTime) {
      console.error("Steps, meetingDate, or meetingTime is null or undefined");
      return [];
    }

    const stepsWithDates = [];
    let currentDate = new Date(`${meetingDate}T${meetingTime}`);

    steps.forEach((step, index) => {
      const stepStartDate = new Date(currentDate);
      stepsWithDates.push(formatDate(stepStartDate));

      // Parse time_taken based on its format
      let timeToAdd = 0;
      if (step.time_taken?.includes("day")) {
        timeToAdd = parseInt(step.time_taken) * 24 * 60 * 60 * 1000; // Convert days to milliseconds
      } else if (step.time_taken?.includes("hour")) {
        timeToAdd = parseInt(step.time_taken) * 60 * 60 * 1000; // Convert hours to milliseconds
      } else if (step.time_taken?.includes("minute")) {
        timeToAdd = parseInt(step.time_taken) * 60 * 1000; // Convert minutes to milliseconds
      } else if (step.time_taken?.includes("second")) {
        timeToAdd = parseInt(step.time_taken) * 1000; // Convert seconds to milliseconds
      }

      // Add timeToAdd to currentDate
      currentDate = new Date(currentDate.getTime() + timeToAdd);
    });

    // Log the last step's end date
    console.log("Last Step End Date: ", formatDate(currentDate));

    return stepsWithDates;
  };

  const dates = calculateStepDates(steps, meeting?.date, meeting?.start_time);

  console.log("dates", dates);
  console.log("steps", steps);

  const [items, setItems] = useState(steps); // Store the items in the state

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const draggedStep = items[result.source.index];
    const destinationStep = items[result.destination.index];

    // Prevent drag if status is 'in_progress'
    if (
      draggedStep?.step_status === "in_progress" ||
      draggedStep?.step_status === "completed"
    ) {
      console.log("Cannot drag steps with 'in_progress' status.");
      return;
    }

    // Prevent moving any step before a 'completed' step
    if (
      destinationStep?.step_status === "completed" &&
      draggedStep?.step_status !== "completed"
    ) {
      console.log(
        "Cannot move an 'upcoming', 'in_progress', or 'completed' step before a 'completed' step."
      );
      return;
    }

    // Prevent placing a 'completed' step after an 'upcoming' or 'in_progress' step
    if (
      draggedStep?.step_status === "completed" &&
      (destinationStep?.step_status === null ||
        destinationStep?.step_status === "in_progress")
    ) {
      console.log(
        "Cannot place a 'completed' step after an 'upcoming' or 'in_progress' step."
      );
      return;
    }

    // Prevent placing a step with 'null' status before an 'in_progress' step
    if (
      draggedStep?.step_status === null &&
      destinationStep?.step_status === "in_progress"
    ) {
      console.log(
        "Cannot place a step with 'null' status before an 'in_progress' step."
      );
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    // Get the ID of the dragged step
    const draggedStepId = reorderedItems[result.destination.index].id;
    console.log("Dragged Step ID:", draggedStepId);

    setItems(reorderedItems);

    // Call the API to save the new order after dragging
    try {
      const response = await axios.post(
        `${API_BASE_URL}/steps/reorder`,
        {
          meeting_id: meeting1?.id,
          // draggedStepId: draggedStepId, // Pass the dragged step ID
          steps: reorderedItems.map((step, index) => ({
            id: step.id,
            order_no: index + 1, // You can pass the new order or any necessary data
          })),
          _method: "post",
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Order updated successfully", response.data);
        const steps = response?.data?.steps;
        updateSteps(steps);
        setCall((prev) => !prev);
      } else {
        console.error("Error updating the order");
      }
    } catch (error) {
      console.error("API call failed:", error);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // Update the order_no after reordering
    return result.map((item, index) => ({
      ...item,
      order_no: index + 1, // Update the order_no based on the new index
    }));
  };
  const getYoutubeEmbedUrl = (url) => {
    if (!url) {
      return false;
    }
    if (url.includes("youtube.com/watch")) {
      const videoUrl = new URL(url);
      const videoId = videoUrl.searchParams.get("v");

      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
      // Handle the shortened YouTube URL (youtu.be)
    }
    if (url.includes("youtu.be/")) {
      const videoId = url.split("youtu.be/")[1];
      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }

    return false; // Return false if it's not a valid YouTube URL
  };
  const [dropdownVisible, setDropdownVisible] = useState(
    Array(data?.length).fill(true)
  );
  const dropdownRefs = useRef([]);

  const toggleDropdown = (index) => {
    return;
    setDropdownVisible((prev) => {
      const newDropdownVisible = [...prev];
      newDropdownVisible[index] = !newDropdownVisible[index];
      return newDropdownVisible;
    });
  };
  useEffect(() => {
    data?.forEach((item, index) => {
      const el = dropdownRefs.current[index];
      if (el) {
        if (dropdownVisible[index]) {
          el.style.display = "block";
          requestAnimationFrame(() => {
            el.classList.add("show");
          });
        } else {
          el.classList.remove("show");
          el.addEventListener(
            "transitionend",
            () => {
              el.style.display = "none";
            },
            { once: true }
          );
        }
      }
    });
  }, [dropdownVisible, data]);

  useEffect(() => {
    const currentInProgressStep = steps.find(
      (item) => item.step_status === "in_progress"
    );
    setInProgressStep(currentInProgressStep || null);
  }, [steps]);

  

  // Fetch Excel data when inProgressStep changes
  useEffect(() => {
    const fetchExcel = async () => {
      if (inProgressStep?.editor_type === "Excel" && inProgressStep?.file) {
        try {
          const fileResponse = await axios.get(
            `${Assets_URL}/${inProgressStep.file}`,
            {
              responseType: "arraybuffer",
            }
          );

          const fileData = fileResponse.data;
          const workbook = read(fileData, { type: "buffer" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonSheetData = utils.sheet_to_json(worksheet, { header: 1 });

          const formattedData = jsonSheetData.map((row, rowIndex) =>
            row.map((cell) => ({
              value: cell,
              readOnly: rowIndex === 0,
            }))
          );

          setExcelData(formattedData);
        } catch (error) {
          console.error("Error fetching Excel file:", error);
        }
      }
    };

    if (inProgressStep) {
      fetchExcel();
    }
  }, [inProgressStep]);

  const fetchExcelData = async (file) => {
    if (file) {
      try {
        const response = await fetch(`${Assets_URL}/${file}`, {
          method: "GET",
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const fileData = await response.arrayBuffer();
        const workbook = read(fileData, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonSheetData = utils.sheet_to_json(worksheet, { header: 1 });
  
        const formattedData = jsonSheetData.map((row, rowIndex) =>
          row.map((cell) => ({
            value: cell,
            readOnly: rowIndex === 0,
          }))
        );
  
        setExcelData(formattedData);
      } catch (error) {
        console.error("Error fetching Excel file:", error);
      }
    }
  };
  

  // import DOMPurify from 'dompurify';

  const sanitizeContent = (content) => {
    if (!content) return null;

    const sanitizedContent = DOMPurify.sanitize(content, {
      ADD_TAGS: [
        "table",
        "tr",
        "td",
        "th",
        "tbody",
        "thead",
        "tfoot",
        "caption",
        "iframe",
      ],
      ADD_ATTR: [
        "allow",
        "allowfullscreen",
        "frameborder",
        "scrolling",
        "src",
        "title",
        "style",
      ],
    });

    // Create a temporary div to hold the sanitized HTML and parse it
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = sanitizedContent;

    // Find all iframe tags within the content
    const iframes = tempDiv.querySelectorAll("iframe");

    // Replace each iframe tag with a link while preserving its surrounding structure
    iframes.forEach((iframe) => {
      const iframeSrc = iframe.getAttribute("src");

      if (iframeSrc) {
        const linkElement = document.createElement("a");
        linkElement.href = iframeSrc;
        linkElement.target = "_blank";

        // Create a more user-friendly link text (optional improvement)
        // linkElement.textContent = `Click here to view the content`;
        linkElement.textContent = iframeSrc;

        // Apply the truncation class to the link element
        linkElement.classList.add("truncated-link");
        // Replace iframe with the link while keeping it inside the parent tag (e.g., table, td)
        iframe.parentNode.replaceChild(linkElement, iframe);
      }
    });

    // Return the updated HTML as a string
    return tempDiv.innerHTML;
  };

  // return content.replace(
  //   /<iframe.*?src="(.*?)".*?<\/iframe>/gi,
  //   (match, src) => {
  //     return `<a href="${src}" target="_blank" rel="noopener noreferrer">${src}</a>`;
  //   }
  // );
  // };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="steps">
          {(provided) => (
            <div
              className="row"
              style={{ marginBottom: "3rem", gap: fromMeeting ? "4px" : "" }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {steps?.map((item, index) => {
                let editorContent = item.editor_content;
                const tempDiv = document.createElement("div");
                tempDiv.innerHTML = editorContent;
                const firstImageTag = tempDiv.querySelector("img");
                const firstImageUrl = firstImageTag
                  ? firstImageTag.getAttribute("src")
                  : "";
                let stepTime = item.count2;

                let [time, modifier] = startTime.split(" ");
                let [hours, minutes] = time.split(":").map(Number);

                if (modifier === "PM" && hours < 12) {
                  hours += 12;
                }
                if (modifier === "AM" && hours === 12) {
                  hours = 0;
                }

                let startDate = new Date();
                startDate.setHours(hours);
                startDate.setMinutes(minutes);
                startDate.setSeconds(0);
                startDate.setMinutes(startDate.getMinutes() + stepTime);

                const calculateTimeDifference = (end_time, current_time) => {
                  if (!end_time || !current_time) {
                    return null;
                  }

                  const endTime = new Date(`1970-01-01T${end_time}Z`);
                  const currentTime = new Date(`1970-01-01T${current_time}Z`);

                  let diff = (endTime - currentTime) / 1000; // difference in seconds

                  const days = Math.floor(diff / (24 * 3600));
                  diff -= days * 24 * 3600;
                  const hours = Math.floor(diff / 3600);
                  diff -= hours * 3600;
                  const minutes = Math.floor(diff / 60);
                  const seconds = Math.floor(diff % 60); // ensure the seconds are integers

                  return { days, hours, minutes, seconds };
                };

                const formatTimeDifference = (diff, timeUnit) => {
                  if (!diff || !timeUnit) {
                    return "";
                  }

                  switch (timeUnit) {
                    case "seconds":
                      return `${
                        diff.hours * 3600 + diff.minutes * 60 + diff.seconds
                      } seconds`;
                    case "minutes":
                      return `${diff.minutes} minutes ${diff.seconds} seconds`;
                    case "hours":
                      return `${diff.hours} hours ${diff.minutes} minutes ${diff.seconds} seconds`;
                    case "days":
                      return `${diff.days} days ${diff.hours} hours ${diff.minutes} minutes ${diff.seconds} seconds`;
                    default:
                      return `${diff.seconds} seconds`;
                  }
                };

                const timeDifference = calculateTimeDifference(
                  item?.end_time,
                  item?.current_time
                );
                const formattedTimeDifference = formatTimeDifference(
                  timeDifference,
                  item?.time_unit
                );

                const handleClick = (item, index) => {
                  if (loading) return;
                  setLoading(true);
                  // const selectedStep = data?.steps[index];
                  if (item?.step_status === "completed") {
                    if(item?.editor_type === "Excel"){
                      fetchExcelData(item?.file)
                    }
                    // handleViewDoneMoments(meeting1, item);
                    toggleModal(index);
                    toggle(true);
                    console.log("complete");
                  } else if (item?.step_status === "in_progress") {
                    changeStatusAndPlay(meeting1);
                    toggle(true);
                  } else {
                    toggle(false);
                    setIsModalOpen(!isModalOpen);
                    // setIsUpdated(true)
                    setStepId(item?.id);
                    setStepIndex(index);
                    setIsDrop(false);
                  }
                  setTimeout(() => setLoading(false), 2000); // Adjust the delay if necessary
                };

                const initialDate =
                  index === 0
                    ? meeting1?.date
                    : calculateNewDateForStep(
                        meeting1?.date,
                        steps
                          ?.slice(0, index)
                          .reduce((acc, curr) => acc + curr?.count2, 0)
                      );
                const initialDateEmail =
                  index === 0
                    ? meeting?.date
                    : calculateNewDateForStep(
                        meeting?.date,
                        steps
                          ?.slice(0, index)
                          .reduce((acc, curr) => acc + curr?.count2, 0)
                      );

                return (
                  <>
                    {fromMeeting ? (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={index}
                        isDragDisabled={
                          item.step_status === "in_progress" ||
                          item.step_status === "completed"
                        }
                      >
                        {(provided) => (
                          <div
                            className="col-12 ste"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={index}
                          >
                            <Card
                              className="mt-4 step-card-meeting"
                              onClick={() => {
                                // toggle(false);
                                handleClick(item, index);
                              }}
                            >
                              <Card.Body className="step-card-body">
                                <div className="step-number-container">
                                  <span className="step-number">
                                    {item?.order_no <= 9 ? "0" : " "}
                                    {item?.order_no}
                                  </span>
                                </div>
                                <div className="step-body">
                                  <div className="step-data stepcard-stepdata">
                                    <div className="step-header">
                                      <Card.Title className="step-card-heading">
                                        {item?.title}
                                      </Card.Title>
                                      {!window.location.href.includes(
                                        "/present/invite"
                                      ) &&
                                        // meeting.status === "in_progress" &&
                                        (item.step_status === "completed" ? (
                                          <span className="status-badge-completed">
                                            {/* Completed */}
                                            {t("badge.completed")}
                                          </span>
                                        ) : item.step_status ===
                                          "in_progress" ? (
                                          <span
                                            className={
                                              convertTimeTakenToSeconds(
                                                item?.time_taken
                                              ) >
                                              convertCount2ToSeconds(
                                                item?.count2,
                                                item?.time_unit
                                              )
                                                ? "status-badge-red"
                                                : "status-badge-inprogress"
                                            }
                                          >
                                            {t("badge.inprogress")}
                                          </span>
                                        ) : item.step_status === "paused" ? (
                                          <span className={"status-badge-red"}>
                                            {t("badge.paused")}
                                          </span>
                                        ) : (
                                          // null
                                          <span className="status-badge-upcoming">
                                            {t("badge.future")}
                                            {/* Upcoming */}
                                          </span>
                                        ))}
                                    </div>
                                    <div className="step-content">
                                      <Card.Subtitle className="step-card-subtext">
                                        {item.assigned_team ? (
                                          <>
                                            {item?.assigned_team?.logo ? (
                                              <img
                                                height="24px"
                                                width="24px"
                                                style={{
                                                  marginRight: "9px",
                                                  borderRadius: "20px",
                                                  objectFit: "cover",
                                                  objectPosition: "top",
                                                }}
                                                src={
                                                  Assets_URL +
                                                  "/" +
                                                  item?.assigned_team?.logo
                                                }
                                                alt={item?.assigned_team?.name}
                                              />
                                            ) : (
                                              <HiUserCircle
                                                style={{
                                                  height: "24px",
                                                  width: "24px",
                                                }}
                                              />
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {item?.image ? (
                                              <img
                                                height="24px"
                                                width="24px"
                                                style={{
                                                  marginRight: "9px",
                                                  borderRadius: "20px",
                                                  objectFit: "cover",
                                                  objectPosition: "top",
                                                }}
                                                src={
                                                  item?.image?.startsWith(
                                                    "users/"
                                                  )
                                                    ? Assets_URL +
                                                      "/" +
                                                      item?.image
                                                    : // : item?.image?.startsWith("users/")
                                                      // ? Assets_URL + "/" + item.image
                                                      item?.image
                                                }
                                                // src={
                                                //     item?.assigned_to_image
                                                // }
                                                alt="img"
                                              />
                                            ) : (
                                              <img
                                                height="24px"
                                                width="24px"
                                                style={{
                                                  marginRight: "9px",
                                                  borderRadius: "20px",
                                                  objectFit: "cover",
                                                  objectPosition: "top",
                                                }}
                                                // src={`${users?.participant_image}`}
                                                // src={
                                                //   // users?.image
                                                //   //   ? Assets_URL + "/" + users.image
                                                //     // :
                                                //      users?.participant_image
                                                // }
                                                src={
                                                  users?.image?.startsWith(
                                                    "users/"
                                                  )
                                                    ? Assets_URL +
                                                      "/" +
                                                      users?.image
                                                    : users?.image
                                                }
                                                alt="img"
                                              />
                                            )}
                                          </>
                                        )}

                                        {item.assigned_team ? (
                                          <span>
                                            {item?.assigned_team?.name}
                                          </span>
                                        ) : (
                                          <span>
                                            {item?.assigned_to_name ||
                                              `${users?.firstName} ${users?.lastName}`}
                                          </span>
                                        )}
                                      </Card.Subtitle>
                                      <Card.Text className="step-card-content flex-row align-items-center card-text gap-0">
                                        <img
                                          height="16px"
                                          width="16px"
                                          src="/Assets/ion_time-outline.svg"
                                        />
                                        {window.location.href.includes(
                                          "/present/invite"
                                        ) ? (
                                          <>
                                            <span className="me-2">
                                              {item?.step_time}
                                            </span>
                                          </>
                                        ) : (
                                          <span
                                            className={`${
                                              item?.time_unit === "days"
                                                ? "me-2"
                                                : "me-2"
                                            }`}
                                          >
                                            {item?.time_unit === "days" ? (
                                              <>
                                                {
                                                  item.step_status === null
                                                    ? formatStepDate(
                                                        item?.start_date
                                                      )
                                                    : // +
                                                      // " " +
                                                      // ` ${t("at")}` +
                                                      // " " +
                                                      // formatStepDate(item?.total_step_time)
                                                      formatStepDate(
                                                        item?.start_date
                                                      )
                                                  //  +
                                                  // " " +
                                                  // ` ${t("at")}` +
                                                  // " " +
                                                  // item.start_date?.split(
                                                  //   "T"
                                                  // )[0]
                                                }
                                              </>
                                            ) : (
                                              <>
                                                {item?.step_status === null
                                                  ? formatStepDate(
                                                      item?.start_date
                                                    ) +
                                                    " " +
                                                    ` ${t("at")}` +
                                                    " " +
                                                    convertTo24HourFormat(
                                                      item?.step_time,
                                                      item?.time_unit
                                                    )
                                                  : formatStepDate(
                                                      item?.start_date
                                                    ) +
                                                    " " +
                                                    ` ${t("at")}` +
                                                    " " +
                                                    convertTo24HourFormat(
                                                      item?.step_time,
                                                      item?.time_unit
                                                    )}
                                              </>
                                            )}
                                          </span>
                                        )}{" "}
                                      </Card.Text>
                                      <Card.Text className="step-card-content flex-row align-items-center gap-0 mb-3">
                                        <span className="">
                                          <img
                                            height="16px"
                                            width="16px"
                                            src="/Assets/alarm-invite.svg"
                                          />
                                        </span>
                                        {window.location.href.includes(
                                          "/present/invite"
                                        ) ? (
                                          <span>
                                            {localizeTimeTaken(
                                              item?.time_taken?.replace("-", "")
                                            )}
                                          </span>
                                        ) : (
                                          <>
                                            {item?.step_status === null
                                              ? item.count2 +
                                                " " +
                                                t(`time_unit.${item.time_unit}`)
                                              : localizeTimeTakenActive(
                                                  item?.time_taken?.replace(
                                                    "-",
                                                    ""
                                                  )
                                                )}
                                            {item?.step_status !== null && (
                                              <span>
                                                &nbsp; /{" "}
                                                {item.count2 +
                                                  " " +
                                                  t(
                                                    `time_unit.${item.time_unit}`
                                                  )}
                                              </span>
                                            )}
                                          </>
                                        )}{" "}
                                      </Card.Text>
                                    </div>
                                  </div>
                                  <div className="step-images">
                                    {item.editor_content &&
                                    item.editor_content.trim() !==
                                      "<html><head></head><body></body></html>" ? (
                                      <div className="step-img-container">
                                        {firstImageUrl ? (
                                          <Card.Img
                                            className="step-img report-step-img"
                                            src={firstImageUrl}
                                          />
                                        ) : (
                                          <div className="fallback-img-container">
                                            {/* <img
                                src="/Assets/Tek.png"
                                className="fallback-img"
                                alt="Fallback Image"
                              /> */}
                                            <FiEdit
                                              className="file-img img-fluid"
                                              style={{ padding: "12px" }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ) : item.editor_type === "File" ? (
                                      <div className="file-img-container">
                                        <PiFilePdfLight
                                          className="file-img img-fluid"
                                          style={{ padding: "12px" }}
                                        />
                                      </div>
                                    ) : item.editor_type === "Excel" ? (
                                      <div className="file-img-container">
                                        <RiFileExcel2Line
                                          className="file-img img-fluid"
                                          style={{ padding: "14px" }}
                                        />
                                      </div>
                                    ) : item.editor_type === "Video" ? (
                                      <div className="file-img-container">
                                        <IoVideocamOutline
                                          className="file-img img-fluid"
                                          style={{ padding: "12px" }}
                                        />
                                      </div>
                                    ) : item.editor_type === "Photo" ? (
                                      <div className="file-img-container">
                                        <MdOutlinePhotoSizeSelectActual
                                          className="file-img img-fluid"
                                          style={{ padding: "12px" }}
                                        />
                                      </div>
                                    ) : item.url ? (
                                      <div className="link-img-container">
                                        <IoCopyOutline
                                          className="file-img img-fluid"
                                          style={{ padding: "12px" }}
                                        />
                                      </div>
                                    ) : (
                                      <div className="fallback-img-container">
                                        <FiEdit
                                          className="file-img img-fluid"
                                          style={{ padding: "12px" }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        )}
                      </Draggable>
                    ) : (
                      <div className="col-12 email-step" key={index}>
                        <Card
                          className="mt-4 step-card"
                          onClick={() => {
                            item?.step_status === "in_progress" &&
                              toggleDropdown(index);
                          }}
                          style={{
                            cursor:
                              item?.step_status === "in_progress"
                                ? "pointer"
                                : "",
                          }}
                        >
                          <Card.Body className="d-flex">
                            <div className="d-flex align-items-center">
                              <div className="step-number-container">
                                <span className="step-number">
                                {item?.order_no <= 9 ? "0" : " "}
                                {item?.order_no}
                                </span>
                              </div>
                              {/* <span className="step-number">{index + 1}.</span> */}
                              <div
                                className={` ${
                                  dropdownVisible[index] &&
                                  item?.step_status === "in_progress"
                                    ? "d-none"
                                    : "d-block"
                                }`}
                              >
                                {item.editor_content &&
                                item.editor_content.trim() !==
                                  "<html><head></head><body></body></html>" ? (
                                  <div className="step-img-container">
                                    {firstImageUrl ? (
                                      <Card.Img
                                        className="step-img report-step-img"
                                        src={firstImageUrl}
                                      />
                                    ) : (
                                      <div className="fallback-img-container">
                                        <FiEdit
                                          className="file-img img-fluid"
                                          style={{ padding: "40px" }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                ) : item.editor_type === "File" ? (
                                  <div className="file-img-container">
                                    <PiFilePdfLight
                                      className="file-img img-fluid"
                                      style={{ padding: "40px" }}
                                    />
                                  </div>
                                ) : item.editor_type === "Excel" ? (
                                  <div className="file-img-container">
                                    <RiFileExcel2Line
                                      className="file-img img-fluid"
                                      style={{ padding: "42px" }}
                                    />
                                  </div>
                                ) : item.editor_type === "Video" ? (
                                  <div className="file-img-container">
                                    <IoVideocamOutline
                                      className="file-img img-fluid"
                                      style={{ padding: "40px" }}
                                    />
                                  </div>
                                ) : item.editor_type === "Photo" ? (
                                  <div className="file-img-container">
                                    <MdOutlinePhotoSizeSelectActual
                                      className="file-img img-fluid"
                                      style={{ padding: "40px" }}
                                    />
                                  </div>
                                ) : item.url ? (
                                  <div className="link-img-container">
                                    <IoCopyOutline
                                      className="file-img img-fluid"
                                      style={{ padding: "40px" }}
                                    />
                                  </div>
                                ) : (
                                  <div className="fallback-img-container">
                                    <FiEdit
                                      className="file-img img-fluid"
                                      style={{ padding: "40px" }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="ms-3 d-flex justify-content-center flex-column step-data w-100" style={{overflow:'auto'}}>
                              <div className="step-header justify-content-between">
                                <div className="d-flex">
                                  <Card.Title className="step-card-heading">
                                    {item?.title}
                                  </Card.Title>
                                  <>
                                    {item.step_status === "completed" ? (
                                      <span className="status-badge-completed">
                                        {/* Completed */}
                                        {t("badge.completed")}
                                      </span>
                                    ) : item.step_status === "in_progress" ? (
                                      <span
                                        className={
                                          convertTimeTakenToSeconds(
                                            item?.time_taken
                                          ) >
                                          convertCount2ToSeconds(
                                            item?.count2,
                                            item?.time_unit
                                          )
                                            ? "status-badge-red"
                                            : "status-badge-inprogress"
                                        }
                                      >
                                        {t("badge.inprogress")}
                                      </span>
                                    ) : item.step_status === "paused" ? (
                                      <span className={"status-badge-red"}>
                                        {t("badge.paused")}
                                      </span>
                                    ) : (
                                      // null
                                      <span className="status-badge-upcoming">
                                        {t("badge.future")}
                                        {/* Upcoming */}
                                      </span>
                                    )}
                                  </>
                                </div>

                                {item?.step_status === "in_progress" && (
                                  <MdKeyboardArrowDown
                                    style={{ cursor: "pointer" }}
                                    size={26}
                                  />
                                )}
                              </div>
                              <Card.Subtitle className="step-card-subtext">
                                {/* {item?.image ? (
                                <img
                                  height="24px"
                                  width="24px"
                                  style={{
                                    marginRight: "9px",
                                    borderRadius: "20px",
                                    objectFit: "cover",
                                    objectPosition: "top",
                                  }}
                                  // src={
                                  //   item?.image
                                  //     ? `${Assets_URL}/${item?.image}`
                                  //     : item?.assigned_to_image
                                  // }
                                  // src={

                                  //   item?.image?.startsWith("users/")
                                  //   ? Assets_URL + "/" + item?.image
                                  //   : item?.image?.startsWith("users/")
                                  //   ? Assets_URL + "/" + item.image
                                  //   : item?.image
                                  // }
                                  src={
                                    item?.image?.startsWith("users/")
                                      ? Assets_URL + "/" + item?.image
                                      : // : item?.image?.startsWith("users/")
                                        // ? Assets_URL + "/" + item.image
                                        item?.image
                                  }
                                  alt="img"
                                />
                              ) : (
                                <img
                                  height="24px"
                                  width="24px"
                                  style={{
                                    marginRight: "9px",
                                    borderRadius: "20px",
                                    objectFit: "cover",
                                    objectPosition: "top",
                                  }}
                                  // src={`${users?.participant_image}`}
                                  // src={
                                  //     users?.participant_image
                                  // }
                                  src={
                                    users?.image?.startsWith("users/")
                                      ? Assets_URL + "/" + users?.image
                                      : users?.image
                                  }
                                  alt="img"
                                />
                              )} */}
                                {item.assigned_team ? (
                                  <>
                                    {item?.assigned_team?.logo ? (
                                      <img
                                        height="24px"
                                        width="24px"
                                        style={{
                                          marginRight: "9px",
                                          borderRadius: "20px",
                                          objectFit: "cover",
                                          objectPosition: "top",
                                        }}
                                        src={
                                          Assets_URL +
                                          "/" +
                                          item?.assigned_team?.logo
                                        }
                                        alt={item?.assigned_team?.name}
                                      />
                                    ) : (
                                      <HiUserCircle
                                        style={{
                                          height: "24px",
                                          width: "24px",
                                        }}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {item?.image ? (
                                      <img
                                        height="24px"
                                        width="24px"
                                        style={{
                                          marginRight: "9px",
                                          borderRadius: "20px",
                                          objectFit: "cover",
                                          objectPosition: "top",
                                        }}
                                        src={
                                          item?.image?.startsWith("users/")
                                            ? Assets_URL + "/" + item?.image
                                            : // : item?.image?.startsWith("users/")
                                              // ? Assets_URL + "/" + item.image
                                              item?.image
                                        }
                                        // src={
                                        //     item?.assigned_to_image
                                        // }
                                        alt="img"
                                      />
                                    ) : (
                                      <img
                                        height="24px"
                                        width="24px"
                                        style={{
                                          marginRight: "9px",
                                          borderRadius: "20px",
                                          objectFit: "cover",
                                          objectPosition: "top",
                                        }}
                                        // src={`${users?.participant_image}`}
                                        // src={
                                        //   // users?.image
                                        //   //   ? Assets_URL + "/" + users.image
                                        //     // :
                                        //      users?.participant_image
                                        // }
                                        src={
                                          users?.image?.startsWith("users/")
                                            ? Assets_URL + "/" + users?.image
                                            : users?.image
                                        }
                                        alt="img"
                                      />
                                    )}
                                  </>
                                )}
                                {/* <span>
                                {item?.assigned_to_name ||
                                  `${users?.firstName} ${users?.lastName}`}
                              </span> */}
                                {item.assigned_team ? (
                                  <span>{item?.assigned_team?.name}</span>
                                ) : (
                                  <span>
                                    {item?.assigned_to_name ||
                                      `${users?.firstName} ${users?.lastName}`}
                                  </span>
                                )}
                              </Card.Subtitle>
                              <Card.Text className="step-card-content">
                                <img
                                  height="16px"
                                  width="16px"
                                  style={{ width: "auto", marginRight: "9px" }}
                                  src="/Assets/ion_time-outline.svg"
                                />
                                {item?.time_unit === "days" ? (
                                  <>
                                    {/* {item?.step_status === null
                                      ? initialDateEmail
                                      : item?.start_date} */}
                                    {
                                      item.step_status === null
                                        ? formatStepDate(item?.start_date)
                                        : // " " +
                                          // ` ${t("at")}` +
                                          // " " +
                                          // formatStepDate(item?.total_step_time)
                                          formatStepDate(item?.start_date)
                                      //  +
                                      // " " +
                                      // ` ${t("at")}` +
                                      // " " +
                                      // item.start_date?.split(
                                      //   "T"
                                      // )[0]
                                    }
                                  </>
                                ) : (
                                  <>
                                    {item?.step_status === null
                                      ? formatStepDate(item.start_date) +
                                        " " +
                                        ` ${t("at")}` +
                                        " " +
                                        // item?.step_time
                                        convertTo24HourFormat(
                                          item?.step_time,
                                          item?.time_unit
                                        )
                                      : formatStepDate(item.start_date) +
                                        " " +
                                        ` ${t("at")}` +
                                        " " +
                                        // item?.step_time
                                        convertTo24HourFormat(
                                          item?.step_time,
                                          item?.time_unit
                                        )}
                                  </>
                                )}
                              </Card.Text>
                              <Card.Text className="step-card-content">
                                <img
                                  height="16px"
                                  width="16px"
                                  style={{
                                    width: "auto",
                                    marginRight: "9px",
                                    // marginLeft: "1rem",
                                  }}
                                  src="/Assets/alarm-invite.svg"
                                />
                                {item?.step_status === null
                                  ? item.count2 +
                                    " " +
                                    t(`time_unit.${item.time_unit}`)
                                  : localizeTimeTakenActive(
                                      item?.time_taken?.replace("-", "")
                                    )}
                              </Card.Text>

                              {item?.step_status === "in_progress" && (
                                  <>
                                    {item?.editor_content &&
                                    item?.editor_type === "Editeur" &&
                                    item?.editor_content?.trim() !==
                                      "<html><head></head><body></body></html>" ? (
                                      <div
                                        style={{
                                          width: "100%", // Full width of the parent container
                                          maxWidth: "100%",
                                          height: item.editor_content.includes(
                                            "<img"
                                          )
                                            ? "350px"
                                            : "auto", // Conditional height
                                          overflowY:
                                            item.editor_content.includes("<img")
                                              ? "auto"
                                              : "visible", // Only scroll if images are present
                                          overflowX: "hidden",
                                          borderRadius: "10px",
                                          display: "flex",
                                          alignItems: "start",
                                          justifyContent: "center",
                                        }}
                                        className="customize-scroller"
                                      >
                                        <div className="scrollableContainer rendered-content-step-report">
                                          {/* <div
                                            dangerouslySetInnerHTML={{
                                              __html: (() => {
                                                if (
                                                  item.editor_content === null
                                                ) {
                                                  return null;
                                                }
                                                // Update all <img> tags to have style="width: 100%"
                                                const updatedContent =
                                                  item.editor_content.replace(
                                                    /<img(.*?)>/g,
                                                    '<img$1 style="width: 100%;" />'
                                                  );
                                                return updatedContent;
                                              })(),
                                            }}
                                          /> */}
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: sanitizeContent(
                                                item?.editor_content
                                              ),
                                            }}
                                          />

                                          {/* )} */}
                                        </div>
                                      </div>
                                    ) : item.url ? (
                                      <div
                                        style={{
                                          // width: "auto",
                                          // height: "500px",
                                          // background: "#eaeaef",
                                          borderRadius: "10px",
                                          // display: "flex",
                                          // alignItems: "center",
                                          // justifyContent: "center",
                                        }}
                                      >
                                        <iframe
                                          src={getYoutubeEmbedUrl(item?.url)}
                                          width="100%"
                                          height="630px"
                                        />
                                      </div>
                                    ) : item?.editor_type === "Excel" ? (
                                      <div
                                        style={{
                                          // width: "auto",
                                          // height: "500px",
                                          // background: "#eaeaef",
                                          borderRadius: "10px",
                                          // display: "flex",
                                          // alignItems: "center",
                                          // justifyContent: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "100%",
                                            height: "630px",
                                            overflowY: "auto",
                                            textAlign: "center",
                                          }}
                                        >
                                          {excelData && (
                                            <Spreadsheet data={excelData || null} />
                                          )}
                                        </div>
                                      </div>
                                    ) : item?.file && item?.editor_type === "File" ? <div
                                    style={{
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <iframe
                                      src={`${Assets_URL}/${item.file}#toolbar=0&view=fitH`}
                                      width="100%"
                                      height="630px"
                                    />
                                  </div> : item?.file && item?.editor_type === "Url" ? 
                                  <iframe
                                  src={`${Assets_URL}/${item.file}#toolbar=0&view=fitH`}
                                  width="100%"
                                  height="630px"
                                />  
                                : item?.file && item?.editor_type === "Video Report" ? 
                                <iframe
                                  src={`${Assets_URL}/${item.file}#toolbar=0&view=fitH`}
                                  width="100%"
                                  height="630px"
                                /> 
                                : item?.file && item?.editor_type === "Audio Report" ?  <iframe
                                src={`${Assets_URL}/${item.file}#toolbar=0&view=fitH`}
                                width="100%"
                                height="630px"
                              /> : item?.file && item?.editor_type === "Video" ? <iframe
                              src={`${Assets_URL}/${item.file}#toolbar=0&view=fitH`}
                              width="100%"
                              height="630px"
                            /> : item?.file && item?.editor_type === "Photo" ? <iframe
                            src={`${Assets_URL}/${item.file}#toolbar=0&view=fitH`}
                            width="100%"
                            height="630px"
                          />  : null }
                                  </>
                                )}
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )}
                    {doneMomentsModal === index && (
                      <>
                        <Modal
                          show={doneMomentsModal === index}
                          onHide={() => setDoneMomentsModal(false)}
                          size="xl"
                          centered
                          className="modal-fade report-steps-note-modal"
                        >
                          <Modal.Body
                            style={{
                              padding: "25px 53px",
                            }}
                          >
                            <div>
                              {item?.editor_type === "File" ||
                              item?.editor_type === "Video" ||
                              item?.editor_type === "Photo" ? (
                                <div>
                                  <iframe
                                    src={
                                      Assets_URL +
                                      "/" +
                                      (item?.file + "#toolbar=0&view=fitH")
                                    }
                                    width="100%"
                                    height="400px"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  />
                                </div>
                              ) : item?.editor_type === "Url" ? (
                                <>
                                  <iframe
                                    src={getYoutubeEmbedUrl(item?.url)}
                                    width="100%"
                                    height="500px"
                                  />
                                </>
                              ) : item.file && item?.editor_type === "Excel" ? (
                                <div
                                  style={{
                                    borderRadius: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "630px",
                                      overflowY: "auto",
                                      textAlign: "center",
                                    }}
                                  >
                                    {excelData ? (
                                      <Spreadsheet data={excelData} />
                                    ) : (
                                      <p>No Excel data available for this step.</p>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="rendered-content-report-step-chart"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item?.editor_content !== null ||
                                      item?.editor_content !== ""
                                        ? item?.editor_content
                                        : " ",
                                  }}
                                />
                              )}
                              <h3>{item?.title}</h3>
                            </div>
                            <div>
                              {item?.time_unit === "days" ? (
                                <>
                                  <Editor
                                    onBlur={(value) => {
                                      console.log("value", value);
                                    }}
                                    disabled
                                    // key={selectedIndex}
                                    apiKey={TINYMCEAPI}
                                    // value={inputData?.steps[selectedIndex].note}
                                    value={`
                                    <p>Campaign Name: ${item.email_campaigns?.campaign_name}</p>
                                    <p>Total Sendings: ${item.email_campaigns?.total_sendings}</p>
                                    <p>Total Recipients: ${item.email_campaigns?.total_recipients}</p>
                                    <p>Total Opens: ${item.email_campaigns?.total_opens}</p>
                                    <p>Total Clicks: ${item.email_campaigns?.total_clicks}</p>
                                    <p>Total Unsubscribes: ${item.email_campaigns?.total_unsubscribes}</p>
                                  `}
                                    init={{
                                      statusbar: false,
                                      branding: false,
                                      height: 400,
                                      menubar: true,
                                      language: "fr_FR",
                                      // language: "en_EN",
                                      plugins:
                                        "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                      toolbar:
                                        "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                      image_advtab: true,
                                      file_picker_types: "image",

                                      file_picker_callback: function (
                                        callback,
                                        value,
                                        meta
                                      ) {
                                        if (meta.filetype === "image") {
                                          const input =
                                            document.createElement("input");
                                          input.setAttribute("type", "file");
                                          input.setAttribute(
                                            "accept",
                                            "image/*"
                                          );

                                          input.onchange = function () {
                                            const file = input.files[0];
                                            const reader = new FileReader();

                                            reader.onload = function (e) {
                                              const img = new Image();
                                              img.src = e.target.result;

                                              img.onload = function () {
                                                const canvas =
                                                  document.createElement(
                                                    "canvas"
                                                  );
                                                const ctx =
                                                  canvas.getContext("2d");
                                                const maxWidth = 700;
                                                const maxHeight = 394;

                                                let newWidth = img.width;
                                                let newHeight = img.height;

                                                if (img.width > maxWidth) {
                                                  newWidth = maxWidth;
                                                  newHeight =
                                                    (img.height * maxWidth) /
                                                    img.width;
                                                }

                                                if (newHeight > maxHeight) {
                                                  newHeight = maxHeight;
                                                  newWidth =
                                                    (img.width * maxHeight) /
                                                    img.height;
                                                }

                                                canvas.width = newWidth;
                                                canvas.height = newHeight;

                                                ctx.drawImage(
                                                  img,
                                                  0,
                                                  0,
                                                  newWidth,
                                                  newHeight
                                                );

                                                const resizedImageData =
                                                  canvas.toDataURL(file.type);

                                                // Pass the resized image data to the callback function
                                                callback(resizedImageData, {
                                                  alt: file.name,
                                                });
                                              };

                                              img.src = e.target.result;
                                            };

                                            reader.readAsDataURL(file);
                                          };

                                          input.click();
                                        }
                                      },
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <Editor
                                    onBlur={(value) => {
                                      console.log("value", value);
                                    }}
                                    // disabled
                                    // key={selectedIndex}
                                    apiKey={TINYMCEAPI}
                                    // value={inputData?.steps[selectedIndex].note}
                                    value={item?.note}
                                    init={{
                                      statusbar: false,
                                      branding: false,
                                      height: 400,
                                      menubar: true,
                                      language: "fr_FR",
                                      // language: "en_EN",
                                      plugins:
                                        "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                      toolbar:
                                        "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                      image_advtab: true,
                                      file_picker_types: "image",

                                      file_picker_callback: function (
                                        callback,
                                        value,
                                        meta
                                      ) {
                                        if (meta.filetype === "image") {
                                          const input =
                                            document.createElement("input");
                                          input.setAttribute("type", "file");
                                          input.setAttribute(
                                            "accept",
                                            "image/*"
                                          );

                                          input.onchange = function () {
                                            const file = input.files[0];
                                            const reader = new FileReader();

                                            reader.onload = function (e) {
                                              const img = new Image();
                                              img.src = e.target.result;

                                              img.onload = function () {
                                                const canvas =
                                                  document.createElement(
                                                    "canvas"
                                                  );
                                                const ctx =
                                                  canvas.getContext("2d");
                                                const maxWidth = 700;
                                                const maxHeight = 394;

                                                let newWidth = img.width;
                                                let newHeight = img.height;

                                                if (img.width > maxWidth) {
                                                  newWidth = maxWidth;
                                                  newHeight =
                                                    (img.height * maxWidth) /
                                                    img.width;
                                                }

                                                if (newHeight > maxHeight) {
                                                  newHeight = maxHeight;
                                                  newWidth =
                                                    (img.width * maxHeight) /
                                                    img.height;
                                                }

                                                canvas.width = newWidth;
                                                canvas.height = newHeight;

                                                ctx.drawImage(
                                                  img,
                                                  0,
                                                  0,
                                                  newWidth,
                                                  newHeight
                                                );

                                                const resizedImageData =
                                                  canvas.toDataURL(file.type);

                                                // Pass the resized image data to the callback function
                                                callback(resizedImageData, {
                                                  alt: file.name,
                                                });
                                              };

                                              img.src = e.target.result;
                                            };

                                            reader.readAsDataURL(file);
                                          };

                                          input.click();
                                        }
                                      },
                                    }}
                                    // onEditorChange={(value) => {
                                    //   setNotes(value);
                                    // }}
                                    // onEditorChange={(value) => {
                                    //   if (value !== stepNotes[selectedIndex]) {
                                    //     setStepNotes((prev) => {
                                    //       const newStepNotes = [...prev];
                                    //       newStepNotes[selectedIndex] = value;
                                    //       return newStepNotes;
                                    //     });
                                    //   }
                                    // }}
                                    // onEditorChange={(value) => {
                                    //   setStepNotes((prev) => {
                                    //     let newStepNotes = [...prev];
                                    //     newStepNotes[selectedIndex] = value;
                                    //     return newStepNotes;
                                    //   });
                                    // }}
                                  />
                                </>
                              )}
                            </div>
                          </Modal.Body>
                          <Modal.Footer className="justify-content-center">
                            <Button
                              variant="danger"
                              onClick={() => setDoneMomentsModal(false)}
                            >
                              {t("buttons.cancel")}
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </>
                    )}
                  </>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {isModalOpen && (
        <Suspense fallback={<div>Loading...</div>}>
          <div className="new-meeting-modal">
            <LazyStepChart
              meetingId={meeting1?.id}
              id={stepId}
              show={isModalOpen}
              meeting1={meeting1}
              setId={setStepId}
              closeModal={handleCloseModal}
              key={`step-chart-${stepId}`}
              isDrop={isDrop}
              stepIndex={stepIndex}
            />
          </div>
        </Suspense>
      )}
    </Suspense>
  );
};

export default StepCard;
