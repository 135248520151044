import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../Components/Apicongfig";
import { askPermission } from "../Components/Utils/askPermission";

const DestinationsContext = createContext();

export const useDestinations = () => useContext(DestinationsContext);

export const DestinationsProvider = ({ children }) => {
  const [allDestinations, setAllDestinations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allCompletedDestinations, setAllCompletedDestinations] = useState([]);
  // console.log('allCompletedDestinations', allCompletedDestinations)
  const [showProgress,setShowProgress] = useState(false);
  const [progress,setProgress] = useState(0);
  const getDestinations = async () => {
    setProgress(0);
    setIsLoading(true);
      // Start a progress simulation
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 90) {
            clearInterval(interval); // Stop updating at 90%
            return 90; // Set to 90 before it completes
          }
          return prev + 10; // Increment progress by 10%
        });
      }, 200); // Update every 200ms
    try {
      const response = await axios.get(`${API_BASE_URL}/destinations`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      if (response.status) {
        setAllDestinations(response?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }finally{
      clearInterval(interval); // Clear the interval when done
      setProgress(100); // Set progress to 100% upon completion
      setIsLoading(false);


    }
  };

  const getAllCompletedDestinations = async () => {
    setProgress(0);
    setIsLoading(true);
      // Start a progress simulation
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 90) {
            clearInterval(interval); // Stop updating at 90%
            return 90; // Set to 90 before it completes
          }
          return prev + 10; // Increment progress by 10%
        });
      }, 200); // Update every 200ms
    try {
      const response = await axios.get(`${API_BASE_URL}/destinations`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      if (response.status) {
        setAllCompletedDestinations(response?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }finally{
      clearInterval(interval); // Clear the interval when done
      setProgress(100); // Set progress to 100% upon completion
      setIsLoading(false);
    }
  };
  const handleDelete = async (id) => {
    const permissionGranted = askPermission(
      "Êtes-vous sûr de vouloir supprimer cette réunion ?" ||
        "Are you sure you want to delete this Destinations?"
    );

    if (!permissionGranted) return;

    try {
      const response = await axios.delete(`${API_BASE_URL}/destinations/${id}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });

      if (response.status === 200) {
        toast.success("Réunion supprimée avec succès");
        getDestinations();
      } else {
        throw new Error("Échec de la suppression de la réunion");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <DestinationsContext.Provider
      value={{
        allDestinations,
        isLoading,
        getDestinations,
        allCompletedDestinations,
        getAllCompletedDestinations,
        handleDelete,
        progress
      }}
    >
      {children}
    </DestinationsContext.Provider>
  );
};
