import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../Apicongfig";
import Action from "./Action";
import ActionByDestination from "./ActionByDestination";
import { useTranslation } from "react-i18next";

const ActionTabs = () => {
  const [t] = useTranslation("global");
  const userId = parseInt(sessionStorage.getItem("user_id"));
  const [activeTab, setActiveTab] = useState("My Action");
  const [myActionCount, setMyActionCount] = useState(0);
  const [myDestinationCount, setMyDestinationCount] = useState(0);
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [destinationLoading, setDestinationLoading] = useState(false);
  const tabsRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [stepsData, setStepsData] = useState([]);
  const [destinationData, setDestinationData] = useState([]);
  const getActions = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/get-user-steps/${userId}`
      );
      if (response.status) {
        const data = response?.data?.data || [];
        setMyActionCount(data?.length);
        // setSteps(data);
        setStepsData(data);
      }
    } catch (error) {
      console.error("Error while fetching actions", error);
    } finally {
      setLoading(false);
    }
  };
  const getUserDestinations = async () => {
    setDestinationLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/get-user-destinations/${userId}`
      );
      if (response.status) {
        const data = response?.data?.data || [];
        setMyDestinationCount(data?.length);
        // setSteps(data);
        setDestinationData(data);
      }
    } catch (error) {
      console.error("Error while fetching actions", error);
    } finally {
      setDestinationLoading(false);
    }
  };

  useEffect(() => {
    getActions();
    getUserDestinations();
  }, []);

  const handleScroll = () => {
    if (tabsRef.current) {
      setIsSticky(window.scrollY > 170);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="destination-tabs-container container-fluid">
      <div ref={tabsRef} className={`tabs-header ${isSticky ? "sticky" : ""}`}>
        <h4 className="meeting-title">{t("action.title")}</h4>
        {/* <small style={{ padding: "15px 14px" }}>
          {t(
            "The destination is the end result you want your client to achieve."
          )}
        </small> */}
        <div className={`container-fluid ${isSticky ? "" : "py-1 px-3"}`}>
          <div className="row pr-3">
            <div
              className="col-lg-12 col-md-10 col-12 destination-tab-row tabs-destinations"
              style={{ borderBottom: "2px solid #F2F2F2" }}
            >
              <div className="tabs p-0">
                <div className="d-flex">
                  <button
                    className={`tab ${
                      activeTab === "My Action" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("My Action")}
                  >
                    {t("action.MyActionTab")}
                    <span
                      className={activeTab === "My Action" ? "future" : "draft"}
                    >
                      {myActionCount}
                    </span>
                  </button>
                  <button
                    className={`tab ${
                      activeTab === "Action by Destinations" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("Action by Destinations")}
                  >
                    {t("action.ActionByDestinationsTab")}
                    <span
                      className={
                        activeTab === "Action by Destinations"
                          ? "future"
                          : "draft"
                      }
                    >
                      {myDestinationCount}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content p-0">
        {activeTab === "My Action" && (
          <div>{<Action stepsData={stepsData} />}</div>
        )}
        {activeTab === "Action by Destinations" && (
          <div>{<ActionByDestination stepsData={destinationData} />}</div>
        )}
      </div>
    </div>
  );
};

export default ActionTabs;
