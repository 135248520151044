import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, Container, Row, Col, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormContext } from "../../../../../context/CreateMeetingContext";
import { toast } from "react-toastify";
import { getOptions } from "../../../../Utils/MeetingFunctions";
import { useMeetings } from "../../../../../context/MeetingsContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const CustomComponent = ({ setActiveTab }) => {
  const {
    formState,
    setFormState,
    handleInputBlur,
    meeting,
    getMeeting,
    checkId,
    isUpdated,
    isDuplicate,
    handleCloseModal,
    recurrentMeetingAPI,
    setUpdatedButton,
    updatedButton,
    setQuit,
    quit,
    setSelectedTab,
  } = useFormContext();
  const { getMeetings } = useMeetings();
  const navigate = useNavigate();
  // const [selectedTab, setSelectedTab] = useState("scratch");
  const [t] = useTranslation("global");
  const [errors, setErrors] = useState({});
  const user = JSON.parse(sessionStorage.getItem("user"));
  const roleId = parseInt(user?.role_id);
  console.log("roleId", roleId);
  const options = getOptions(t, roleId);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingQuit, setLoadingQuit] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [userTime, setUserTime] = useState(null);
  const [timeDifference, setTimeDifference] = useState(null);
  console.log("timeDifference", timeDifference);
  console.log("userTime", userTime);

  // useLayoutEffect(() => {
  //   if (checkId) {
  //     getMeeting(checkId);
  //   }
  // }, [checkId]);
  useEffect(() => {
    if (checkId) {
      // Show loading indicator before making the API call
      setIsLoading(true);

      // Call the API and fetch meeting data
      const fetchData = async () => {
        try {
          await getMeeting(checkId);
        } catch (error) {
          toast.error("Error fetching meeting data");
        } finally {
          // Hide the loading indicator once the API call is complete
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [checkId]);

  useEffect(() => {
    if (meeting) {
      setFormState((prevState) => ({
        ...prevState,
        type: meeting?.type || "",
      }));
    }
  }, [meeting, setFormState]);

  useEffect(() => {
    if (formState.type) {
      const selectedIdx = options.findIndex(
        (item) => item.title === formState.type
      );
      setSelectedCard(selectedIdx);
    }
  }, [formState.type, options]);

  const handleCardClick = (idx, title) => {
    setSelectedCard(idx);
    setFormState((prevState) => ({
      ...prevState,
      type: title,
    }));
  };
  const validateForm = () => {
    let validationErrors = {};

    if (!formState.type) {
      validationErrors.type = t("meeting.formState.type");
      toast.error(validationErrors.type);
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
  const handleSaveAndContinue = async () => {
    if (validateForm()) {
      setLoading(true);

      await new Promise((resolve) => setTimeout(resolve, 0));

      try {
        const options = { updatedButton: false, quitAndUpdate: false };
        const newformState = { ...meeting };
        await handleInputBlur(newformState, options);

        setActiveTab("tab3");
        setSelectedTab("tab3");
        // navigate(`/invite/${checkId}`, { state: {from: "meeting" } });
      } catch (error) {
        toast.error("Error occurred");
      } finally {
        setLoading(false);
      }
    }
  };

  // Set the user's current time when the component mounts
  useEffect(() => {
    const currentTime = moment().startOf("minute"); // Normalize to remove seconds
    setUserTime(currentTime);
  }, []);

  // Calculate the time difference when userTime or meeting details change
  useEffect(() => {
    if (userTime && meeting?.date && meeting?.start_time) {
      const meetingTime = moment(
        `${meeting?.date} ${meeting?.start_time}`,
        "YYYY-MM-DD HH:mm:ss"
      ).startOf("minute"); // Normalize meeting time to remove seconds

      if (meetingTime.isValid()) {
        const diff = meetingTime.diff(userTime, "minutes");
        setTimeDifference(diff);
      } else {
        console.error("Invalid meeting date or time format.");
        setTimeDifference(null); // Reset to null if invalid
      }
    }
  }, [userTime, meeting]);

  const handleSaveAndQuit = async () => {
    if (
      formState?.prise_de_notes == "Automatic" &&
      meeting?.location === "Google Meet" &&
      timeDifference !== null &&
      timeDifference < 30
    ) {
      toast.warning(t("UpmeetWarning"));
      return;
    }
    if (validateForm()) {
      setLoadingQuit(true); // Show loader
      await new Promise((resolve) => setTimeout(resolve, 0));
      try {
        const options = { updatedButton: true, quitAndUpdate: true };
        const newformState = { ...meeting };
        await handleInputBlur(newformState, options);

        recurrentMeetingAPI();
        handleCloseModal();
        navigate(`/invite/${checkId}`, { state: { from: "meeting" } });
      } catch (error) {
        // Handle error (if any)
        toast.error("Error occurred");
      } finally {
        setLoadingQuit(false); // Hide loader
        setQuit(false);
        setUpdatedButton(false);
        await getMeetings();
      }
    }
  };
  const sortedOptions = options?.sort((a, b) => a.label.localeCompare(b.label));

  if (isLoading) {
    // Show a loading spinner while waiting for the API response
    return (
      <Spinner
        animation="border"
        role="status"
        className="center-spinner"
      ></Spinner>
    );
  }

  return (
    <div className="col-md-12 mt-1 p-4 modal-height">
      <Row className="g-3 mb-3">
        {meeting?.type === "Strategy" ? (
          <Col
            xs={6}
            sm={4}
            md={3}
            lg={2}
            // key={idx}
            className="d-flex justify-content-center"
          >
            <Card
              className="text-center shadow-sm"
              style={{
                borderRadius: "10px",
                height: "138px",
                width: "100%",
                maxWidth: "138px", // Set max width
                background: "none",
                cursor: "pointer",
                border: "2px solid blue",
                transform: "scale(1.1)", // Scale the card size up
                transition: "transform 0.2s ease-in-out", // Smooth transition on hover
              }}
              // onClick={() => handleCardClick(idx, item.title)}
            >
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                {/* {item.svg} */}
                <svg
                  height="36"
                  width="37"
                  viewBox="0 0 1024 1024"
                  fill="#000000"
                  class="icon"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M208.508 583.99c-4.406 0-8.078-3.578-8.078-7.998 0-4.422 3.5-8 7.92-8h0.156c4.42 0 8 3.578 8 8a7.994 7.994 0 0 1-7.998 7.998z"
                      fill=""
                    ></path>
                    <path
                      d="M999.936 767.966H56.058c-4.422 0-8-3.578-8-8V152.046c0-4.422 3.578-8 8-8h943.876c4.422 0 8 3.578 8 8v607.92a7.992 7.992 0 0 1-7.998 8z m-935.878-15.998h927.88V160.044H64.058v591.924z"
                      fill=""
                    ></path>
                    <path
                      d="M775.964 416.012c-22.058 0-39.994-17.944-39.994-39.994 0-7.016 1.844-13.936 5.342-20.006a8.004 8.004 0 0 1 13.876 7.984 24.02 24.02 0 0 0-3.218 12.022c0 13.232 10.764 23.996 23.996 23.996s23.998-10.764 23.998-23.996a24.12 24.12 0 0 0-3.204-11.966 8 8 0 0 1 2.984-10.914c3.78-2.202 8.702-0.882 10.92 2.976a40.19 40.19 0 0 1 5.296 19.904c0 22.05-17.934 39.994-39.996 39.994z"
                      fill=""
                    ></path>
                    <path
                      d="M775.964 384.016a7.992 7.992 0 0 1-7.998-7.998V216.04c0-4.422 3.576-8 7.998-8s8 3.578 8 8v159.978a7.994 7.994 0 0 1-8 7.998z"
                      fill=""
                    ></path>
                    <path
                      d="M855.956 272.032h-63.992a7.994 7.994 0 0 1-7.998-8 7.994 7.994 0 0 1 7.998-7.998h55.994v-15.998h-55.994a7.994 7.994 0 0 1-7.998-8 7.994 7.994 0 0 1 7.998-7.998h63.992a7.994 7.994 0 0 1 7.998 7.998v31.996c0 4.422-3.578 8-7.998 8zM919.948 304.028h-79.99c-4.422 0-8-3.578-8-8a7.994 7.994 0 0 1 8-7.998h79.99a7.994 7.994 0 0 1 7.998 7.998c0 4.422-3.578 8-7.998 8zM919.948 272.032h-31.996c-4.422 0-8-3.578-8-8a7.994 7.994 0 0 1 8-7.998h31.996a7.994 7.994 0 0 1 7.998 7.998c0 4.422-3.578 8-7.998 8z"
                      fill=""
                    ></path>
                    <path
                      d="M919.948 304.028a7.976 7.976 0 0 1-5.656-2.344l-15.998-15.998a7.996 7.996 0 0 1 0-11.31l15.998-15.998a7.996 7.996 0 1 1 11.31 11.31l-10.342 10.342 10.342 10.344a7.996 7.996 0 0 1-5.654 13.654zM208.04 623.984c-26.464 0-47.994-21.528-47.994-47.992 0-26.466 21.53-47.994 47.994-47.994s47.994 21.528 47.994 47.994-21.53 47.992-47.994 47.992z m0-79.988c-17.654 0-31.996 14.342-31.996 31.996 0 17.652 14.342 31.994 31.996 31.994 17.638 0 31.996-14.342 31.996-31.994 0-17.654-14.358-31.996-31.996-31.996zM256.034 535.996a7.95 7.95 0 0 1-5.906-2.61 7.982 7.982 0 0 1 0.516-11.294c184.524-168.486 459.518-138.326 462.268-138.022a7.99 7.99 0 0 1 7 8.876c-0.516 4.366-4.156 7.498-8.876 7.014-2.624-0.32-270.542-29.536-449.612 133.944a7.958 7.958 0 0 1-5.39 2.092z"
                      fill=""
                    ></path>
                    <path
                      d="M679.976 416.012a8.008 8.008 0 0 1-7.154-4.42 8.006 8.006 0 0 1 3.578-10.734l31.996-15.998a8.004 8.004 0 0 1 10.732 3.578 8.004 8.004 0 0 1-3.578 10.732l-31.996 15.998a8.066 8.066 0 0 1-3.578 0.844z"
                      fill=""
                    ></path>
                    <path
                      d="M711.972 400.014c-1.204 0-2.42-0.274-3.576-0.844l-31.996-15.998a8.004 8.004 0 0 1-3.578-10.732c1.984-3.954 6.78-5.514 10.732-3.578l31.996 15.998a8.006 8.006 0 0 1 3.578 10.734 8.008 8.008 0 0 1-7.156 4.42zM496.002 296.028a7.976 7.976 0 0 1-5.656-2.344l-31.996-31.996a8 8 0 0 1 11.312-11.31l31.996 31.996a7.996 7.996 0 0 1-5.656 13.654z"
                      fill=""
                    ></path>
                    <path
                      d="M464.006 296.028a7.996 7.996 0 0 1-5.656-13.654l31.996-31.996a8 8 0 0 1 11.312 11.31l-31.996 31.996a7.976 7.976 0 0 1-5.656 2.344z"
                      fill=""
                    ></path>
                    <path
                      d="M480.004 320.026c-26.464 0-47.994-21.53-47.994-47.994s21.53-47.994 47.994-47.994 47.994 21.53 47.994 47.994-21.53 47.994-47.994 47.994z m0-79.99c-17.638 0-31.996 14.35-31.996 31.996s14.358 31.996 31.996 31.996c17.654 0 31.996-14.35 31.996-31.996s-14.342-31.996-31.996-31.996zM264.016 368.018a7.986 7.986 0 0 1-5.656-2.342l-47.994-47.994a7.996 7.996 0 1 1 11.31-11.31l47.994 47.992a8 8 0 0 1-5.654 13.654z"
                      fill=""
                    ></path>
                    <path
                      d="M216.022 368.018a8 8 0 0 1-5.656-13.654l47.994-47.992a8 8 0 0 1 11.312 11.31l-47.994 47.994a7.982 7.982 0 0 1-5.656 2.342zM447.992 647.982a7.976 7.976 0 0 1-5.656-2.344l-31.996-31.996a8 8 0 0 1 11.312-11.31l31.996 31.996a7.996 7.996 0 0 1-5.656 13.654z"
                      fill=""
                    ></path>
                    <path
                      d="M415.996 647.982a7.996 7.996 0 0 1-5.656-13.654l31.996-31.996a8 8 0 0 1 11.312 11.31l-31.996 31.996a7.968 7.968 0 0 1-5.656 2.344z"
                      fill=""
                    ></path>
                    <path
                      d="M432.01 671.978c-26.464 0-47.994-21.53-47.994-47.994s21.53-47.992 47.994-47.992 47.994 21.528 47.994 47.992-21.53 47.994-47.994 47.994z m0-79.99c-17.654 0-31.996 14.344-31.996 31.996 0 17.654 14.342 31.996 31.996 31.996 17.638 0 31.996-14.342 31.996-31.996 0-17.652-14.358-31.996-31.996-31.996zM647.982 591.988a7.976 7.976 0 0 1-5.656-2.344l-47.994-47.992a8 8 0 1 1 11.312-11.312l47.992 47.994a7.996 7.996 0 0 1-5.654 13.654z"
                      fill=""
                    ></path>
                    <path
                      d="M599.988 591.988a7.996 7.996 0 0 1-5.656-13.654l47.994-47.994a8 8 0 0 1 11.31 11.312l-47.992 47.992a7.964 7.964 0 0 1-5.656 2.344zM831.958 663.98a7.976 7.976 0 0 1-5.654-2.344l-31.996-31.996a7.996 7.996 0 1 1 11.31-11.31l31.996 31.994a8 8 0 0 1-5.656 13.656z"
                      fill=""
                    ></path>
                    <path
                      d="M799.962 663.98a8 8 0 0 1-5.656-13.656l31.996-31.994a7.996 7.996 0 1 1 11.31 11.31l-31.996 31.996a7.964 7.964 0 0 1-5.654 2.344z"
                      fill=""
                    ></path>
                    <path
                      d="M815.96 687.976c-26.466 0-47.994-21.528-47.994-47.994 0-26.464 21.528-47.994 47.994-47.994 26.464 0 47.992 21.53 47.992 47.994 0.002 26.468-21.526 47.994-47.992 47.994z m0-79.99c-17.638 0-31.996 14.342-31.996 31.996s14.358 31.996 31.996 31.996c17.636 0 31.996-14.342 31.996-31.996s-14.358-31.996-31.996-31.996z"
                      fill=""
                    ></path>
                    <path
                      d="M527.998 96.052h-31.996c-4.422 0-8-3.576-8-7.998V56.058a7.994 7.994 0 0 1 8-7.998h31.996a7.994 7.994 0 0 1 7.998 7.998v31.996a7.994 7.994 0 0 1-7.998 7.998zM504 80.056h15.998v-15.998H504v15.998z"
                      fill=""
                    ></path>
                    <path
                      d="M1015.934 160.044H8.064a7.994 7.994 0 0 1-7.998-7.998V88.054a7.994 7.994 0 0 1 7.998-7.998h1007.87c4.422 0 8 3.576 8 7.998v63.992a7.994 7.994 0 0 1-8 7.998zM16.064 144.046h991.872V96.052H16.064v47.994z"
                      fill=""
                    ></path>
                    <path
                      d="M967.942 128.048H56.058c-4.422 0-8-3.576-8-7.998s3.578-8 8-8H967.94c4.42 0 7.998 3.578 7.998 8s-3.576 7.998-7.996 7.998z"
                      fill=""
                    ></path>
                    <path
                      d="M727.972 975.94a8.176 8.176 0 0 1-3.578-0.844l-215.972-107.986a8.002 8.002 0 0 1-3.578-10.732 8.008 8.008 0 0 1 10.734-3.578l215.97 107.986a8.002 8.002 0 0 1 3.578 10.732 8.02 8.02 0 0 1-7.154 4.422zM791.962 975.94a8.16 8.16 0 0 1-3.576-0.844l-279.964-139.982a8 8 0 0 1-3.578-10.732 8.008 8.008 0 0 1 10.734-3.578l279.962 139.982c3.954 1.968 5.544 6.782 3.578 10.732a8.02 8.02 0 0 1-7.156 4.422z"
                      fill=""
                    ></path>
                    <path
                      d="M791.962 975.94h-63.99c-4.422 0-8-3.578-8-7.998 0-4.422 3.578-8 8-8h63.99c4.422 0 8 3.578 8 8a7.994 7.994 0 0 1-8 7.998z"
                      fill=""
                    ></path>
                    <path
                      d="M296.042 975.94a8.02 8.02 0 0 1-7.17-4.422 8 8 0 0 1 3.578-10.732L508.422 852.8a8.004 8.004 0 0 1 10.732 3.578 7.988 7.988 0 0 1-3.576 10.732l-215.972 107.986a8.072 8.072 0 0 1-3.564 0.844zM232.05 975.94a8.024 8.024 0 0 1-7.17-4.422 8 8 0 0 1 3.578-10.732l279.964-139.982a8.004 8.004 0 0 1 10.732 3.578 7.988 7.988 0 0 1-3.576 10.732l-279.964 139.982a8.072 8.072 0 0 1-3.564 0.844z"
                      fill=""
                    ></path>
                    <path
                      d="M296.028 975.94h-63.992a7.994 7.994 0 0 1-7.998-7.998c0-4.422 3.578-8 7.998-8h63.992c4.422 0 8 3.578 8 8a7.996 7.996 0 0 1-8 7.998z"
                      fill=""
                    ></path>
                    <path
                      d="M496.002 843.958c-4.422 0-8-3.578-8-8v-75.99a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998v75.99c0 4.42-3.578 8-7.998 8z"
                      fill=""
                    ></path>
                    <path
                      d="M527.998 843.958c-4.422 0-8-3.578-8-8v-75.99a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998v75.99c0 4.42-3.578 8-7.998 8z"
                      fill=""
                    ></path>
                    <path
                      d="M527.998 975.94c-4.422 0-8-3.578-8-7.998v-99.988a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998v99.988a7.994 7.994 0 0 1-7.998 7.998z"
                      fill=""
                    ></path>
                    <path
                      d="M527.998 975.94h-31.996c-4.422 0-8-3.578-8-7.998 0-4.422 3.578-8 8-8h31.996a7.994 7.994 0 0 1 7.998 8 7.994 7.994 0 0 1-7.998 7.998z"
                      fill=""
                    ></path>
                    <path
                      d="M496.002 975.94c-4.422 0-8-3.578-8-7.998v-99.988a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998v99.988a7.994 7.994 0 0 1-7.998 7.998z"
                      fill=""
                    ></path>
                  </g>
                </svg>
                <Card.Title
                  className="mt-2 solutioncards"
                  style={{ textAlign: "center", wordBreak: "break-word" }}
                >
                  {t("Strategy")}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          sortedOptions?.map((item, idx) => (
            <Col
              xs={6}
              sm={4}
              md={3}
              lg={2}
              key={idx}
              className="d-flex justify-content-center"
            >
              <Card
                className="text-center shadow-sm"
                style={{
                  borderRadius: "10px",
                  height: "138px",
                  width: "100%",
                  maxWidth: "138px", // Set max width
                  background: "none",
                  cursor: "pointer",
                  border: selectedCard === idx ? "2px solid blue" : "none",
                  transform: "scale(1.1)", // Scale the card size up
                  transition: "transform 0.2s ease-in-out", // Smooth transition on hover
                }}
                onClick={() => handleCardClick(idx, item.title)}
              >
                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                  {item.svg}
                  <Card.Title
                    className="mt-2 solutioncards"
                    style={{ textAlign: "center", wordBreak: "break-word" }}
                  >
                    {item.label}
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>
      <div
        className={`modal-footer d-flex justify-content-end modal-save-button gap-4`}
      >
        {(isUpdated || isDuplicate) && (
          <Button
            variant="danger"
            // className="btn "
            onClick={handleSaveAndQuit}
            disabled={loadingQuit}
            style={{ padding: "9px" }}
          >
            {loadingQuit ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "white",
                    margin: "5px 82px",
                  }}
                />
              </>
            ) : (
              <>
                &nbsp;{t("meeting.formState.Save and Quit")}
                {/* <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.4707 8.52991C13.397 8.46125 13.3379 8.37845 13.2969 8.28645C13.2559 8.19445 13.2338 8.09513 13.2321 7.99443C13.2303 7.89373 13.2488 7.7937 13.2865 7.70031C13.3243 7.60692 13.3804 7.52209 13.4516 7.45087C13.5228 7.37965 13.6077 7.32351 13.7011 7.28579C13.7945 7.24807 13.8945 7.22954 13.9952 7.23132C14.0959 7.23309 14.1952 7.25514 14.2872 7.29613C14.3792 7.33712 14.462 7.39622 14.5307 7.46991L18.5307 11.4699C18.6711 11.6105 18.75 11.8012 18.75 11.9999C18.75 12.1987 18.6711 12.3893 18.5307 12.5299L14.5307 16.5299C14.462 16.6036 14.3792 16.6627 14.2872 16.7037C14.1952 16.7447 14.0959 16.7667 13.9952 16.7685C13.8945 16.7703 13.7945 16.7518 13.7011 16.714C13.6077 16.6763 13.5228 16.6202 13.4516 16.549C13.3804 16.4778 13.3243 16.393 13.2865 16.2996C13.2488 16.2062 13.2303 16.1062 13.2321 16.0055C13.2338 15.9048 13.2559 15.8055 13.2969 15.7135C13.3379 15.6215 13.397 15.5387 13.4707 15.4699L16.1907 12.7499H6C5.80109 12.7499 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 11.9999C5.25 11.801 5.32902 11.6103 5.46967 11.4696C5.61032 11.329 5.80109 11.2499 6 11.2499H16.1907L13.4707 8.52991Z"
                      fill="white"
                    />
                  </svg>
                </span> */}
              </>
            )}
          </Button>
        )}
        <button
          className="btn moment-btn"
          onClick={handleSaveAndContinue}
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "white",
                  margin: "5px 82px",
                }}
              />
            </>
          ) : (
            <>
              &nbsp;{t("meeting.formState.Save and Continue")}
              <span className="ms-2">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4707 8.52991C13.397 8.46125 13.3379 8.37845 13.2969 8.28645C13.2559 8.19445 13.2338 8.09513 13.2321 7.99443C13.2303 7.89373 13.2488 7.7937 13.2865 7.70031C13.3243 7.60692 13.3804 7.52209 13.4516 7.45087C13.5228 7.37965 13.6077 7.32351 13.7011 7.28579C13.7945 7.24807 13.8945 7.22954 13.9952 7.23132C14.0959 7.23309 14.1952 7.25514 14.2872 7.29613C14.3792 7.33712 14.462 7.39622 14.5307 7.46991L18.5307 11.4699C18.6711 11.6105 18.75 11.8012 18.75 11.9999C18.75 12.1987 18.6711 12.3893 18.5307 12.5299L14.5307 16.5299C14.462 16.6036 14.3792 16.6627 14.2872 16.7037C14.1952 16.7447 14.0959 16.7667 13.9952 16.7685C13.8945 16.7703 13.7945 16.7518 13.7011 16.714C13.6077 16.6763 13.5228 16.6202 13.4516 16.5489C13.3804 16.4777 13.3243 16.3929 13.2865 16.2995C13.2488 16.2061 13.2303 16.1061 13.2321 16.0054C13.2338 15.9047 13.2559 15.8054 13.2969 15.7134C13.3379 15.6214 13.397 15.5386 13.4707 15.4699L16.1907 12.7499H6.50066C6.30175 12.7499 6.11098 12.6709 5.97033 12.5302C5.82968 12.3896 5.75066 12.1988 5.75066 11.9999C5.75066 11.801 5.82968 11.6102 5.97033 11.4696C6.11098 11.3289 6.30175 11.2499 6.50066 11.2499H16.1907L13.4707 8.52991Z"
                    fill="white"
                  />
                </svg>
              </span>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default CustomComponent;
