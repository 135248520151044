import React, { useEffect, useRef, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  FaLinkedin,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaBehance,
  FaDiscord,
  FaDribbble,
  FaGoogle,
  FaLine,
  FaFacebookMessenger,
  FaPinterest,
  FaQq,
  FaReddit,
  FaSkype,
  FaSlack,
  FaSnapchat,
  FaSpotify,
  FaMicrosoft,
  FaTelegram,
  FaTiktok,
  FaCloudDownloadAlt,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ReactPlayer from "react-player";
import VisibilityMessageModal from "./VisibilityMessageModal";
// import ConfirmationModal from "../../Utils/ConfirmationModal";

const getPlatformIcon = (platform) => {
  switch (platform) {
    case "LinkedIn":
      return (
        <FaLinkedin className="platform-icon" style={{ color: "#0A66C2" }} />
      );
    case "Facebook":
      return (
        <FaFacebook className="platform-icon" style={{ color: "#1877F2" }} />
      );
    case "Twitter":
      return (
        <FaTwitter className="platform-icon" style={{ color: "#1DA1F2" }} />
      );
    case "Whatsapp":
      return (
        <FaWhatsapp className="platform-icon" style={{ color: "green" }} />
      );
    case "Youtube":
      return <FaYoutube className="platform-icon" style={{ color: "red" }} />;
    case "Instagram":
      return (
        <FaInstagram className="platform-icon" style={{ color: "#C13584" }} />
      );
    case "Behance":
      return (
        <FaBehance className="platform-icon" style={{ color: "#1769FF" }} />
      );
    case "Discord":
      return (
        <FaDiscord className="platform-icon" style={{ color: "#7289DA" }} />
      );
    case "Dribble":
      return (
        <FaDribbble className="platform-icon" style={{ color: "#EA4C89" }} />
      );
    case "Google My Business":
      return (
        <FaGoogle className="platform-icon" style={{ color: "#4285F4" }} />
      );
    case "Line":
      return <FaLine className="platform-icon" style={{ color: "#00C300" }} />;
    case "Messenger":
      return (
        <FaFacebookMessenger
          className="platform-icon"
          style={{ color: "#0084FF" }}
        />
      );
    case "Pinterest":
      return (
        <FaPinterest className="platform-icon" style={{ color: "#E60023" }} />
      );
    case "QQ":
      return <FaQq className="platform-icon" style={{ color: "#00B2A9" }} />;
    case "Reddit":
      return (
        <FaReddit className="platform-icon" style={{ color: "#FF4500" }} />
      );
    case "Skype":
      return <FaSkype className="platform-icon" style={{ color: "#00AFF0" }} />;
    case "Slack":
      return <FaSlack className="platform-icon" style={{ color: "#4A154B" }} />;
    case "Snapchat":
      return (
        <FaSnapchat className="platform-icon" style={{ color: "#FFFC00" }} />
      );
    case "Spotify":
      return (
        <FaSpotify className="platform-icon" style={{ color: "#1DB954" }} />
      );
    case "Microsoft Teams":
      return (
        <FaMicrosoft className="platform-icon" style={{ color: "#6264A7" }} />
      );
    case "Telegram":
      return (
        <FaTelegram className="platform-icon" style={{ color: "#0088CC" }} />
      );
    case "Tiktok":
      return (
        <FaTiktok className="platform-icon" style={{ color: "#000000" }} />
      );
    default:
      return null;
  }
};
const ProfileInvitePage = () => {
  const [t] = useTranslation("global");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { nick_name } = useParams();
  console.log("nick_name", nick_name);
  // const pageId = `${userId}/${meetId}/${objective}`;
  const [pageViews, setPageViews] = useState(0);

  useEffect(() => {
    const incrementPageView = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/user-page-views/${nick_name}`
        );
        console.log("response views", response);
        if (response.status === 200) {
          setPageViews(response?.data?.views_count);
        }
      } catch (error) {
        console.error("Error incrementing page view:", error);
      }
    };
    incrementPageView();
  }, [nick_name]);

  const [visibilityMessage, setVisibilityMessage] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      setLoading(true);

      // Extract the part after the underscore
      // const parts = nick_name?.split('_');
      // const suffix = parts[parts?.length - 1];
      // console.log('suffix',suffix)
      // const isNumeric = /^\d+$/.test(suffix);
      const apiUrl = `${API_BASE_URL}/public-user/${nick_name}`;
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        console.log("response of public user api-->", response?.data?.data);
        if (response.status === 200) {
          setUser(response.data?.data);
        }
      } catch (error) {
        setLoading(false);
        console.log("error while fetching user", error);
        setVisibilityMessage(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };

    getUser();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (visibilityMessage) {
      setIsModalOpen(true);
    }
  }, [visibilityMessage]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // useEffect(() => {
  //   console.log("isModalOpen:", isModalOpen);
  // }, [isModalOpen]);

  const visitingCardUrl =
    window.location.origin + "/" + user?.nick_name + "/" + user?.uuid;

  const formatUrl = (url) => {
    if (!url) return "";
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`; // Default to https if no scheme is provided
    }
    return url;
  };
  const handleOpen = (link) => {
    window.open(formatUrl(link), "_blank");
  };
  const [dropdownVisible, setDropdownVisible] = useState(
    Array(user?.websites?.length).fill(false)
  );
  const dropdownRefs = useRef([]);

  const toggleDropdown = (index) => {
    setDropdownVisible((prev) => {
      const newDropdownVisible = [...prev];
      newDropdownVisible[index] = !newDropdownVisible[index];
      return newDropdownVisible;
    });
  };
  useEffect(() => {
    user?.websites?.forEach((item, index) => {
      const el = dropdownRefs.current[index];
      if (el) {
        if (dropdownVisible[index]) {
          el.style.display = "block";
          requestAnimationFrame(() => {
            el.classList.add("show");
          });
        } else {
          el.classList.remove("show");
          el.addEventListener(
            "transitionend",
            () => {
              el.style.display = "none";
            },
            { once: true }
          );
        }
      }
    });
  }, [dropdownVisible, user]);

  //For Affiliation
  const [dropdownVisible1, setDropdownVisible1] = useState(
    Array(user?.affiliation_links?.length).fill(false)
  );
  const dropdownRefs1 = useRef([]);

  const toggleDropdown1 = (index) => {
    setDropdownVisible1((prev) => {
      const newDropdownVisible = [...prev];
      newDropdownVisible[index] = !newDropdownVisible[index];
      return newDropdownVisible;
    });
  };
  useEffect(() => {
    user?.affiliation_links?.forEach((item, index) => {
      const el = dropdownRefs1.current[index];
      if (el) {
        if (dropdownVisible1[index]) {
          el.style.display = "block";
          requestAnimationFrame(() => {
            el.classList.add("show");
          });
        } else {
          el.classList.remove("show");
          el.addEventListener(
            "transitionend",
            () => {
              el.style.display = "none";
            },
            { once: true }
          );
        }
      }
    });
  }, [dropdownVisible1, user]);
  //For Products
  const [dropdownVisible2, setDropdownVisible2] = useState(
    Array(user?.products?.length).fill(false)
  );
  const dropdownRefs2 = useRef([]);

  const toggleDropdown2 = (index) => {
    setDropdownVisible2((prev) => {
      const newDropdownVisible = [...prev];
      newDropdownVisible[index] = !newDropdownVisible[index];
      return newDropdownVisible;
    });
  };
  useEffect(() => {
    user?.products?.forEach((item, index) => {
      const el = dropdownRefs2.current[index];
      if (el) {
        if (dropdownVisible2[index]) {
          el.style.display = "block";
          requestAnimationFrame(() => {
            el.classList.add("show");
          });
        } else {
          el.classList.remove("show");
          el.addEventListener(
            "transitionend",
            () => {
              el.style.display = "none";
            },
            { once: true }
          );
        }
      }
    });
  }, [dropdownVisible2, user]);

  const handleNavigate = () => {
    window.location.href =
      "https://calendly.com/tektime/tektime-qu-est-ce-que-c-est";
  };
  function downloadVCard() {
    const {
      name,
      last_name,
      email,
      post,
      social_links,
      websites,
      full_name,
      enterprise,
      bio,
    } = user;

    // Start building the vCard
    let vCardData = `
  BEGIN:VCARD
  VERSION:3.0
  FN:${full_name}
  ORG:${enterprise?.name}
  TITLE:${post || "N/A"}
  EMAIL:${email || "N/A"}
  INFO:${bio}
  `;

    // Add websites
    websites.forEach((website) => {
      vCardData += `URL;TYPE=WORK:${website.link}\n`;
    });

    // Add social links
    social_links.forEach((link) => {
      vCardData += `URL;TYPE=SOCIAL:${link.link}\n`;
    });

    vCardData += `END:VCARD`;

    // Create and download the vCard
    const blob = new Blob([vCardData], { type: "text/vcard" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${name}_${last_name}.vcf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const blockedDomains = [
    // Music platforms
    "spotify.com",
    "open.spotify.com",
    "apple.com/music",
    "soundcloud.com",
    "youtube.com",
    "youtubemusic.com",

    // Social media platforms
    "facebook.com",
    "instagram.com",
    "twitter.com",
    "tiktok.com",
    "reddit.com",
    "pinterest.com",
    "linkedin.com",
    "discord.com",

    // Search engines
    "google.com",
    "bing.com",
    "duckduckgo.com",

    // Email providers
    "gmail.com",
    "outlook.com",
    "yahoo.com",
    "hotmail.com",

    // Productivity platforms
    "dropbox.com",
    "drive.google.com",
    "onedrive.com",
    "box.com",

    // News platforms
    "nytimes.com",
    "bbc.com",
    "cnn.com",
    "foxnews.com",

    // E-commerce platforms
    "amazon.com",
    "ebay.com",
    "alibaba.com",
    "walmart.com",

    // Banking and finance platforms
    "paypal.com",
    "stripe.com",
    "bankofamerica.com",
    "chase.com",

    // Other popular platforms
    "netflix.com",
    "hulu.com",
    "amazonprime.com",
    "disneyplus.com",
    "medium.com",
    "quora.com",
    "stackoverflow.com",
  ];

  return (
    <>
      {loading ? (
        <Spinner animation="border" role="status" className="center-spinner" />
      ) : (
        <>
          {isModalOpen ? (
            <VisibilityMessageModal
              message={visibilityMessage}
              onClose={closeModal}
            />
          ) : (
            <div
              className="profile-link w-100"
              style={{ position: "relative" }}
            >
              <div className="gradient-header profile-header">
                <svg
                  width="100%"
                  height="338"
                  viewBox="0 0 1453 338"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_f_902_7997)">
                    <rect
                      width="100%"
                      height="271"
                      fill="url(#paint0_linear_902_7997)"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_f_902_7997"
                      x="-66.5"
                      y="-66.5"
                      width="1573"
                      height="404"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feGaussianBlur
                        stdDeviation="33.25"
                        result="effect1_foregroundBlur_902_7997"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_902_7997"
                      x1="856"
                      y1="281.934"
                      x2="863.131"
                      y2="-138.913"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="#76C3EE" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="content">
                <div className="row profile-ivite-card report-row">
                  <div className="col-12 col-md-8 d-flex flex-column flex-md-row align-items-center align-items-md-start text-center text-md-start">
                    <h5 className="content-heading fw-bold">
                      {user?.title
                        ? user?.title
                        : t("profile.inviteProfile.visitingCard")}
                    </h5>
                  </div>
                  <div className="col-md-4">
                    <div className="position-relative">
                      <Card className="mt-3 host-card destination-dev-team">
                        <Card.Body>
                          <div className="d-flex justify-content-center">
                            <div className="profile-logo">
                              <Card.Img
                                className="avatar-img"
                                src={
                                  user?.enterprise?.logo
                                    ? Assets_URL + "/" + user?.enterprise?.logo
                                    : "/Assets/logo2.png"
                                }
                              />
                            </div>
                          </div>
                          {/* <Card.Title className="text-center mt-3 card-heading">
                            {meetingData?.user?.enterprise?.name}
                          </Card.Title> */}
                          {/* <Card.Subtitle className="mb-2 card-subtext">
                        {meeting?.user?.post} -{" "}
                        {meeting?.user?.teams?.map((item) => item.name)}
                      </Card.Subtitle> */}
                        </Card.Body>
                      </Card>
                    </div>
                  </div>

                  {/* ------------------------------------------------ HOST */}
                </div>
                {user?.bio && (
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <div className="bio">
                        <h3> {t("profile.inviteProfile.bio")}</h3>
                        <p>{user?.bio}</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row profile-card report-row mt-4">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-center flex-wrap">
                      <Card
                        className={`participant-card participant-card-meeting position-relative`}
                        style={{
                          width: "fit-content",
                          marginTop: '4rem'
                        }}
                      >
                        <Card.Body className="profile-invite-card-body px-5 py-4">
                          <div className="d-flex justify-content-center">
                            <div className="participant-card-position">
                              <div className="profile-logo">
                                <Card.Img
                                  className="user-img"
                                  src={
                                    user?.image?.startsWith("users/")
                                      ? `${Assets_URL}/${user?.image}`
                                      : user?.image
                                  }
                                  style={{
                                    height: "150px",
                                    width: "150px",
                                  }}
                                  alt="User Avatar"
                                />
                                <Card.Img
                                  className="logout-icon"
                                  src="/Assets/Avatar_company.svg"
                                  height="20px"
                                  width="20px"
                                  alt="Company Logo"
                                />
                              </div>
                            </div>
                          </div>
                          <Card.Title className="text-center mt-4 card-heading">
                            {user?.name} {user?.last_name}
                          </Card.Title>
                          <Card.Title className="text-center mb-2 card-subtext">
                            {user?.email}
                          </Card.Title>
                          <Card.Title className="text-center mb-2 card-subtext">
                            {user?.phoneNumber}
                          </Card.Title>
                          <Card.Title className="mb-2 card-subtext">
                            {user?.post}
                          </Card.Title>
                          <Card.Title className="mb-2 card-subtext">
                            {user?.teams?.map((item) => item.name).join(", ")}
                          </Card.Title>
                          <div className="d-flex justify-content-start">
                            <button
                              className="show-save-profile"
                              onClick={downloadVCard}
                            >
                              <FaCloudDownloadAlt size={18} /> &nbsp;{" "}
                              {t("Contact sheet")}
                            </button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
                {user?.social_links?.length > 0 && (
                  <div className="row mt-5">
                    <h3>{t("profile.inviteProfile.socialLinks")}</h3>
                    <div className="d-flex gap-3 mt-3">
                      {user?.social_links?.map((item, index) => (
                        <Link
                          key={index}
                          onClick={() => handleOpen(item.link)}
                          className="text-primary"
                        >
                          {getPlatformIcon(item?.platform)}
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
                {user?.websites?.length > 0 && (
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <div className="bio">
                        <h3>{t("profile.inviteProfile.websites")}</h3>

                        {user?.websites?.map((item, index) => {
                          const domain = new URL(formatUrl(item?.link))?.hostname;
                          const isBlocked = blockedDomains?.includes(domain);

                          return (
                            <div className="col-12 profile-step" key={index}>
                              <Card
                                className="mt-4 step-card"
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleDropdown(index)}
                              >
                                <Card.Body className="d-flex">
                                  <div
                                    className={` ${dropdownVisible[index]
                                      ? "d-block"
                                      : "d-block d-flex align-items-start"
                                      }`}
                                  >
                                    <div className="step-number-container">
                                      <span className="step-number viewprofile-txtsize">
                                        {index < 10 ? "0" : ""}
                                        {index + 1}
                                      </span>
                                    </div>

                                    {/* {getPlatformIcon(item?.platform)} */}
                                  </div>
                                  <div className="ms-3 d-flex justify-content-center flex-column step-data w-100">
                                    <div className="ms-3 me-5 d-flex justify-content-between align-items-center step-data viewprofile-width">
                                      {/* <Card.Title className="step-card-heading viewprofile-txtellipses viewprofile-txtsize">
                                        {item?.title}
                                      </Card.Title> */}
                                      <OverlayTrigger
                                        placement="top" // Position of the tooltip: top, bottom, left, or right
                                        overlay={
                                          <Tooltip id={`tooltip-${item?.id}`}>
                                            {item?.title}
                                          </Tooltip>
                                        }
                                      >
                                        <Card.Title className="step-card-heading w-100 viewprofile-txtellipses viewprofile-txtsize">
                                          {item?.title}
                                        </Card.Title>
                                      </OverlayTrigger>
                                      <MdKeyboardArrowDown
                                        // onClick={() => toggleDropdown(index)}
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "21px",
                                        }}
                                        size={26}
                                      />
                                    </div>
                                  </div>
                                </Card.Body>
                                {dropdownVisible[index] && (
                                  <div
                                    className="dropdown-content-1  fade"
                                    ref={(el) =>
                                      (dropdownRefs.current[index] = el)
                                    }
                                    style={{ display: "none", margin: "6px" }}
                                  >
                                    <div className="dropdown-section-1">
                                      {isBlocked ? (
                                        <div className="d-flex align-items-center flex-column">
                                          <p>
                                            Unable to display "{item.title}" due to {domain} security policies.
                                          </p>
                                          <p>
                                            Please click the link below to open it in a new tab.
                                          </p>
                                          <a
                                            href={item.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Open {item.title} in new tab
                                          </a>
                                        </div>
                                      ) : (
                                        <iframe
                                          src={formatUrl(item?.link)}
                                          frameborder="0"
                                          allowFullScreen
                                          style={{
                                            height: "500px",
                                            width: "100%",
                                            maxHeight: "500px",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Card>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {user?.affiliation_links?.length > 0 && (
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <div className="bio">
                        <h3>{t("profile.inviteProfile.affiliation")}</h3>

                        {user?.affiliation_links?.map((item, index) => {
                          return (
                            <div className="col-12 profile-step" key={index}>
                              <Card
                                className="mt-4 step-card"
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleDropdown1(index)}
                              >
                                <Card.Body className="d-flex">
                                  <div
                                    className={` ${dropdownVisible1[index]
                                      ? "d-block"
                                      : "d-block d-flex align-items-start"
                                      }`}
                                  >
                                    <div className="step-number-container">
                                      <span className="step-number">
                                        {index < 10 ? "0" : ""}
                                        {index + 1}
                                      </span>
                                    </div>

                                    {/* {getPlatformIcon(item?.platform)} */}
                                  </div>
                                  <div className="ms-3 d-flex justify-content-center flex-column step-data w-100">
                                    <div className="mx-3 d-flex justify-content-between align-items-center step-data w-100">
                                      <Card.Title className="step-card-heading">
                                        {item?.title}
                                      </Card.Title>
                                      <MdKeyboardArrowDown
                                        // onClick={() => toggleDropdown(index)}
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "21px",
                                        }}
                                        size={26}
                                      />
                                    </div>
                                  </div>
                                </Card.Body>
                                {dropdownVisible1[index] && (
                                  <div
                                    className="dropdown-content-1  fade"
                                    ref={(el) =>
                                      (dropdownRefs1.current[index] = el)
                                    }
                                    style={{ display: "none", margin: "6px" }}
                                  >
                                    <div className="dropdown-section-1">
                                      <iframe
                                        // src={item.link}
                                        src={formatUrl(item?.link)}
                                        frameborder="0"
                                        allowFullScreen
                                        style={{
                                          height: "500px",
                                          width: "100%",
                                          maxHeight: "500px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Card>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {user?.products?.length > 0 && (
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <div className="bio">
                        <h3>{t("profile.inviteProfile.products")}</h3>

                        {user?.products?.map((item, index) => {
                          return (
                            <div className="col-md-12" key={index}>
                              <Card
                                className="mt-4 profile-step-card"
                                onClick={() => toggleDropdown2(index)}
                                style={{ cursor: "pointer" }}
                              >
                                <Card.Body className="d-flex">
                                  <div
                                    className={` ${dropdownVisible2[index]
                                      ? "d-none"
                                      : "d-block d-flex align-items-start"
                                      }`}
                                  // style={{ display: dropdownVisible[index] ? "none" : "block" }}
                                  >
                                    <div>
                                      <Card.Img
                                        className={
                                          dropdownVisible2[index]
                                            ? "product-step-img"
                                            : "product-img"
                                        }
                                        src={
                                          Assets_URL + "/" + item.product_image
                                        }
                                        style={{
                                          width: "80px", // Make the image responsive
                                          height: "46px", // Maintain aspect ratio
                                          objectFit: "contain",
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="d-flex flex-column justify-content-center w-100">
                                    <div className="mx-3 d-flex justify-content-between align-items-center step-data w-100">
                                      <Card.Title
                                        className="step-card-heading"
                                        style={{ fontSize: "larger" }}
                                      >
                                        {item?.product_title}
                                      </Card.Title>

                                      <span style={{ padding: "10px" }}>
                                        <MdKeyboardArrowDown
                                          // onClick={() => toggleDropdown(index)}
                                          style={{ cursor: "pointer" }}
                                          size={26}
                                        />
                                      </span>
                                    </div>

                                    <div
                                      className={` ${dropdownVisible2[index]
                                        ? "d-block"
                                        : "d-none d-flex align-items-start"
                                        }`}
                                    >
                                      <div
                                        className="text-center"
                                      // style={{
                                      //   width: "193.14px",
                                      //   height: "104px",
                                      //   background: "#eaeaef",
                                      //   borderRadius: "10px",
                                      //   display: "flex",
                                      //   alignItems: "center",
                                      //   justifyContent: "center",
                                      // }}
                                      >
                                        <Card.Img
                                          className={
                                            dropdownVisible2[index]
                                              ? "product-step-img"
                                              : ""
                                          }
                                          src={
                                            Assets_URL +
                                            "/" +
                                            item.product_image
                                          }
                                          style={{
                                            width: "500px", // Make the image responsive
                                            height: "auto", // Maintain aspect ratio
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {dropdownVisible2[index] && (
                                      <div
                                        className="dropdown-content-1  fade"
                                        ref={(el) =>
                                          (dropdownRefs2.current[index] = el)
                                        }
                                        style={{
                                          display: "none",
                                          margin: "6px",
                                        }}
                                      >
                                        <div className="dropdown-section-1">
                                          <h6>{t("product_description")}</h6>
                                          <p>{item.product_description}</p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}

                <div className="row mt-5 report-row">
                  <div className="join-btn d-flex justify-content-center">
                    <button
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "24px",
                        textAlign: "left",
                        color: " #FFFFFF",
                        background: "#2C48AE",
                        border: 0,
                        outine: 0,
                        padding: "10px 16px",
                        borderRadius: "9px",
                        // marginTop: "1.5rem",
                      }}
                      onClick={handleNavigate}
                    >
                      {t("joinTektime")}
                    </button>
                  </div>
                </div>

                {user?.video && user?.video !== "null" && (
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <div className="bio">
                        <h3> {t("profile.inviteProfile.presentation")}</h3>

                        <div className="embed-responsive embed-responsive-16by9 mb-4 text-center  d-flex align-items-center justify-content-center">
                          <ReactPlayer
                            url={
                              Assets_URL + "/" + user?.video ||
                              "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                            }
                            className="react-player-video"
                            controls={true}
                            style={{ borderRadius: "4px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-center align-items-center view-count">
                  <div className="d-flex flex-column align-items-center">
                    <span>
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z"
                          stroke="black"
                          stroke-width="2"
                        />
                        <path
                          d="M21 12.5C21 12.5 20 4.5 12 4.5C4 4.5 3 12.5 3 12.5"
                          stroke="black"
                          stroke-width="2"
                        />
                      </svg>
                    </span>
                    <p className="page-count">{pageViews} page views</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ProfileInvitePage;
